import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Input from './../../components/Input';

import "./style.css"

const subtitle_signup = {
    fr: `Pas encore inscrit ?`,
    en: `Not registered yet?`,
    sp: `¿Aún no está registrado?`,
    de: `Noch nicht registriert?`
}

const label_compagny = {
    fr: `Société`,
    en: `Compagny`,
    sp: `Unternehmen`,
    de: `Empresa`
}
const placeholder_compagny = {
    fr: `Saisissez le nom de votre société`,
    en: `Enter your company name`,
    sp: `Ingrese el nombre de su empresa`,
    de: `Geben Sie den Namen Ihres Unternehmens ein`
}

const label_service = {
    fr: `Service`,
    en: `Service`,
    sp: `Servicio`,
    de: `Service`
};
const placeholder_service = {
    fr: `Saisissez le nom du service`,
    en: `Enter the name of the service`,
    sp: `Ingrese el nombre del servicio`,
    de: `Geben Sie den Namen des Dienstes ein`
};

const label_siret = {
    fr: `Siret/Siren`,
    en: `Siret/Siren`,
    sp: `Siret/Siren`,
    de: `Siret/Siren`
}
const placeholder_siret = {
    fr: `Saisissez votre numero siret/siren`,
    en: `Enter your Siret/Siren number`,
    sp: `Ingrese su número de Siret/Siren`,
    de: `Geben Sie Ihre Siret/Siren-Nummer ein`
}

const label_lastname = {
    fr: `Nom`,
    en: `Lastname`,
    sp: `Apellido`,
    de: `Nachname`
}
const placeholder_lastname = {
    fr: `Saisissez votre nom`,
    en: `Enter your last name`,
    sp: `Ingrese su apellido`,
    de: `Geben Sie Ihren Nachnamen ein`
}

const label_firstname = {
    fr: `Prénom`,
    en: `Firstname`,
    sp: `Nombre de pila`,
    de: `Vorname`
}
const placeholder_firstname = {
    fr: `Saisissez votre prénom`,
    en: `Enter your first name`,
    sp: `Ingrese su nombre de pila`,
    de: `Geben Sie Ihren Vornamen ein`
}

const label_email = {
    fr: `Adresse mail`,
    en: `Mail address`,
    sp: `Correo electrónico`,
    de: `Mail adresse`
}
const placeholder_email = {
    fr: `Saisissez votre adresse mail`,
    en: `Enter your mail address`,
    sp: `Ingrese su correo electrónico`,
    de: `Geben Sie Ihre Mailadresse ein`
}

const label_password = {
    fr: `Mot de passe`,
    en: `Password`,
    sp: `Contraseña`,
    de: `Passwort`
}
const placeholder_password = {
    fr: `Saisissez votre mot de passe`,
    en: `Enter your password`,
    sp: `Ingrese su contraseña`,
    de: `Geben Sie Ihr Passwort ein`
}

const label_cellphone = {
    fr: `Téléphone fixe`,
    en: `Phone`,
    sp: `Teléfono fijo`,
    de: `Telefon`
}
const placeholder_cellphone = {
    fr: `Saisissez votre fixe`,
    en: `Enter your phone number`,
    sp: `Ingrese su teléfono fijo`,
    de: `Geben Sie Ihre Telefonnummer ein`
}

const label_phone = {
    fr: `Téléphone mobile`,
    en: `Mobile phone`,
    sp: `Teléfono móvil`,
    de: `Mobiltelefon`
}
const placeholder_phone = {
    fr: `Saisissez votre mobile`,
    en: `Enter your mobile number`,
    sp: `Ingrese su teléfono móvil`,
    de: `Geben Sie Ihre Handynummer ein`
}

const label_address = {
    fr: `Adresse`,
    en: `Address`,
    sp: `Dirección`,
    de: `Adresse`
}
const placeholder_address = {
    fr: `Saisissez votre adresse`,
    en: `Enter your address`,
    sp: `Ingrese su dirección`,
    de: `Geben Sie Ihre Adresse ein`
}

const label_city = {
    fr: `Ville`,
    en: `City`,
    sp: `Ciudad`,
    de: `Stadt`
}
const placeholder_city = {
    fr: `Saisissez votre ville`,
    en: `Enter your city`,
    sp: `Ingrese su ciudad`,
    de: `Geben Sie Ihre Stadt ein`
}

const label_zipcode = {
    fr: `Code postal`,
    en: `Zip code`,
    sp: `Código postal`,
    de: `Postleitzahl`
}

const label_country = {
    fr: `Pays`,
    en: `Country`,
    sp: `País`,
    de: `Land`
}
const placeholder_country = {
    fr: `Saisissez votre pays`,
    en: `Enter your country`,
    sp: `Ingrese su país`,
    de: `Geben Sie Ihr Land ein`
}

const check_cond = {
    fr: `J’accepte les conditions générales d’utilisations.`,
    en: `I accept the terms and conditions.`,
    sp: `Acepto los términos y condiciones.`,
    de: `Ich akzeptiere die Allgemeinen Geschäftsbedingungen.`
}
const button_signup = {
    fr: `Inscription`,
    en: `Sign up`,
    sp: `Registrarse`,
    de: `Registrieren`
}

function FormSignup({ setInscrip }) {
    const lang = useSelector(state => state.menu.lang);

    const [msgError, setMsgError] = useState('');
    const [formData, setFormData] = useState({
        society: '',
        service: '',

        siret: '',
        batiment: '',

        lastname: '',
        firstname: '',

        email: '',
        password: '',

        cellphoneNumber: '',
        phoneNumber: '',

        address_street: '',
        address_city: '',

        address_country: '',
        address_zip_code: '',

        cond: false,
        lang: 'fr',
        code: ''
    });

    // Supprime le msgError au bout de 5s
    useEffect(() => {
        if (msgError && msgError !== '') {
            const timer = setTimeout(() => {
                setMsgError('');
            }, 5000);
    
            return () => clearTimeout(timer);
        }
    }, [msgError])

    // Connection
    const sendCodeForVerification = async () => {

        // Verif cond user
        if (!formData.cond) {
            setMsgError('L\'acceptation des conditions d\'utilisation est nécéssaire pour vous inscrire.');
            return
        }
        
        // Verif des data
        const verifData = verifDataForSignup();
        if (verifData.length !== 0) {
            const fields = verifData.map((f, idx) => idx !== verifData.length ? ` "${f}"` : `"${f}"`);
            let msg = 'Champs ' + fields + ' requis.'
            setMsgError(msg);
            return
        }


        const req = await fetch(`https://api.pro-vide.eu/user/checkemail`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        });
        const res = await req.json();

        if (res) {
            setInscrip(formData);
        }
    }

    // Verifi les champs du form
    function verifDataForSignup() {
        const fields = [
            [
                'society',
                'Société'
            ],
            [
                'lastname',
                'Nom'
            ],
            [
                'firstname',
                'Prénom'
            ],
            [
                'email',
                'Adresse email'
            ],
            [
                'password',
                'Mot de passe'
            ],
            [
                'cellphoneNumber',
                'Téléphone fixe'
            ],
            [
                'address_street',
                'Adresse'
            ],
            [
                'address_city',
                'Ville'
            ],
            [
                'address_country',
                'Pays'
            ],
            [
                'address_zip_code',
                'Code postal'
            ],
        ]

        let dataIsCheck = [];

        for (let field of fields) {
            if (!formData[field[0]] || formData[field[0]] === '') {
                dataIsCheck.push(field[1]);
            }
        }

        return dataIsCheck;
    }

    // Submit form
    const handleSubmit = (event) => {
        event.preventDefault();
        sendCodeForVerification();
    };

    return lang && (
        <form className='form_signup' onSubmit={handleSubmit}>
            <h3>{subtitle_signup[lang]}</h3>

            <div className="container-fields">
                <Input label={label_compagny[lang] + ' *'} placeholder={placeholder_compagny[lang]} value={formData.society} setValue={setFormData} labForm='society' />
                <Input label={label_service[lang]} placeholder={placeholder_service[lang]} value={formData.service} setValue={setFormData} labForm='service' />
                
                <Input label={label_siret[lang]} placeholder={placeholder_siret[lang]} value={formData.siret} setValue={setFormData} labForm='siret' />
                <i></i>

                <Input label={label_lastname[lang] + ' *'} placeholder={placeholder_lastname[lang]} value={formData.lastname} setValue={setFormData} labForm='lastname' />
                <Input label={label_firstname[lang] + ' *'} placeholder={placeholder_firstname[lang]} value={formData.firstname} setValue={setFormData} labForm='firstname' />

                <Input label={label_email[lang] + ' *'} placeholder={placeholder_email[lang]} value={formData.email} setValue={setFormData} labForm='email' />
                <Input label={label_password[lang] + ' *'} placeholder={placeholder_password[lang]} value={formData.password} setValue={setFormData} labForm='password' />

                <Input label={label_cellphone[lang] + ' *'} placeholder={placeholder_cellphone[lang]} value={formData.cellphoneNumber} setValue={setFormData} labForm='cellphoneNumber' />
                <Input label={label_phone[lang] + ' *'} placeholder={placeholder_phone[lang]} value={formData.phoneNumber} setValue={setFormData} labForm='phoneNumber' />

                <Input label={label_address[lang] + ' *'} placeholder={placeholder_address[lang]} value={formData.address_street} setValue={setFormData} labForm='address_street' />
                <Input label={label_city[lang] + ' *'} placeholder={placeholder_city[lang]} value={formData.address_city} setValue={setFormData} labForm='address_city' />

                <Input label={label_zipcode[lang] + ' *'} placeholder='73000' value={formData.address_zip_code} setValue={setFormData} labForm='address_zip_code' />
                <Input label={label_country[lang] + ' *'} placeholder={placeholder_country[lang]} value={formData.address_country} setValue={setFormData} labForm='address_country' />
            </div>

            <p className='cond' onClick={() => setFormData(prev => ({ ...prev, cond: !prev.cond }))}><i>{formData.cond && '✓'}</i>{check_cond[lang] + ' *'}</p>
            
            {msgError && msgError !== '' && <p className='msgError'>{msgError}</p>}

            <button className='btn-signup'>{button_signup[lang] + ' *'}</button>
        </form>
    )
}

export default FormSignup;