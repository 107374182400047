import { useState } from 'react';
import { useSelector } from 'react-redux';

import Input from './../../components/Input';

import icon from './../../assets/svg/compagny_settings-icon.svg';

import "./style.css"

const title = {
    fr: `Adresses`,
    en: `Addresses`,
    sp: `Direcciones`,
    de: `Adressen`
}

const label_selector = {
    fr: `Vos adresses :`,
    en: `Your addresses:`,
    sp: `Sus direcciones:`,
    de: `Ihre Adressen:`
}
const option_default = {
    fr: `Sélectionner une adresse`,
    en: `Select an address`,
    sp: `Seleccionar una dirección`,
    de: `Adresse auswählen`
}
const option_add = {
    fr: `+ Ajouter une adresse`,
    en: `+ Add an address`,
    sp: `+ Añadir una dirección`,
    de: `+ Adresse hinzufügen`
}

const label_name = {
    fr: `Nom`,
    en: `Name`,
    sp: `Nombre`,
    de: `Name`
}
const placeholder_name = {
    fr: `Nom de l'adresse`,
    en: `Address name`,
    sp: `Nombre de la dirección`,
    de: `Adressname`
}

const label_address = {
    fr: `Adresse`,
    en: `Address`,
    sp: `Dirección`,
    de: `Adresse`
}
const placeholder_address = {
    fr: `Saisissez votre adresse`,
    en: `Enter your address`,
    sp: `Ingrese su dirección`,
    de: `Geben Sie Ihre Adresse ein`
}

const label_city = {
    fr: `Ville`,
    en: `City`,
    sp: `Ciudad`,
    de: `Stadt`
}
const placeholder_city = {
    fr: `Saisissez votre ville`,
    en: `Enter your city`,
    sp: `Ingrese su ciudad`,
    de: `Geben Sie Ihre Stadt ein`
}

const label_zipcode = {
    fr: `Code postal`,
    en: `Zip code`,
    sp: `Código postal`,
    de: `Postleitzahl`
}

const label_country = {
    fr: `Pays`,
    en: `Country`,
    sp: `País`,
    de: `Land`
}
const placeholder_country = {
    fr: `Saisissez votre pays`,
    en: `Enter your country`,
    sp: `Ingrese su país`,
    de: `Geben Sie Ihr Land ein`
}

const button_add = {
    fr: `Ajouter`,
    en: `Add`,
    sp: `Añadir`,
    de: `Hinzufügen`
}
const button_edit = {
    fr: `Modifier`,
    en: `Edit`,
    sp: `Editar`,
    de: `Bearbeiten`
}
const button_delete = {
    fr: `Supprimer`,
    en: `Delete`,
    sp: `Eliminar`,
    de: `Löschen`
}

function Address({ data }) {
    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [addressCursor, setAddressCursor] = useState('null');

    const [dataUser, setDataUser] = useState({
        all: [...data],
        name: '',
        address_street: '',
        address_city: '',
        address_country: '',
        address_zip_code: ''
    });
    const [formData, setFormData] = useState({
        name: '',
        address_street: '',
        address_city: '',
        address_country: '',
        address_zip_code: ''
    });

    // Selecte addresse
    const handleSelectChange = (e) => {
        const selectedId = parseInt(e.target.value);
        setAddressCursor(e.target.value);

        if (e.target.value === 'null' || e.target.value === 'add') {
            // Réinitialiser les champs si "Sélectionner une adresse" ou "Ajouter une adresse" est sélectionné
            setDataUser({
                ...dataUser,
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            });
            setFormData({
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            });
        }
        else {
            const selectedAddress = dataUser.all.find(element => element.id === parseInt(selectedId));
            setDataUser({
                ...dataUser,
                name: selectedAddress?.name || '',
                address_street: selectedAddress?.address_street || '',
                address_city: selectedAddress?.address_city || '',
                address_country: selectedAddress?.address_country || '',
                address_zip_code: selectedAddress?.address_zip_code || ''
            });
            setFormData({
                name: selectedAddress?.name || '',
                address_street: selectedAddress?.address_street || '',
                address_city: selectedAddress?.address_city || '',
                address_country: selectedAddress?.address_country || '',
                address_zip_code: selectedAddress?.address_zip_code || ''
            });
        }
    };

    // Ajout d'une addresse
    const addNewAddress = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/address/${user._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ ...formData, password: user.password })
        });
        const res = await req.json();

        if (res) {
            setDataUser(prev => {
                return {
                    ...prev,
                    all: res
                }
            })
            setFormData({
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            })
            setAddressCursor('null');
        }
    }

    // Modifier une addrese
    const updateAddress = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/address/${user._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ addressId: addressCursor, ...formData })
        });
        const res = await req.json();

        if (res) {
            setDataUser(prev => {
                return {
                    ...prev,
                    all: [...res]
                }
            })
        }
    }

    // Supprimer une addresse
    const deleteAddress = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/address/${user._id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ addressId: addressCursor })
        });
        const res = await req.json();

        if (res) {
            setDataUser(prev => {
                return {
                    ...prev,
                    all: [...res]
                }
            })
            setFormData({
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            })
            setAddressCursor('null');
        }
    }

    return lang && (
        <div className='modal modal_address'>
            <h3><img src={icon} alt="Icon" />{title[lang]}</h3>

            <div className="big_container">

                {/* Selecteur */}
                <div className='input_model'>
                    <label>{label_selector[lang]}</label>

                    <select value={addressCursor} onChange={handleSelectChange}>
                        <option value="null">{option_default[lang]}</option>
                        <option value="add">{option_add[lang]}</option>

                        {dataUser && dataUser.all.length !== 0 && dataUser.all.map((a, idx) => (
                            <option key={idx} value={a.id}>{a.name}, {a?.address_street}, {a?.address_city} {a?.address_country}</option>
                        ))}
                    </select>
                </div>

                {/* Inputs */}
                {formData && addressCursor && addressCursor !== 'null' && (
                    <div className="container">
                        <Input label={label_name[lang]} placeholder={placeholder_name[lang]} value={formData.name} setValue={setFormData} labForm={`name${formData.name !== dataUser.name ? '_isEdit' : ''}`} />
                        <i></i>
                        <Input label={label_address[lang]} placeholder={placeholder_address[lang]} value={formData.address_street} setValue={setFormData} labForm={`address_street${formData.address_street !== dataUser.address_street ? '_isEdit' : ''}`} />
                        <Input label={label_city[lang]} placeholder={placeholder_city[lang]} value={formData.address_city} setValue={setFormData} labForm={`address_city${formData.address_city !== dataUser.address_city ? '_isEdit' : ''}`} />
                        <Input label={label_zipcode[lang]} placeholder='73000' value={formData.address_zip_code} setValue={setFormData} labForm={`address_zip_code${formData.address_zip_code !== dataUser.address_zip_code ? '_isEdit' : ''}`} />
                        <Input label={label_country[lang]} placeholder={placeholder_country[lang]} value={formData.address_country} setValue={setFormData} labForm={`address_country${formData.address_country !== dataUser.address_country ? '_isEdit' : ''}`} />
                    </div>
                )}
            </div>

            {/* Buttons */}
            {addressCursor && (
                <div className='container_btn'>
                    {addressCursor === 'add' && <button onClick={() => addNewAddress()} className='btn_edit'>{button_add[lang]}</button>}

                    {addressCursor !== 'add' && addressCursor !== 'null' && <button onClick={() => deleteAddress()} className='btn_delete'>{button_edit[lang]}</button>}
                    {addressCursor !== 'add' && addressCursor !== 'null' && <button onClick={() => updateAddress()} className='btn_update'>{button_delete[lang]}</button>}
                </div>
            )}

        </div>
    )
}

export default Address;