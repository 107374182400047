import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux';

import Input from './../../components/Input';

import icon from './../../assets/svg/lock_settings-icon.svg';

import "./style.css"

const title = {
    fr: `Mot de passe`,
    en: `Password`,
    sp: `Contraseña`,
    de: `Passwort`
}
const label_input_lock = {
    fr: `Votre mot de passe`,
    en: `Your password`,
    sp: `Su contraseña`,
    de: `Ihr Passwort`
}
const button_edit_pw = {
    fr: `Modifier le mot de passe`,
    en: `Change password`,
    sp: `Cambiar la contraseña`,
    de: `Passwort ändern`
}
const label_new_pw = {
    fr: `Nouveau mot de passe`,
    en: `New password`,
    sp: `Nueva contraseña`,
    de: `Neues Passwort`
}
const placeholder_new_pw = {
    fr: `Saisissez votre mot de passe`,
    en: `Enter your password`,
    sp: `Ingrese su contraseña`,
    de: `Geben Sie Ihr Passwort ein`
}
const label_new_pw_conf = {
    fr: `Confirmation du mot de passe`,
    en: `Confirm password`,
    sp: `Confirmación de la contraseña`,
    de: `Passwort bestätigen`
}
const placeholder_new_pw_conf = {
    fr: `Confirmer votre mot de passe`,
    en: `Confirm your password`,
    sp: `Confirme su contraseña`,
    de: `Bestätigen Sie Ihr Passwort`
}
const button_return = {
    fr: `Annuler`,
    en: `Cancel`,
    sp: `Cancelar`,
    de: `Abbrechen`
}
const button_edit = {
    fr: `Modifier`,
    en: `Edit`,
    sp: `Modificar`,
    de: `Bearbeiten`
}
const title_modal = {
    fr: `Confirmation`,
    en: `Confirmation`,
    sp: `Confirmación`,
    de: `Bestätigung`
}
const text_modal = {
    fr: `Afin de confirmer votre demande, veuillez renseigner votre mot de passe :`,
    en: `To confirm your request, please enter your password:`,
    sp: `Para confirmar su solicitud, por favor ingrese su contraseña:`,
    de: `Um Ihre Anfrage zu bestätigen, geben Sie bitte Ihr Passwort ein:`
}
const button_conf = {
    fr: `Confirmer`,
    en: `Confirm`,
    sp: `Confirmar`,
    de: `Bestätigen`
}

function Password() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [msgError, setMsgError] = useState('');
    const [msgSuccess, setMsgSuccess] = useState('');

    const [mdlConf, setMdlConf] = useState(false);
    const [editPassword, setEditPassword] = useState(false);

    const [formData, setFormData] = useState({
        passw: '',
        _passw: ''
    });
    const [lastPw, setLastPw] = useState('');

    // Clear l'input 'password' actuel
    useEffect(() => {
        if (!editPassword) {
            setLastPw('');
        }
    }, [editPassword])

    // Update password
    const setPassw = async () => {

        // Verif password
        if (formData.passw !== formData._passw) {
            setMsgError('Les deux mots de passe doivent etre identiques');

            setTimeout(() => {
                setMsgError('');
            }, 5000);
            return
        }

        const req = await fetch(`https://api.pro-vide.eu/user/changePw/${user._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ password: formData.passw, lastpassword: lastPw })
        });
        const res = await req.json();

        if (res) {
            setMsgSuccess('Votre mot de passe a bien été mis à jour');

            // Mise à jour du state
            sessionStorage.setItem('password', formData.passw);

            if (localStorage.getItem('password')) {
                localStorage.setItem('password', formData.passw);
            }

            dispatch(login({ ...user, password: formData.passw }));

            // Reset
            setTimeout(() => {
                setMsgError('');
                setMsgSuccess('');
                setMdlConf(false);
                setEditPassword(false);
                setFormData({ passw: '', _passw: '' });
                setLastPw('');
            }, 5000)
        }
    }

    return (
        <div className='modal modal_password'>
            <h3><img src={icon} alt="Icon" />{title[lang]}</h3>

            {!editPassword && (
                <div className="container_fields">
                    <div className='input_model input_lock'>
                        <label>{label_input_lock[lang]}</label>
                        <input
                            type="text"
                            value='*******************'
                            className={`input_email`}
                            readOnly
                        />
                    </div>

                    <button onClick={() => setEditPassword(true)} className='editAddressMail'>{button_edit_pw[lang]}</button>
                </div>
            )}

            {/* Password editor */}
            {editPassword && (
                <>
                    <div className="container_fields">
                        <Input label={label_new_pw} placeholder={placeholder_new_pw} value={formData.passw} setValue={setFormData} labForm='passw' />
                        <Input label={label_new_pw_conf} placeholder={placeholder_new_pw_conf} value={formData._passw} setValue={setFormData} labForm='_passw' />
                        {msgError && msgError !== '' && <p>{msgError}</p>}
                    </div>

                    <div className="container_btn">
                        <button onClick={() => setEditPassword(false)} className='btn_return'>{button_return[lang]}</button>
                        <button onClick={() => setMdlConf(true)} className='btn_edit'>{button_edit[lang]}</button>
                    </div>
                </>
            )}

            {/* Modal de confirmation */}
            {mdlConf && (
                <div className="conf_edit_account">

                    {/* Verif du mot de passe */}
                    {!msgSuccess && (
                        <div className="content">

                            <i className="fas fa-xmark" onClick={() => setMdlConf(false)}></i>

                            <span>{title_modal[lang]}</span>
                            <p>{text_modal[lang]}</p>

                            <input
                                className='input_pw_for_conf'
                                type="password"
                                placeholder='********************'
                                value={lastPw}
                                onChange={(e) => setLastPw(e.target.value)}
                            />

                            <button onClick={() => setPassw()}>{button_conf[lang]}</button>
                        </div>
                    )}

                    {/* Message de success */}
                    {msgSuccess && (
                        <div className="content">
                            <p className='msgConf'>{msgSuccess}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Password;