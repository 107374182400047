import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { close } from '../../redux';

import "./style.css"

function MainMenu({ data, images, type }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }

    return (
        <ul className={`mainMenu_${type}`}>
            {data && data.length !== 0 && data.map((e, idx) => (
                <li 
                    key={e._id}
                    onClick={() => goPage(`${type === 'subcategories' ? 'categories': type}/${e._id}`)}
                >
                    <div className="container-img">
                        <img src={images[idx]?.image} alt="Illustration" />
                    </div>

                    <p>{e.name}</p>
                </li>
            ))}
        </ul>
    )
}

export default MainMenu;