import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';

import MainReal from '../../components/MainReal';

import illustartion from './../../assets/img/realisation.png';
import "./style.css"
import Realisation from '../../modals/Realisation';
import { useNavigate } from 'react-router-dom';

const title = {
    fr: `Nos réalisations`,
    en: `Our achievements`,
    sp: `Nuestros logros`,
    de: `Unsere Erfolge`
}

const descrip = {
    fr: `Pro-Vide, votre partenaire d'excellence dans le domaine de l'usinage de précision. Forts d'une expertise inégalée, nous sommes spécialisés dans la fabrication de pièces sur mesure.

Nos Points Forts :

Usinage de Précision : Nous transformons les idées en réalité avec une précision inégalée. Nos équipements de pointe et notre équipe qualifiée garantissent des pièces conformes aux exigences les plus strictes.

Polyvalence : De la pièce unitaire à la moyenne série, notre capacité de production flexible nous permet de répondre efficacement à vos besoins, qu'il s'agisse de petites séries spécialisées ou de projets de plus grande envergure.`,
    en: `Pro-Vide, your partner of excellence in the field of precision machining. With unrivaled expertise, we specialize in the manufacturing of custom parts.

Our strong points :

Precision Machining: We turn ideas into reality with unparalleled precision. Our cutting-edge equipment and qualified team guarantee parts that meet the strictest requirements.

Versatility: From single parts to medium series, our flexible production capacity allows us to respond effectively to your needs, whether they are small specialized series or larger projects.`,
    sp: `Pro-Vide, su socio de excelencia en el campo del mecanizado de precisión. Con una experiencia inigualable, nos especializamos en la fabricación de piezas personalizadas.

Nuestros puntos fuertes:

Mecanizado de Precisión: Convertimos ideas en realidad con una precisión incomparable. Nuestros equipos de última generación y nuestro equipo calificado garantizan piezas que cumplen con los requisitos más estrictos.

Versatilidad: Desde piezas individuales hasta series medianas, nuestra capacidad de producción flexible nos permite responder eficazmente a sus necesidades, ya sean pequeñas series especializadas o proyectos de mayor envergadura.`,
    de: `Pro-Vide, Ihr Exzellenzpartner im Bereich Präzisionsbearbeitung. Mit unübertroffenem Fachwissen sind wir auf die Herstellung kundenspezifischer Teile spezialisiert.

Unsere Stärken:

Präzisionsbearbeitung: Wir setzen Ideen mit beispielloser Präzision in die Realität um. Unsere hochmoderne Ausrüstung und unser qualifiziertes Team garantieren Teile, die den strengsten Anforderungen entsprechen.

Vielseitigkeit: Vom Einzelteil bis zur Mittelserie – unsere flexible Produktionskapazität ermöglicht es uns, effektiv auf Ihre Bedürfnisse einzugehen, egal ob kleine Spezialserien oder größere Projekte.`
}

const filter_ = {
    fr: `Filtres :`,
    en: `Filters`,
    sp: `Filtros:`,
    de: `Filter :`
}

const all = {
    fr: `Toutes`,
    en: `All`,
    sp: `Todas`,
    de: `Alle`
}

function Realisations() {
    const navigate = useNavigate();
    const filtersRef = useRef(null);

    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [filter, setFilter] = useState('all');

    const [pagesData, setPagesData] = useState([]);
    const [dataImages, setDataImages] = useState([]);
    const [realSelected, setRealSelected] = useState(null);

    const lang = useSelector(state => state.menu.lang);
    const categories = useSelector(state => state.data.categories);
    const realisations = useSelector(state => state.data.realisations);

    // Filtrage des catégories et calcule du nb de pages
    useEffect(() => {
        let newPagesData = [];
        
        if(!realisations || realisations.length === 0) {
            setPagesData(newPagesData);
            setMaxPage(0);
            return
        }

        let datas = [...realisations];

        if (filter !== 'all') {
            datas = realisations.filter(e => e.parentId === filter);
        }

        const multiplicator = Math.floor(parseInt(datas.length) / 20);
        setMaxPage(multiplicator);

        if (multiplicator === 0) {
            setPagesData([datas]);
            return
        }
        
        for (let i = 0; i < multiplicator; i++) {
            console.log(multiplicator);
            let newPage = [];

            for (let n = 0; n < 20; n++) {
                newPage.push(datas[(i * 20) + n]);
            }

            newPagesData.push(newPage);
        }

        setPagesData(newPagesData);
        // console.log(newPagesData);
    }, [realisations, filter])

    // Recup des images de la page
    useEffect(() => {
        if (!pagesData || pagesData.length === 0) {
            setDataImages([]);
            return
        }

        const fetchImages = async () => {
            try {
                const reqImgProd = await Promise.all(pagesData[page].map(async (real) => {
                    if (real && real.images && real.images.length > 0) {
                        const imgId = real.images[0];

                        const req = await fetch(`https://api.pro-vide.eu/public/image/${imgId}`);
                        const res = await req.json();

                        return res ? res : '';
                    } 
                    else {
                        return '';
                    }
                }));

                setDataImages(reqImgProd);
            } 
            catch (error) {
                console.error("Error fetching images:", error);
            }
        };

        fetchImages();

    }, [pagesData, page])

    // Rendu nav-page && main_real
    const renderPagination = () => {
        let buttons = [];

        if (page > 2) {
            buttons.push(<button key="first" onClick={() => handlePageChange(0)}>1</button>);
            if (page > 3) {
                buttons.push(<span key="first-dots">...</span>);
            }
        }

        for (let i = Math.max(0, page - 2); i <= Math.min(maxPage - 1, page + 2); i++) {
            buttons.push(
                <button key={i} onClick={() => handlePageChange(i)} className={i === page ? 'actif' : ''}>
                    {i + 1}
                </button>
            );
        }

        if (page < maxPage - 3) {
            if (page < maxPage - 4) {
                buttons.push(<span key="last-dots">...</span>);
            }
            buttons.push(<button key="last" onClick={() => handlePageChange(maxPage - 1)}>{maxPage}</button>);
        }

        return buttons;
    };

    // Changement de page de realisations
    const handlePageChange = (newPage) => {
        setPage(newPage);
        if (filtersRef.current) {
            filtersRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const showRealisation = (id) => {
        const real = realisations.find(e => e._id === id);
        if (!real) {
            navigate('/error');
            return
        }

        setRealSelected(real);
    }

    return (
        <div className='page_realisations'>
            {realSelected && <Realisation data={realSelected} setRealSelected={setRealSelected} />}

            <section className='sect-descrip'>
                <img src={illustartion} alt="illustration" />
                <div className="descrip">
                    <h2>{title[lang]}</h2>
                    <p>{descrip[lang]}</p>
                </div>
            </section>

            <section className='sect-realisations' ref={filtersRef}>
                <div className="filters">
                    <span>{filter_[lang]}</span>

                    <button className={filter === 'all' ? 'actif' : ''} onClick={() => setFilter('all')}>{all[lang]}</button>
                    
                    {categories && categories.map(cat => cat.type === 'prod' && !cat.parentId && (
                        <button 
                            key={cat._id}
                            onClick={() => setFilter(cat._id)}
                            className={filter === cat._id ? 'actif' : ''}
                        >{cat.name}</button>
                    ))}
                </div>

                <MainReal data={pagesData[page]} images={dataImages} showRealisation={showRealisation} />

                <div className="nav-page_realisations">
                    {renderPagination()}
                </div>
            </section>
        </div>
    )
}

export default Realisations;