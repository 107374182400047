import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Input from './../../components/Input';

import icon from './../../assets/svg/file_settings-icon.svg';

import "./style.css"

const title = {
    fr: `Coordonnées`,
    en: `Contact Information`,
    sp: `Datos de contacto`,
    de: `Kontaktdaten`
}
const label_compagny = {
    fr: `Société`,
    en: `Compagny`,
    sp: `Unternehmen`,
    de: `Empresa`
}
const placeholder_compagny = {
    fr: `Saisissez le nom de votre société`,
    en: `Enter your company name`,
    sp: `Ingrese el nombre de su empresa`,
    de: `Geben Sie den Namen Ihres Unternehmens ein`
}
const label_service = {
    fr: `Service`,
    en: `Service`,
    sp: `Servicio`,
    de: `Service`
};
const placeholder_service = {
    fr: `Saisissez le nom du service`,
    en: `Enter the name of the service`,
    sp: `Ingrese el nombre del servicio`,
    de: `Geben Sie den Namen des Dienstes ein`
};
const label_siret = {
    fr: `Siret/Siren`,
    en: `Siret/Siren`,
    sp: `Siret/Siren`,
    de: `Siret/Siren`
}
const placeholder_siret = {
    fr: `Saisissez votre numero siret/siren`,
    en: `Enter your Siret/Siren number`,
    sp: `Ingrese su número de Siret/Siren`,
    de: `Geben Sie Ihre Siret/Siren-Nummer ein`
}
const label_lastname = {
    fr: `Nom`,
    en: `Lastname`,
    sp: `Apellido`,
    de: `Nachname`
}
const label_firstname = {
    fr: `Prénom`,
    en: `Firstname`,
    sp: `Nombre de pila`,
    de: `Vorname`
}
const label_cellphone = {
    fr: `Téléphone fixe`,
    en: `Phone`,
    sp: `Teléfono fijo`,
    de: `Telefon`
}
const placeholder_cellphone = {
    fr: `Saisissez votre fixe`,
    en: `Enter your phone number`,
    sp: `Ingrese su teléfono fijo`,
    de: `Geben Sie Ihre Telefonnummer ein`
}
const label_phone = {
    fr: `Téléphone mobile`,
    en: `Mobile phone`,
    sp: `Teléfono móvil`,
    de: `Mobiltelefon`
}
const placeholder_phone = {
    fr: `Saisissez votre mobile`,
    en: `Enter your mobile number`,
    sp: `Ingrese su teléfono móvil`,
    de: `Geben Sie Ihre Handynummer ein`
}
const label_address = {
    fr: `Adresse`,
    en: `Address`,
    sp: `Dirección`,
    de: `Adresse`
}
const placeholder_address = {
    fr: `Saisissez votre adresse`,
    en: `Enter your address`,
    sp: `Ingrese su dirección`,
    de: `Geben Sie Ihre Adresse ein`
}
const label_city = {
    fr: `Ville`,
    en: `City`,
    sp: `Ciudad`,
    de: `Stadt`
}
const placeholder_city = {
    fr: `Saisissez votre ville`,
    en: `Enter your city`,
    sp: `Ingrese su ciudad`,
    de: `Geben Sie Ihre Stadt ein`
}
const label_zipcode = {
    fr: `Code postal`,
    en: `Zip code`,
    sp: `Código postal`,
    de: `Postleitzahl`
}
const label_country = {
    fr: `Pays`,
    en: `Country`,
    sp: `País`,
    de: `Land`
}
const button_edit = {
    fr: `Modifier`,
    en: `Edit`,
    sp: `Editar`,
    de: `Bearbeiten`
}
const title_modal = {
    fr: `Confirmation`,
    en: `Confirmation`,
    sp: `Confirmación`,
    de: `Bestätigung`
}
const text_modal = {
    fr: `Afin de confirmer votre demande, veuillez renseigner votre mot de passe :`,
    en: `To confirm your request, please enter your password:`,
    sp: `Para confirmar su solicitud, por favor ingrese su contraseña:`,
    de: `Um Ihre Anfrage zu bestätigen, geben Sie bitte Ihr Passwort ein:`
}
const button_conf = {
    fr: `Confirmer`,
    en: `Confirm`,
    sp: `Confirmar`,
    de: `Bestätigen`
}
const msg_conf = {
    fr: `Vos coordonnées ont bien été mises à jour`,
    en: `Your contact information has been successfully updated`,
    sp: `Sus datos de contacto han sido actualizados correctamente`,
    de: `Ihre Kontaktdaten wurden erfolgreich aktualisiert`
}

function Coordinates({ data }) {
    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [dataModified, setDataModified] = useState(false);
    const [passwForConf, setPasswForConf] = useState('');
    const [mdlConf, setMdlConf] = useState(false);

    const [dataIsUpdate, setDataIsUpdate] = useState(false);

    const [formData, setFormData] = useState({
        siret: '',
        society: '',
        service: '',
        cellphoneNumber: '',
        phoneNumber: '',
        address_street: '',
        address_city: '',
        address_country: '',
        address_zip_code: ''
    });
    const [dataUser, setDataUser] = useState({
        siret: '',
        society: '',
        service: '',
        cellphoneNumber: '',
        phoneNumber: '',
        address_street: '',
        address_city: '',
        address_country: '',
        address_zip_code: ''
    });

    // Recup des data
    useEffect(() => {
        const e = {
            siret: data.siret || '',
            society: data.society || '',
            service: data.service || '',
            cellphoneNumber: data.cellphoneNumber || '',
            phoneNumber: data.phoneNumber || '',
            address_street: data.address_street || '',
            address_city: data.address_city || '',
            address_country: data.address_country || '',
            address_zip_code: data.address_zip_code || ''
        };
        setFormData(e);
        setDataUser(e);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Vérification de modification (afficher le btn 'Modifier')
    useEffect(() => {
        if (formData.siret !== dataUser.siret
            || formData.society !== dataUser.society
            || formData.service !== dataUser.service
            || formData.address_street !== dataUser.address_street
            || formData.cellphoneNumber !== dataUser.cellphoneNumber
            || formData.phoneNumber !== dataUser.phoneNumber
            || formData.address_city !== dataUser.address_city
            || formData.address_country !== dataUser.address_country
            || formData.address_zip_code !== dataUser.address_zip_code
        ) {
            setDataModified(true);
        }
        else {
            setDataModified(false);
        }

    }, [formData, dataUser])

    // Edition du compte
    const editCoordinate = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/account/${data._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ ...formData, password: passwForConf })
        });
        const res = await req.json();

        if (res === true) {
            setDataIsUpdate(true);

            setTimeout(() => {
                setDataModified(false)
                setPasswForConf('')
                setMdlConf(false)
                setDataIsUpdate(false)
                setDataUser(formData);
            }, 2000)
        }
    }

    return (
        <>
            <div className='modal modal_coordinates'>
                <h3><img src={icon} alt="Icon" />{title[lang]}</h3>

                <div className="container_fields">
                    <div className='input_model input_lock'>
                        <label>{label_lastname[lang]}</label>
                        <input
                            type="text"
                            value={data.lastname}
                            className={`input_`}
                            readOnly
                        />
                    </div>

                    <div className='input_model input_lock'>
                        <label>{label_firstname[lang]}</label>
                        <input
                            type="text"
                            value={data.firstname}
                            className={`input_`}
                            readOnly
                        />
                    </div>

                    {formData && <Input label={label_compagny[lang]} placeholder={placeholder_compagny[lang]} value={formData.society} setValue={setFormData} labForm={`society${formData.society !== dataUser.society ? '_isEdit' : ''}`} />}
                    {formData && <Input label={label_service[lang]} placeholder={placeholder_service[lang]} value={formData.service} setValue={setFormData} labForm={`service${formData.service !== dataUser.service ? '_isEdit' : ''}`} />}
                    {formData && <Input label={label_siret[lang]} placeholder={placeholder_siret[lang]} value={formData.siret} setValue={setFormData} labForm={`siret${formData.siret !== dataUser.siret ? '_isEdit' : ''}`} />}
                    {formData && <Input label={label_cellphone[lang]} placeholder={placeholder_cellphone[lang]} value={formData.cellphoneNumber} setValue={setFormData} labForm={`cellphoneNumber${formData.cellphoneNumber !== dataUser.cellphoneNumber ? '_isEdit' : ''}`} />}
                    {formData && <Input label={label_phone[lang]} placeholder={placeholder_phone[lang]} value={formData.phoneNumber} setValue={setFormData} labForm={`phoneNumber${formData.phoneNumber !== dataUser.phoneNumber ? '_isEdit' : ''}`} />}
                    {formData && <Input label={label_address[lang]} placeholder={placeholder_address[lang]} value={formData.address_street} setValue={setFormData} labForm={`address_street${formData.address_street !== dataUser.address_street ? '_isEdit' : ''}`} />}
                    {formData && <Input label={label_zipcode[lang]} placeholder='73000' value={formData.address_zip_code} setValue={setFormData} labForm={`address_zip_code${formData.address_zip_code !== dataUser.address_zip_code ? '_isEdit' : ''}`} />}
                    {formData && <Input label={label_city[lang]} placeholder={placeholder_city[lang]} value={formData.address_city} setValue={setFormData} labForm={`address_city${formData.address_city !== dataUser.address_city ? '_isEdit' : ''}`} />}
                
                    
                    <div className='input_model input_lock'>
                        <label>{label_country[lang]}</label>
                        <input
                            type="text"
                            value={formData.address_country}
                            className={`input_`}
                            readOnly
                        />
                    </div>
                </div>

                {dataModified && <button onClick={() => setMdlConf(true)} className='btn_edit'>{button_edit[lang]}</button>}
            </div>


            {mdlConf && (
                <div className="conf_edit_account">
                    <div className="content">
                        {!dataIsUpdate ? (
                            <>
                                <i className="fas fa-xmark" onClick={() => setMdlConf(false)}></i>

                                <span>{title_modal[lang]}</span>
                                <p>{text_modal[lang]}</p>

                                <input
                                    className='input_pw_for_conf'
                                    type="password"
                                    placeholder='********************'
                                    value={passwForConf}
                                    onChange={(e) => setPasswForConf(e.target.value)}
                                />

                                <button onClick={() => editCoordinate()}>{button_conf[lang]}</button>
                            </>
                        ) : (
                            <p className='msgConf'>{msg_conf[lang]}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default Coordinates;