import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { close } from '../../redux'

import logo_provide from './../../assets/img/logo-pro-vide.png'
import location_icon from './../../assets/svg/location_icon-footer.svg'
import email_icon from './../../assets/svg/email_icon-footer.svg'
import phone_icon from './../../assets/svg/phone_icon-footer.svg'
import background_icon from './../../assets/svg/backgroung_footer-location.svg'

import "./style.css"

const subTitle = {
    fr: `Créateur de solution`,
    en: `Solution Creator`,
    sp: `Creador de soluciones`,
    de: `Lösungsersteller`
};
const label_compagny = {
    fr: `Entreprise`,
    en: `Company`,
    sp: `Empresa`,
    de: `Unternehmen`
}
const link_real = {
    fr: `Réalisations`,
    en: `Achievements`,
    sp: `Logros`,
    de: `Leistungen`
}
const link_about = {
    fr: `À propos`,
    en: `About Us`,
    sp: `Acerca de`,
    de: `Über uns`
}
const link_contact = {
    fr: `Contact`,
    en: `Contact Us`,
    sp: `Contáctenos`,
    de: `Kontakt`
}
const label_account = {
    fr: `Mon compte`,
    en: `My Account`,
    sp: `Mi cuenta`,
    de: `Mein Konto`
}
const link_settings = {
    fr: `Paramètres du compte`,
    en: `Account Settings`,
    sp: `Configuración de la cuenta`,
    de: `Kontoeinstellungen`
}
const link_history = {
    fr: `Historique`,
    en: `History`,
    sp: `Historial`,
    de: `Verlauf`
}
const link_cart = {
    fr: `Panier`,
    en: `Cart`,
    sp: `Carrito`,
    de: `Warenkorb`
}
const label_mention = {
    fr: `Mentions légales`,
    en: `Legal Notice`,
    sp: `Aviso legal`,
    de: `Impressum`
}
const link_CGU = {
    fr: `Conditions Générales d’Utilisation`,
    en: `Terms of Use`,
    sp: `Términos de uso`,
    de: `Nutzungsbedingungen`
}
const link_PC = {
    fr: `Politique de confidentialité`,
    en: `Privacy Policy`,
    sp: `Política de privacidad`,
    de: `Datenschutzrichtlinie`
}
const subtext = {
    fr: `Tous droits réservés (c) 2023 Pro-Vide`,
    en: `All rights reserved (c) 2023 Pro-Vide`,
    sp: `Todos los derechos reservados (c) 2023 Pro-Vide`,
    de: `Alle Rechte vorbehalten (c) 2023 Pro-Vide`
}
const label_location = {
    fr: `Nous retrouver`,
    en: `Find Us`,
    sp: `Encuéntranos`,
    de: `Finden Sie uns`
}


function Footer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const lang = useSelector(state => state.menu.lang);

    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }

    return (
        <footer>
            <div className="content">
                <div className="menu">
                    <ul>
                        <li><h4>{label_compagny[lang]}</h4></li>
                        <li><button onClick={() => goPage('realisations')}>{link_real[lang]}</button></li>
                        <li><button onClick={() => goPage('about')}>{link_about[lang]}</button></li>
                        <li><button onClick={() => goPage('contact')}>{link_contact[lang]}</button></li>
                    </ul>
                    <ul>
                        <li><h4>{label_account[lang]}</h4></li>
                        <li><button onClick={() => goPage('settings')}>{link_settings[lang]}</button></li>
                        <li><button onClick={() => goPage('historical')}>{link_history[lang]}</button></li>
                        <li><button onClick={() => goPage('basket')}>{link_cart[lang]}</button></li>
                    </ul>
                    <ul>
                        <li><h4>{label_mention[lang]}</h4></li>
                        <li><button onClick={() => goPage('CGU')}>{link_CGU[lang]}</button></li>
                        <li><button onClick={() => goPage('PC')}>{link_PC[lang]}</button></li>
                    </ul>
                    <div className="container_logo-law">
                        <div className="logo_pro-vide">
                            <img src={logo_provide} alt="Logo" />
                            <p>Pro-vide<span>{subTitle[lang]}</span></p>
                        </div>

                        <p className='law'>{subtext[lang]}</p>
                    </div>
                </div>

                <div className="contact">
                    <h4>{label_location[lang]}</h4>
                    <img className='back-icon' src={background_icon} alt=' ' />

                    <ul>
                        <li>
                            <button>
                                <img src={location_icon} alt="icon" />
                                <p>24 Bis Route de Fougères <br />
                                    41120 CORMERAY <br />
                                    France</p>
                            </button>
                        </li>
                        <li>
                            <button>
                                <img src={email_icon} alt="icon" />
                                <p>contact@pro-vide.eu</p>
                            </button>
                        </li>
                        <li>
                            <button>
                                <img src={phone_icon} alt="icon" />
                                <p>02 55 99 65 05</p>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;