import axios from 'axios';

const getLocation = async () => {
    try {
        const response = await axios.get('https://ipapi.co/json/');
        if (!response || !response.data || !response.data.country) {
            return 'en'
        }

        const country = response.data.country;

        if (country === 'FR') {
            return 'fr'
        }
        if (country === 'BE') {
            return 'fr'
        }

        if (country === 'GB') {
            return 'en'
        }
        if (country === 'US') {
            return 'en'
        }

        if (country === 'ES') {
            return 'sp'
        }

        if (country === 'DE') {
            return 'de'
        }
        return 'en'
    }
    catch (error) {
        return 'en';
    }
};

export default getLocation;