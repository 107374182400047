import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { close } from "../../redux";

import Input from "../../components/Input";
import Banner from "../../components/Banner";
import SliderServ from "../../components/SliderServ";

import icon_flyer from './../../assets/svg/flyer_icon-services.svg'
import icon_contact from './../../assets/svg/contact_icon-product.svg'
import icon_phone from './../../assets/svg/phone_icon-product.svg'
import icon_message from './../../assets/svg/message_icon-product.svg'

import "./style.css"

const msg_contact = {
    fr: `Si vous désirez une offre, ou de plus amples informations sur ce produit contactez-nous :`,
    en: `If you would like an offer, or more information on this product, contact us :`,
    sp: `Si desea una oferta o más información sobre este producto, contáctenos :`,
    de: `Wenn Sie ein Angebot oder weitere Informationen zu diesem Produkt wünschen, kontaktieren Sie uns :`
};
const button_contact = {
    fr: `Nous écrire`,
    en: `Write us`,
    sp: `Escribenos`,
    de: `Schreiben Sie uns`
};
const title_modal = {
    fr: `Demande de service`,
    en: `Service request`,
    sp: `Petición de servicio`,
    de: `Service-Anfrage`
};
const label_name_modal = {
    fr: `Service :`,
    en: `Service :`,
    sp: `Servicio :`,
    de: `Service :`
};
const label_request_modal = {
    fr: `Besoins spécifiques :`,
    en: `Specific needs :`,
    sp: `Necesidades específicas :`,
    de: `Spezielle Bedürfnisse :`
};
const placeholder_request_modal = {
    fr: `Décrivez-nous au mieux vos besoins`,
    en: `Describe your needs to us as best as possible`,
    sp: `Descríbanos sus necesidades lo mejor posible`,
    de: `Beschreiben Sie uns Ihre Bedürfnisse so gut wie möglich`
};
const label_compagny = {
    fr: `Société`,
    en: `Compagny`,
    sp: `Unternehmen`,
    de: `Empresa`
}
const label_service = {
    fr: `Service`,
    en: `Service`,
    sp: `Servicio`,
    de: `Service`
};
const label_city = {
    fr: `Ville`,
    en: `City`,
    sp: `Ciudad`,
    de: `Stadt`
}
const label_country = {
    fr: `Pays`,
    en: `Country`,
    sp: `País`,
    de: `Land`
}
const label_phone = {
    fr: `Téléphone`,
    en: `Phonenumber`,
    sp: `Teléfono`,
    de: `Telefon`
}
const label_email = {
    fr: `Adresse mail`,
    en: `Mail address`,
    sp: `Correo electrónico`,
    de: `Mail adresse`
}
const label_address = {
    fr: `Adresse`,
    en: `Address`,
    sp: `Dirección`,
    de: `Adresse`
}
const label_zipcode = {
    fr: `Code postal`,
    en: `Zip code`,
    sp: `Código postal`,
    de: `Postleitzahl`
}
const button_request = {
    fr: `Faire une demande`,
    en: `To make a request`,
    sp: `Para hacer una petición`,
    de: `Eine Anfrage stellen`
};

function Services() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [flyer, setFlyer] = useState('');
    const [dataPage, setDataPage] = useState(null);

    const [formData, setFormData] = useState({
        society: '',
        service: '',

        siret: '',
        email: '',

        phoneNumber: '',

        address_street: '',
        address_city: '',

        address_country: '',
        address_zip_code: ''
    });

    const [requestProd, setRequestProd] = useState('');
    const [descripRequest, setDescripRequest] = useState('');
    const [msgSuccessOrError, setMsgSuccessOrError] = useState('');

    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);
    const services = useSelector(state => state.data.services);

    useEffect(() => {
        if (!services || services.length <= 0) {
            return
        }

        const newDataPage = services.find(serv => serv._id === id);
        setDataPage(newDataPage);

        if (!newDataPage.flyer || newDataPage.flyer === '') {
            return
        }

        const fetchFlyer = async (flyerId) => {
            const req = await fetch(`https://api.pro-vide.eu/pdf/${flyerId}`);
            const res = await req.json();
            setFlyer(res ? res : '')
        }
        fetchFlyer(newDataPage?.flyer);
    }, [id, services]);

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };

    // Découpage filename
    const getFileName = (url) => {
        if (!url) return '';

        let filename = url.substring(url.lastIndexOf('/') + 1); // Supprime les caractères avant le dernier '/'
        filename = filename.replace(/(-[^-]+)(?=\.)/g, ''); // Supprime les caractères après le premier tiret avant l'extension
        return filename;
    };

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }

    const downloadFlyer = async (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank') // Force le téléchargement sans spécifier le nom de fichier, le nom original sera utilisé
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const request = async (event) => {
        event.preventDefault();

        let body = user._id ? { _id: user._id } : { ...formData };
        body.request = descripRequest;
        body.typeReq = 'serv';
        body.product_code = dataPage.name;

        try {
            const req = await fetch(`https://api.pro-vide.eu/user/sendRequest`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            });
            const res = await req.json();

            if (res !== true) {
                setMsgSuccessOrError('Une erreur est survenue');
                setTimeout(() => {
                    window.location.reload();
                }, 3500)
                return
            }
            setMsgSuccessOrError('Demande envoyer avec succès');
            setTimeout(() => {
                setMsgSuccessOrError('');
                setDescripRequest('');
                setRequestProd(false);
            }, 4000)
        }
        catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    return dataPage && (
        <div className='page_service'>
            {/* Video && Banner */}
            {dataPage.video && (
                <div className="container-banner-video">
                    <Banner />
                </div>
            )}

            {/* Slider & Descrip */}
            <div className="container-slider-descrip">
                <div className="slider-flyer">
                    <SliderServ data={dataPage} />

                    {flyer && flyer !== '' && (
                        <button onClick={() => downloadFlyer(flyer.pdf)} className="flyer">
                            <img src={icon_flyer} alt="Flyer" />
                            <p>{getFileName(flyer.pdf)}</p>
                        </button>
                    )}
                </div>

                <div className="descrip">
                    <h2>{dataPage.name}</h2>
                    <p>{transformText(dataPage.text)}</p>
                    <button onClick={() => setRequestProd(true)}>{button_request[lang]}</button>
                </div>
            </div>

            {/* Contact */}
            <div className="contact">
                <p><img src={icon_contact} alt="Icon" />{msg_contact[lang]}</p>

                <div className="contact-btn">
                    <a href="tel:+330255996505" className="btn-phone"><img src={icon_phone} alt="Icon" /> +33 2 55 99 65 05</a>
                    <button onClick={() => goPage('contact')} className="btn-email"><img src={icon_message} alt="Icon" />{button_contact[lang]}</button>
                </div>
            </div>


            {/* Request service */}
            {requestProd && (
                <section className="requestProd">
                    <div className="content">
                        {!msgSuccessOrError && msgSuccessOrError === '' ? (
                            <>
                                <i onClick={() => setRequestProd(false)} className="fas fa-xmark"></i>

                                <span className="title">{title_modal[lang]}</span>

                                <form onSubmit={(event) => request(event)}>
                                    <div className='input_model input_lock'>
                                        <label>{label_name_modal[lang]}</label>
                                        <input
                                            type="text"
                                            value={dataPage.name}
                                            className={`input_`}
                                            readOnly
                                        />
                                    </div>

                                    {(!user || !user._id) &&
                                        <div className="container_fields">
                                            {formData && <Input label={label_compagny[lang]} placeholder='JohnDoe company' value={formData.society} setValue={setFormData} labForm='society' />}
                                            {formData && <Input label={label_service[lang]} placeholder='Général' value={formData.service} setValue={setFormData} labForm='service' />}

                                            {formData && <Input label='Siret/Siren' placeholder='Numéro siret/siren' value={formData.siret} setValue={setFormData} labForm='siret' />}
                                            {formData && <Input label={label_email[lang]} placeholder='john-doe@gmail.com' value={formData.email} setValue={setFormData} labForm='email' />}

                                            {formData && <Input label={label_phone[lang]} placeholder='Saisissez votre mobile' value={formData.phoneNumber} setValue={setFormData} labForm='phoneNumber' />}

                                            {formData && <Input label={label_address[lang]} placeholder='10 route de paris' value={formData.address_street} setValue={setFormData} labForm='address_street' />}
                                            {formData && <Input label={label_city[lang]} placeholder='Strasbourg' value={formData.address_city} setValue={setFormData} labForm='address_city' />}

                                            {formData && <Input label={label_country[lang]} placeholder='France' value={formData.address_country} setValue={setFormData} labForm='address_country' />}
                                            {formData && <Input label={label_zipcode[lang]} placeholder='67000' value={formData.address_zip_code} setValue={setFormData} labForm='address_zip_code' />}
                                        </div>
                                    }

                                    <div className='input_model input_lock'>
                                        <label>{label_request_modal[lang]}</label>
                                        <textarea
                                            value={descripRequest}
                                            className={`input_`}
                                            placeholder={placeholder_request_modal[lang]}
                                            onChange={(e) => setDescripRequest(e.target.value)}
                                        ></textarea>
                                    </div>

                                    <button type="submit">{button_request[lang]}</button>
                                </form>
                            </>
                        ) : (
                            <p>{msgSuccessOrError}</p>
                        )}
                    </div>
                </section>
            )}
        </div>
    )
}

export default Services;