import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import FormSignup from './../../forms/FormSignup';
import FormLogin from './../../forms/FormLogin';

import Inscription from '../../modals/Inscription';

import "./style.css"
import { useSelector } from 'react-redux';

const title = {
    fr: `Espace client`,
    en: `Customer Area`,
    sp: `Área de cliente`,
    de: `Kundenbereich`
}
const text = {
    fr: `En créant votre compte, vous pourrez demander un devis,
    obtenir l'historique de vos demandes, suivre vos commandes...`,
    en: `By creating your account, you can request a quote, 
    get the history of your requests, track your orders...`,
    sp: `Al crear su cuenta, podrá solicitar un presupuesto,
    obtener el historial de sus solicitudes, seguir sus pedidos...`,
    de: `Durch die Erstellung Ihres Kontos können Sie ein Angebot anfordern,
    den Verlauf Ihrer Anfragen einsehen, Ihre Bestellungen verfolgen...`
}

function Login() {
    const navigate = useNavigate();
    const [inscrip, setInscrip] = useState(null);
    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    useEffect(() => {
        if (user && user.token && user.token !== '') {
            navigate('/')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return lang && (
        <div className='page_login'>
            <div className="container">
                <h2>{title[lang]}</h2>
                <p>{text[lang]}</p>
            </div>

            {inscrip && <Inscription data={inscrip} setData={setInscrip} />}

            <div className="container-form">
                <FormLogin />
                <FormSignup setInscrip={setInscrip} />
            </div>
        </div>
    )
}

export default Login;