import { useSelector } from "react-redux";

import equip1 from './../../assets/about/Pro-vide BE_inPixio.jpg';
import equip2 from './../../assets/about/Pro-vide-Plieuse à tole manuelle_inPixio.jpg';
import equip3 from './../../assets/about/Pro-vide-plieuse-pb-110T-metfab-2-1024x746_inPixio.jpg';
import equip4 from './../../assets/about/Pro-vide rouleuse 3 rouleaux.jpg';
import equip5 from './../../assets/about/Pro-vide-Cintreuse à galets_inPixio.jpg';
import equip6 from './../../assets/about/Pro-vide-Guillotine metal_inPixio.jpg';
import equip7 from './../../assets/about/Pro-vide-Scie à ruban_inPixio.jpg';
import equip8 from './../../assets/about/Pro-vide-Soudure TIG_inPixio.jpg';
import equip9 from './../../assets/about/Pro-vide-Soudure orbitale_inPixio.jpg';
import equip10 from './../../assets/about/Pro-vide-Soudure laser_inPixio.png';
import equip11 from './../../assets/about/Brasage induction.jpg';
import equip12 from './../../assets/about/pro-vide-Micro-Waterjet_Cutting_2-min_inPixio.png';
import equip13 from './../../assets/about/Pro-vide-Micro-Waterjet_Cutting_inPixio.png';
import equip14 from './../../assets/about/Pro-vide-Water jet cutting_inPixio.jpg';
import equip15 from './../../assets/about/Pro-vide-decoupe-laser-fibre.jpg';
import equip16 from './../../assets/about/Pro-vide-decoupe-laser-tube_inPixio.png';
import equip17 from './../../assets/about/Usinage.jpg';
import equip18 from './../../assets/about/Usinage2.jpg';
import equip19 from './../../assets/about/Usinage3.jpg';
import equip20 from './../../assets/about/Usinage4.jpg';
import equip21 from './../../assets/about/Usinage de ceramique.JPG';
import equip22 from './../../assets/about/Usinage de ceramique2.JPG';
import equip23 from './../../assets/about/Dégraissage décapage passivation.jpg';
import equip24 from './../../assets/about/Pro-vide-Etuve sous vide_inPixio.jpg';
import equip25 from './../../assets/about/pfeiffer-vacuum-leak-detector-asm340.png';
import equip26 from './../../assets/about/Distribution.png';
import equip27 from './../../assets/about/Conditionnement d’huile.png';
import equip28 from './../../assets/about/Rétrofit et Automatisation.png';
import equip29 from './../../assets/about/Assemblage.png';

import "./style.css"

const title = {
    fr: `Qui sommes nous`,
    en: `Who are we`,
    sp: `Quiénes somos`,
    de: `Wer sind wir`
}

const descrip = {
    fr: `Spécialiste du vide, des matériaux et des Couches-minces`,
    en: `Specialist in vacuum, materials and thin layers`,
    sp: `Especialista en vacío, materiales y capas finas`,
    de: `Spezialist für Vakuum, Materialien und dünne Schichten`
}

const subtitle_visions = {
    fr: `Notre vision :`,
    en: `Our vision :`,
    sp: `Nuestra visión :`,
    de: `Unsere vision :`
}
const visions = {
    fr: [
        `Apporter des solutions innovantes dans le domaine du vide, des matériaux et des couches-minces`,
        `Créer des partenariats permettant d’élargir nos compétences et nos connaissances`,
        `Développer des solutions qui préservent l’environnement et les ressources naturelles`,
        `Accompagner nos collaborateurs pour qu’ils s’épanouissent dans leurs tâches quotidiennes`,
        `Faire grandir nos clients pour grandir avec eux`
    ],
    en: [
        `Provide innovative solutions in the field of vacuum, materials and thin films`,
        `Create partnerships to broaden our skills and knowledge`,
        `Develop solutions that preserve the environment and natural resources`,
        `Support our employees so that they flourish in their daily tasks`,
        `Grow our customers to grow with them`
    ],
    sp: [
        `Aportar soluciones innovadoras en el campo del vacío, materiales y películas finas`,
        `Crear alianzas para ampliar nuestras habilidades y conocimientos`,
        `Desarrollar soluciones que preserven el medio ambiente y los recursos naturales`,
        `Apoyar a nuestros empleados para que prosperen en sus tareas diarias`,
        `Hacer crecer a nuestros clientes para crecer con ellos`
    ],
    de: [
        `Innovative Lösungen im Bereich Vakuum, Materialien und Dünnschichten anbiete`,
        `Schaffen Sie Partnerschaften, um unsere Fähigkeiten und unser Wissen zu erweiter`,
        `Lösungen entwickeln, die die Umwelt und natürliche Ressourcen schone`,
        `Unterstützen Sie unsere Mitarbeiter, damit sie bei ihren täglichen Aufgaben erfolgreich sin`,
        `Unsere Kunden wachsen lassen, um mit ihnen zu wachse.`
    ]
};

const subtitle_valeurs = {
    fr: `Nos valeurs :`,
    en: `Our values :`,
    sp: `Nuestros valores :`,
    de: `Unsere Werte :`
}
const valeurs = {
    fr: [
        `L’humain est au centre, nous respectons l’homme et lui faisons confiance`,
        `Nous donnons à nos collaborateurs les moyens pour qu’ils s’épanouissent et les accompagnons`,
        `Nous incitons chacun à développer ses capacités et à faire preuve d’initiative`
    ],
    en: [
        `Humans are at the center, we respect humans and trust them`,
        `We give our employees the means to flourish and support them`,
        `We encourage everyone to develop their abilities and show initiative`
    ],
    sp: [
        "El ser humano es el centro, lo respetamos y confiamos en él",
        `Damos a nuestros empleados los medios para prosperar y apoyarlos`,
        `Animamos a todos a desarrollar sus capacidades y mostrar iniciativa`
    ],
    de: [
        `Der Mensch steht im Mittelpunkt, wir respektieren den Menschen und vertrauen ihm`,
        `Wir geben unseren Mitarbeitern die Möglichkeit, sich zu entfalten und unterstützen sie`,
        `Wir ermutigen jeden, seine Fähigkeiten zu entwickeln und Initiative zu zeigen.`
    ]
};

const subtitle_regles = {
    fr: `Nos règles de conduite :`,
    en: `Our rules of conduct :`,
    sp: `Nuestras reglas de conducta :`,
    de: `Unsere Verhaltensregeln :`
}
const regles = {
    fr: [
        `Partager la vision de l’entreprise et ses valeurs`,
        `Être ouvert et savoir écouter`,
        `Apporter son soutien et son aide à ceux qui en ont besoin`,
        `Ne pas chercher des coupables mais des solutions`,
        `Comprendre les erreurs et les transformer en expérience`,
        `Être curieux et accepter les changements`,
        `Créer une dynamique d’équipe et une cohésion`,
        `Observer et prendre soins des autres`
    ],
    en: [
        `Share the company’s vision and its values`,
        `Be open and know how to listen`,
        `Provide support and help to those who need it`,
        `Do not look for culprits but for solutions`,
        `Understand mistakes and transform them into experience`,
        `Be curious and accept changes`,
        `Create team dynamics and cohesion`,
        `Observe and care for others`
    ],
    sp: [
        `Compartir la visión de la empresa y sus valores`,
        `Sea abierto y sepa escuchar`,
        `Brindar apoyo y ayuda a quienes lo necesitan`,
        `No busquemos culpables sino soluciones`,
        `Comprender los errores y transformarlos en experiencia`,
        `Sé curioso y acepta los cambios`,
        `Crear dinámica y cohesión de equipo`,
        `Observar y cuidar a los demás`
    ],
    de: [
        `Teilen Sie die Vision und die Werte des Unternehmens`,
        `Seien Sie offen und wissen Sie, wie man zuhört`,
        `Bieten Sie denjenigen Unterstützung und Hilfe, die sie brauchen`,
        `Suchen Sie nicht nach Schuldigen, sondern nach Lösungen`,
        `Fehler verstehen und in Erfahrungen umwandeln`,
        `Seien Sie neugierig und akzeptieren Sie Veränderungen`,
        `Teamdynamik und Zusammenhalt schaffen`,
        `Andere beobachten und für sie sorgen`
    ]
};

const subtitle_comp = {
    fr: `Nos compétences :`,
    en: `Our skills :`,
    sp: `Nuestras habilidades :`,
    de: `Unsere Fähigkeiten :`
}
const comp1 = {
    fr: [
        {
            title: `Bureau d’étude`,
            texte: `Conception à partir d’un cahier des charges, ou d’un croquis notre équipe vous accompagne et vous fais des propositions permettant d’optimiser les couts et facilité la maintenance`
        },
        {
            title: `Manipulation sous vide`,
            texte: `Nous avons développé toute une gamme d’éléments standards pour la manipulation sous vide tel que : passages de rotations, translateur, manipulateur, tilt, platine XY, …`
        },
        {
            title: `Standard du vide`,
            texte: `Nos équipements nous permettent de réaliser plus de 80% des composants selon la norme Pneurop et Conflat`
        },
        {
            title: `Chaudronnerie blanche`,
            texte: `Roulage, pliage, Cisaillage, sciage, soudure, mise en forme de tôle afin de réaliser vos enceintes sous vide`
        },
        {
            title: `Travail du tube`,
            texte: `Cintrage découpe, soudage, brasage`
        },
        {
            title: `Soudure`,
            texte: `TIG, MIG, soudure Laser, brasage`
        },
        {
            title: `Découpe`,
            texte: `Découpe Jet d’eau tête 5 axes et découpe laser`
        },
        {
            title: `Usinage`,
            texte: `Inox, aluminium, PTFE, PEEK, …`
        },
    ],
    en: [
        {
            title: `Design office`,
            texte: `Design based on specifications, or a sketch, our team supports you and makes proposals to optimize costs and facilitate maintenance.`
        },
        {
            title: `Vacuum handling`,
            texte: `We have developed a whole range of standard elements for vacuum handling such as: rotation passages, translator, manipulator, tilt, XY stage, etc.`
        },
        {
            title: `Vacuum standard`,
            texte: `Our equipment allows us to produce more than 80% of components according to the Pneurop and Conflat standard.`
        },
        {
            title: `White boilermaking`,
            texte: `Rolling, folding, shearing, sawing, welding, sheet metal shaping to create your vacuum chambers`
        },
        {
            title: `Tube work`,
            texte: `Bending cutting, welding, brazing`
        },
        {
            title: `Welding`,
            texte: `TIG, MIG, Laser welding, brazing`
        },
        {
            title: `Cutting`,
            texte: `Waterjet cutting 5-axis head and laser cutting`
        },
        {
            title: `Machining`,
            texte: `Stainless steel, aluminum, PTFE, PEEK, …`
        }
    ],
    sp: [
        {
            title: `Oficina de diseño`,
            texte: `Diseño basado en especificaciones, o croquis, nuestro equipo lo apoya y realiza propuestas para optimizar costos y facilitar el mantenimiento.`
        },
        {
            title: `Manipulación al vacío`,
            texte: `Hemos desarrollado toda una gama de elementos estándar para el manejo del vacío como: pasajes de rotación, traductor, manipulador, basculante, platina XY, etc.`
        },
        {
            title: `Estándar de vacío`,
            texte: `Nuestros equipos nos permiten producir más del 80% de los componentes según el estándar Pneurop y Conflat.`
        },
        {
            title: `Calderería blanca`,
            texte: `Laminado, plegado, cizallado, aserrado, soldado, moldeado de chapa para crear sus cámaras de vacío`
        },
        {
            title: `Trabajo de tubo`,
            texte: `Doblado, corte, soldadura, soldadura fuerte.`
        },
        {
            title: `Soldadura`,
            texte: `TIG, MIG, soldadura láser, soldadura fuerte`
        },
        {
            title: `Corte`,
            texte: `Corte por chorro de agua con cabezal de 5 ejes y corte por láser`
        },
        {
            title: `Mecanizado`,
            texte: `Acero inoxidable, aluminio, PTFE, PEEK,…`
        }
    ],
    de: [
        {
            title: `Design Büro`,
            texte: `Entwerfen Sie anhand von Spezifikationen oder einer Skizze. Unser Team unterstützt Sie und unterbreitet Ihnen Vorschläge zur Kostenoptimierung und Erleichterung der Wartung.`
        },
        {
            title: `Vakuumhandhabung`,
            texte: `Wir haben eine ganze Reihe von Standardelementen für die Vakuumhandhabung entwickelt, wie zum Beispiel: Rotationskanäle, Übersetzer, Manipulator, Neigung, XY-Tisch usw.`
        },
        {
            title: `Vakuumstandard`,
            texte: `Unsere Ausrüstung ermöglicht es uns, mehr als 80 % der Komponenten nach dem Pneurop- und Conflat-Standard zu produzieren.`
        },
        {
            title: `Weißer Kesselbau`,
            texte: `Rollen, Falten, Scheren, Sägen, Schweißen, Blechformen zur Herstellung Ihrer Vakuumkammern`
        },
        {
            title: `Rohrarbeiten`,
            texte: `Biegen, Schneiden, Schweißen, Löten`
        },
        {
            title: `Schweißen`,
            texte: `TIG, MIG, Laserschweißen, Hartlöten`
        },
        {
            title: `Schneiden`,
            texte: `Wasserstrahlschneiden mit 5-Achsen-Kopf und Laserschneiden`
        },
        {
            title: `Bearbeitung`,
            texte: `Edelstahl, Aluminium, PTFE, PEEK, …`
        }
    ]
};

const title_spe = {
    fr: `Usinage de matériaux dur :`,
    en: `Machining of hard materials:`,
    sp: `Mecanizado de materiales duros:`,
    de: `Bearbeitung von harten Materialien:`
};
const subtitle_spe1 = {
    fr: `Métaux :`,
    en: `Metals:`,
    sp: `Metales:`,
    de: `Metalle:`
};
const text_spe1 = {
    fr: `Titane, Inconel, Molybdène, Tantale, Tungstène, Zirconium, …`,
    en: `Titanium, Inconel, Molybdenum, Tantalum, Tungsten, Zirconium, ...`,
    sp: `Titanio, Inconel, Molibdeno, Tántalo, Tungsteno, Zirconio, ...`,
    de: `Titan, Inconel, Molybdän, Tantal, Wolfram, Zirkonium, ...`
};
const subtitle_spe2 = {
    fr: `Céramique :`,
    en: `Ceramic:`,
    sp: `Cerámica:`,
    de: `Keramik:`
};
const text_spe2 = {
    fr: `Macor, Al2O3, SiC, Si3N4, AlN, ZrO2, Saphire, Silicium, …`,
    en: `Macor, Al2O3, SiC, Si3N4, AlN, ZrO2, Sapphire, Silicon, ...`,
    sp: `Macor, Al2O3, SiC, Si3N4, AlN, ZrO2, Zafiro, Silicio, ...`,
    de: `Macor, Al2O3, SiC, Si3N4, AlN, ZrO2, Saphir, Silizium, ...`
};


const comp2 = {
    fr: [
        {
            title: `Nettoyage`,
            texte: `Bac à ultrasons, bain d’attaque acide rinçage, passivation, rinçage à l’eau DI`
        },
        {
            title: `Dégazage`,
            texte: `Dégazage sous vide jusqu’à 450°C`
        },
        {
            title: `Câblage et automatisation`,
            texte: `Nous câblons nos armoires électriques et automatisons nos systèmes`
        },
        {
            title: `Assemblage`,
            texte: `Du porte substrat chauffant ou refroidis au système sous vide entièrement automatisé`
        },
        {
            title: `La distribution`,
            texte: `Nous distribuons des pompes à vide primaire et secondaire de la marque Kashiyma et Shimatzu, marques Japonaises reconnues pour la qualité de leurs produits`
        },
        {
            title: `Le conditionnement`,
            texte: `Nous conditionnons des huiles de pompes à vide avec suivis documentaire`
        },
        {
            title: `Le service`,
            texte: `Afin de répondre aux besoins de nos clients, nous avons développer un service maintenance qui nous permet non seulement d’entretenir les pompes que nous distribuons, mais aussi nos compétences dans le domaine de vide, la connaissance des process de vide et nos habilitations dans le domaine du froid, nous permettent de dépanner et de vous proposer l’entretien de vos équipements sous vide ainsi que de vos « Polycold »`
        },
        {
            title: `Détection de fuite`,
            texte: `Que ce soit pour la fabrication d’enceinte, de système de manipulation ou de SAV, la détection de fuite est un passage obligé, nous sommes équipés de détecteur de fuite Hélium et d’analyseur de gaz, nous permettant de détecter d’une part les fuite, mais aussi d’analyser les problèmes de dégazage`
        },
    ],
    en: [
        {
            title: `Cleaning`,
            texte: `Ultrasonic tank, acid etch bath, rinsing, passivation, DI water rinsing`
        },
        {
            title: `Degassing`,
            texte: `Vacuum degassing up to 450°C`
        },
        {
            title: `Wiring and automation`,
            texte: `We wire our electrical cabinets and automate our systems`
        },
        {
            title: `Assembly`,
            texte: `From heated or cooled substrate holder to fully automated vacuum system`
        },
        {
            title: `The distribution`,
            texte: `We distribute primary and secondary vacuum pumps from the Kashiyma and Shimatzu brands, Japanese brands recognized for the quality of their products`
        },
        {
            title: `The conditioning`,
            texte: `We package vacuum pump oils with documentary follow-up`
        },
        {
            title: `The service`,
            texte: `In order to meet the needs of our customers, we have developed a maintenance service which allows us not only to maintain the pumps that we distribute, but also our skills in the field of vacuum, knowledge of vacuum processes and our authorizations in the field of vacuum. cold field, allow us to troubleshoot and offer maintenance for your vacuum equipment as well as your “Polycold”`
        },
        {
            title: `Leak detection`,
            texte: `Whether for the manufacture of enclosures, handling systems or after-sales service, leak detection is a must; we are equipped with a Helium leak detector and a gas analyzer, allowing us to detect on the one hand leaks, but also to analyze degassing problems`
        },
    ],
    sp: [
        {
            title: `Limpieza`,
            texte: `Tanque ultrasónico, baño de grabado ácido, enjuague, pasivación, enjuague con agua desionizada`
        },
        {
            title: `Desgasificación`,
            texte: `Desgasificación al vacío hasta 450°C`
        },
        {
            title: `Cableado y automatización`,
            texte: `Cableamos nuestros cuadros eléctricos y automatizamos nuestros sistemas.`
        },
        {
            title: `Ensamblaje`,
            texte: `Desde soportes de sustrato calentados o refrigerados hasta sistemas de vacío totalmente automatizados`
        },
        {
            title: `La distribución`,
            texte: `Distribuimos bombas de vacío primarias y secundarias de las marcas Kashiyma y Shimatzu, marcas japonesas reconocidas por la calidad de sus productos.`
        },
        {
            title: `El acondicionamiento`,
            texte: `Envasamos aceites para bombas de vacío con seguimiento documental`
        },
        {
            title: `El servicio`,
            texte: `Para satisfacer las necesidades de nuestros clientes, hemos desarrollado un servicio de mantenimiento que nos permite no solo mantener las bombas que distribuimos, sino también nuestras habilidades en el campo del vacío, conocimiento de los procesos de vacío y nuestras autorizaciones en el campo. campo frío, permítanos solucionar problemas y ofrecerle mantenimiento a su equipo de vacío así como a su “Polycold”`
        },
        {
            title: `Detección de fugas`,
            texte: `Ya sea para la fabricación de envolventes, sistemas de manipulación o servicio postventa, la detección de fugas es imprescindible, estamos equipados con un detector de fugas de helio y un analizador de gases, lo que nos permite detectar fugas, pero también analizar problemas de desgasificación;`
        },
    ],
    de: [
        {
            title: `Reinigung`,
            texte: `Ultraschalltank, Säureätzbad, Spülung, Passivierung, Spülung mit DI-Wasser`
        },
        {
            title: `Entgasung`,
            texte: `Vakuumentgasung bis 450°C`
        },
        {
            title: `Verkabelung und Automatisierung`,
            texte: `Wir verkabeln unsere Schaltschränke und automatisieren unsere Anlagen`
        },
        {
            title: `Montage`,
            texte: `Vom beheizten oder gekühlten Substrathalter bis zum vollautomatischen Vakuumsystem`
        },
        {
            title: `Der Vertrieb`,
            texte: `Wir vertreiben primäre und sekundäre Vakuumpumpen der Marken Kashiyma und Shimatzu, japanische Marken, die für die Qualität ihrer Produkte bekannt sind`
        },
        {
            title: `Die Konditionierung`,
            texte: `Wir verpacken Vakuumpumpenöle mit dokumentarischer Nachverfolgung`
        },
        {
            title: `Der Service`,
            texte: `Um den Bedürfnissen unserer Kunden gerecht zu werden, haben wir einen Wartungsservice entwickelt, der es uns ermöglicht, nicht nur die von uns vertriebenen Pumpen zu warten, sondern auch unsere Fähigkeiten im Vakuumbereich, unser Wissen über Vakuumprozesse und unsere Berechtigungen in diesem Bereich zu nutzen. Kaltfeld, erlauben Sie uns, Fehler zu beheben und Wartung für Ihre Vakuumausrüstung sowie Ihr „Polycold“ anzubieten.`
        },
        {
            title: `Leckerkennung`,
            texte: `Ob bei der Herstellung von Gehäusen, Handlingsystemen oder im After-Sales-Service, die Lecksuche ist ein Muss; wir sind mit einem Helium-Lecksucher und einem Gasanalysator ausgestattet, mit denen wir einerseits Lecks erkennen, aber auch Entgasungsprobleme analysieren können`
        },
    ]
};

const our_stuffs = {
    title: {
        fr: `Nos Équipements :`,
        en: `Our Equipment:`,
        sp: `Nuestros Equipos:`,
        de: `Unsere Ausrüstung:`
    },
    our_stuff_span_1: {
        fr: `Bureau d’étude :`,
        en: `Design Office :`,
        sp: `Oficina de diseño :`,
        de: `Konstruktionsbüro :`
    },
    our_stuff_span_2: {
        fr: `Chaudronnerie blanche :`,
        en: `White Metalworking :`,
        sp: `Calderería blanca :`,
        de: `Weiße Blechbearbeitung :`
    },
    our_stuff_span_3: {
        fr: `Découpe :`,
        en: `Cutting :`,
        sp: `Corte :`,
        de: `Schneiden :`
    },
    our_stuff_text_1: {
        fr: `2 postes équipés de la solution SolidWorks`,
        en: `2 workstations equipped with SolidWorks solution`,
        sp: `2 estaciones de trabajo equipadas con la solución SolidWorks`,
        de: `2 Arbeitsplätze ausgestattet mit der SolidWorks-Lösung`
    },
    our_stuff_text_2: {
        fr: `Plieuse manuelle longueur 3 m pour tôle inox 3 mm`,
        en: `Manual folding machine, 3m length for 3mm stainless steel sheet`,
        sp: `Máquina plegadora manual, longitud de 3m para chapa de acero inoxidable de 3mm`,
        de: `Manuelle Biegemaschine, 3 m Länge für 3 mm Edelstahlblech`
    },
    our_stuff_text_3: {
        fr: `Plieuse 110T longueur 3m tôle inox jusqu’à 6 mm`,
        en: `110T bending machine, 3m length for stainless steel sheet up to 6mm`,
        sp: `Máquina plegadora de 110T, longitud de 3m para chapa de acero inoxidable de hasta 6mm`,
        de: `110T Biegemaschine, 3 m Länge für Edelstahlblech bis 6 mm`
    },
    our_stuff_text_4: {
        fr: `Rouleuse 3 rouleaux`,
        en: `3-roll bending machine`,
        sp: `Máquina de curvado de 3 rodillos`,
        de: `3-Walzen-Biegemaschine`
    },
    our_stuff_text_5: {
        fr: `Cintreuse à galets`,
        en: `Roll bender`,
        sp: `Curvadora de rodillos`,
        de: `Walzenbiegemaschine`
    },
    our_stuff_text_6: {
        fr: `Guillotine`,
        en: `Guillotine`,
        sp: `Guillotina`,
        de: `Guillotine`
    },
    our_stuff_text_7: {
        fr: `Scie à ruban`,
        en: `Bandsaw`,
        sp: `Sierra de cinta`,
        de: `Bandsäge`
    },
    our_stuff_text_8: {
        fr: `Poste à souder TIG`,
        en: `TIG welding station`,
        sp: `Estación de soldadura TIG`,
        de: `TIG-Schweißstation`
    },
    our_stuff_text_9: {
        fr: `Soudeuse orbitale`,
        en: `Orbital welder`,
        sp: `Soldadora orbital`,
        de: `Orbitalschweißer`
    },
    our_stuff_text_10: {
        fr: `Poste à souder Laser`,
        en: `Laser welding station`,
        sp: `Estación de soldadura láser`,
        de: `Laserschweißstation`
    },
    our_stuff_text_11: {
        fr: `Brasage induction`,
        en: `Induction brazing`,
        sp: `Soldadura por inducción`,
        de: `Induktionslöten`
    },
    our_stuff_text_12: {
        fr: `Micro Découpe Jet d’eau tolérance 0.01mm`,
        en: `Micro waterjet cutting tolerance 0.01mm`,
        sp: `Corte por microchorro de agua con tolerancia de 0.01mm`,
        de: `Mikro-Wasserstrahlschneiden Toleranz 0,01 mm`
    },
    our_stuff_text_13: {
        fr: `Découpe jet d’eau 5 axes`,
        en: `5-axis waterjet cutting`,
        sp: `Corte por chorro de agua de 5 ejes`,
        de: `5-Achsen-Wasserstrahlschneiden`
    },
    our_stuff_text_14: {
        fr: `Découpe laser`,
        en: `Laser cutting`,
        sp: `Corte por láser`,
        de: `Laserschneiden`
    },
    our_stuff_text_15: {
        fr: `Découpe Laser tube`,
        en: `Tube laser cutting`,
        sp: `Corte láser de tubo`,
        de: `Rohrlaserschneiden`
    },
    our_stuff_text_16: {
        fr: `Usinage : Inox, aluminium, PTFE, PEEK, …`,
        en: `Machining: Stainless steel, aluminum, PTFE, PEEK, …`,
        sp: `Mecanizado: acero inoxidable, aluminio, PTFE, PEEK, …`,
        de: `Bearbeitung: Edelstahl, Aluminium, PTFE, PEEK, …`
    },
    our_stuff_text_17: {
        fr: `Usinage de matériaux durs`,
        en: `Machining of hard materials`,
        sp: `Mecanizado de materiales duros`,
        de: `Bearbeitung harter Materialien`
    },
    our_stuff_text_18: {
        fr: `Dégraissage, décapage, passivation`,
        en: `Degreasing, pickling, passivation`,
        sp: `Desengrase, decapado, pasivación`,
        de: `Entfettung, Beizen, Passivierung`
    },
    our_stuff_text_19: {
        fr: `Dégazage`,
        en: `Degassing`,
        sp: `Desgasificación`,
        de: `Entgasung`
    },
    our_stuff_text_20: {
        fr: `Détection de fuite`,
        en: `Leak detection`,
        sp: `Detección de fugas`,
        de: `Lecksuche`
    },
    our_stuff_text_21: {
        fr: `Distribution`,
        en: `Distribution`,
        sp: `Distribución`,
        de: `Verteilung`
    },
    our_stuff_text_22: {
        fr: `Conditionnement d’huile :`,
        en: `Oil conditioning :`,
        sp: `Condicionamiento de aceite :`,
        de: `Ölkonditionierung :`
    },
    our_stuff_text_23: {
        fr: `Rétrofit et Automatisation`,
        en: `Retrofit and Automation`,
        sp: `Retrofit y automatización`,
        de: `Retrofit und Automatisierung`
    },
    our_stuff_text_24: {
        fr: `Assemblage`,
        en: `Assembly`,
        sp: `Montaje`,
        de: `Montage`
    },
}

function About() {
    const lang = useSelector(state => state.menu.lang);

    return (
        <div className='page_about'>
            <h2>{title[lang]}</h2>
            <p className="subTitle">{descrip[lang]}</p>

            <ul className="our_vision rule_first">
                <h3>{subtitle_visions[lang]}</h3>
                {visions[lang].map((text, idx) => <li key={idx}>- {text}</li>)}
            </ul>

            <ul className="our_values rule_first">
                <h3>{subtitle_valeurs[lang]}</h3>
                {valeurs[lang].map((text, idx) => <li key={idx}>- {text}</li>)}
            </ul>

            <ul className="rules_of_conduct rule_first">
                <h3>{subtitle_regles[lang]}</h3>
                {regles[lang].map((text, idx) => <li key={idx}>- {text}</li>)}
            </ul>

            <ul className="our_skills">
                <h3>{subtitle_comp[lang]}</h3>
                {comp1[lang].map((text, idx) => <li key={idx}><span>{text.title} : </span>{text.texte}</li>)}

                <li>
                    <span>{title_spe[lang]}</span>
                    <p className="left_pad"><span>{subtitle_spe1[lang]} </span>{text_spe1[lang]}</p>
                    <p className="left_pad"><span>{subtitle_spe2[lang]} </span>{text_spe2[lang]}</p>
                </li>

                {comp2[lang].map((text, idx) => <li key={idx}><span>{text.title} : </span>{text.texte}</li>)}
            </ul>

            <ul className="our_stuffs">
                <h3>{our_stuffs.title[lang]}</h3>

                <li><span>{our_stuffs.our_stuff_span_1[lang]}</span> {our_stuffs.our_stuff_text_1[lang]}</li>
                <li><img src={equip1} alt="illustration" /></li>

                <li><span>{our_stuffs.our_stuff_span_2[lang]}</span><br /> {our_stuffs.our_stuff_text_2[lang]}</li>
                <li><img src={equip2} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_3[lang]}</li>
                <li><img src={equip3} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_4[lang]}</li>
                <li><img src={equip4} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_5[lang]}</li>
                <li><img src={equip5} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_6[lang]}</li>
                <li><img src={equip6} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_7[lang]}</li>
                <li><img src={equip7} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_8[lang]}</li>
                <li><img src={equip8} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_9[lang]}</li>
                <li><img src={equip9} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_10[lang]}</li>
                <li><img src={equip10} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_11[lang]}</li>
                <li><img src={equip11} alt="illustration" /></li>

                <li><span>{our_stuffs.our_stuff_span_3[lang]}</span><br /> {our_stuffs.our_stuff_text_12[lang]}</li>
                <li><img src={equip12} alt="illustration" /><img src={equip13} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_13[lang]}</li>
                <li><img src={equip14} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_14[lang]}</li>
                <li><img src={equip15} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_15[lang]}</li>
                <li><img src={equip16} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_16[lang]}</li>
                <li><img src={equip17} alt="illustration" /></li>
                <li><img src={equip18} alt="illustration" /></li>
                <li><img src={equip19} alt="illustration" /></li>
                <li><img src={equip20} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_17[lang]}</li>
                <li><img src={equip21} alt="illustration" /></li>
                <li><img src={equip22} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_18[lang]}</li>
                <li><img src={equip23} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_19[lang]}</li>
                <li><img src={equip24} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_20[lang]}</li>
                <li><img src={equip25} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_21[lang]}</li>
                <li><img src={equip26} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_22[lang]}</li>
                <li><img src={equip27} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_23[lang]}</li>
                <li><img src={equip28} alt="illustration" /></li>

                <li>{our_stuffs.our_stuff_text_24[lang]}</li>
                <li><img src={equip29} alt="illustration" /></li>
            </ul>
        </div>
    )
}

export default About;