import { useState } from 'react';
import { useSelector } from 'react-redux';

import Input from './../../components/Input';

import icon from './../../assets/svg/phone_settings-icon.svg';

import "./style.css"

const title = {
    fr: `Adresse mail`,
    en: `Email address`,
    sp: `Dirección de correo electrónico`,
    de: `E-Mail-Adresse`
}
const label_input_lock = {
    fr: `Votre adresse mail`,
    en: `Your email address`,
    sp: `Su dirección de correo electrónico`,
    de: `Ihre E-Mail-Adresse`
}
const button_edit_email = {
    fr: `Modifier mon email`,
    en: `Edit my email`,
    sp: `Editar mi correo electrónico`,
    de: `Meine E-Mail-Adresse bearbeiten`
}
const label_email = {
    fr: `Nouvelle adresse mail`,
    en: `New email address`,
    sp: `Nueva dirección de correo electrónico`,
    de: `Neue E-Mail-Adresse`
}
const placeholder_email = {
    fr: `Saisissez votre adresse mail`,
    en: `Enter your email address`,
    sp: `Ingrese su dirección de correo electrónico`,
    de: `Geben Sie Ihre E-Mail-Adresse ein`
}
const label_email_conf = {
    fr: `Confirmation de l’adresse mail`,
    en: `Confirm email address`,
    sp: `Confirmación de la dirección de correo electrónico`,
    de: `E-Mail-Adresse bestätigen`
}
const placeholder_email_conf = {
    fr: `Confirmer votre adresse mail`,
    en: `Confirm your email address`,
    sp: `Confirme su dirección de correo electrónico`,
    de: `Bestätigen Sie Ihre E-Mail-Adresse`
}
const button_return = {
    fr: `Annuler`,
    en: `Cancel`,
    sp: `Cancelar`,
    de: `Abbrechen`
}
const button_edit = {
    fr: `Modifier`,
    en: `Edit`,
    sp: `Modificar`,
    de: `Bearbeiten`
}

const title_modal = {
    fr: `Confirmation`,
    en: `Confirmation`,
    sp: `Confirmación`,
    de: `Bestätigung`
}
const text_modal = {
    fr: `Afin de confirmer votre demande, veuillez renseigner votre mot de passe :`,
    en: `To confirm your request, please enter your password:`,
    sp: `Para confirmar su solicitud, por favor ingrese su contraseña:`,
    de: `Um Ihre Anfrage zu bestätigen, geben Sie bitte Ihr Passwort ein:`
}
const button_conf = {
    fr: `Confirmer`,
    en: `Confirm`,
    sp: `Confirmar`,
    de: `Bestätigen`
}
const title_msg_conf = {
    fr: `Validation`,
    en: `Validation`,
    sp: `Validación`,
    de: `Validierung`
}
const text_msg_conf = {
    fr: `Afin de valider votre demande, veuillez renseigner le code envoyé à votre nouvelle adresse mail :`,
    en: `To validate your request, please enter the code sent to your new email address:`,
    sp: `Para validar su solicitud, por favor ingrese el código enviado a su nueva dirección de correo electrónico:`,
    de: `Um Ihre Anfrage zu bestätigen, geben Sie bitte den an Ihre neue E-Mail-Adresse gesendeten Code ein:`
}
const button_valid = {
    fr: `Valider`,
    en: `Validate`,
    sp: `Validar`,
    de: `Bestätigen`
}

function AddressMail({ email }) {
    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [code, setCode] = useState('');
    const [lastPw, setLastPw] = useState('');

    const [msgError, setMsgError] = useState('');
    const [msgSucess, setMsgSucess] = useState('');

    const [mdlConf, setMdlConf] = useState(false);
    const [codeIsSend, setCodeIsSend] = useState(false);
    const [editEmail, setEditEmail] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        _email: ''
    });

    // Envoie du code de verification
    const sendVerifEmail = async () => {
        // Verif 
        if (formData.email !== formData._email) {
            setMsgError('Les deux adresses doivent etre identiques');
            setTimeout(() => {
                setMsgError('');
            }, 5000);
            return
        }

        // Envoi
        const req = await fetch(`https://api.pro-vide.eu/user/sendChangeEmail/${user._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ password: lastPw, email: formData.email })
        });
        const res = await req.json();

        // Go => validationUpdate()
        if (res === true) {
            setCodeIsSend(true);
        }
    }

    // Validation de la mise à jour
    const validationUpdate = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/changeEmail/${user._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ code: code, email: formData.email })
        });
        const res = await req.json();

        if (res === true) {
            setMsgSucess('Votre adresse à bien était mise à jour');

            setTimeout(() => {
                setCode('');
                setLastPw('');
                setMsgError('');
                setMsgSucess('');
                setMdlConf(false);
                setCodeIsSend(false);
                setEditEmail(false);
                setFormData({
                    email: '',
                    _email: ''
                });
            }, 5000);
        }
    }


    return (
        <div className='modal modal_addressMail'>
            <h3><img src={icon} alt="Icon" />{title[lang]}</h3>

            {!editEmail && (
                <div className="container_fields">
                    <div className='input_model input_lock'>
                        <label>{label_input_lock[lang]}</label>
                        <input
                            type="text"
                            value={email}
                            className={`input_email`}
                            readOnly
                        />
                    </div>

                    <button onClick={() => setEditEmail(true)} className='editAddressMail'>{button_edit_email[lang]}</button>
                </div>
            )}

            {/* Editor email */}
            {editEmail && (
                <>
                    <div className="container_fields">
                        <Input label={label_email[lang]} placeholder={placeholder_email[lang]} value={formData.email} setValue={setFormData} labForm='email' />
                        <Input label={label_email_conf[lang]} placeholder={placeholder_email_conf[lang]} value={formData._email} setValue={setFormData} labForm='_email' />
                    </div>

                    <div className="container_btn">
                        <button onClick={() => setEditEmail(false)} className='btn_return'>{button_return[lang]}</button>
                        <button onClick={() => setMdlConf(true)} className='btn_edit'>{button_edit[lang]}</button>
                    </div>
                </>
            )}


            {/* Modals */}
            {mdlConf && (
                <div className="conf_edit_account">

                    {/* Modal mot de passe */}
                    {!codeIsSend && (
                        <div className="content">
                            <i className="fas fa-xmark" onClick={() => setMdlConf(false)}></i>

                            <span>{title_modal[lang]}</span>
                            <p>{text_modal[lang]}</p>

                            <input
                                className='input_pw_for_conf'
                                type="password"
                                placeholder='********************'
                                value={lastPw}
                                onChange={(e) => setLastPw(e.target.value)}
                            />

                            {msgError && msgError !== '' && <p className='msgError'>{msgError}</p>}
                            <button onClick={() => sendVerifEmail()}>{button_conf[lang]}</button>
                        </div>
                    )}

                    {/* Modal code */}
                    {codeIsSend && msgSucess === '' && (
                        <div className="content">
                            <span>{title_msg_conf[lang]}</span>
                            <p>{text_msg_conf[lang]}</p>

                            <input
                                className='input_pw_for_conf'
                                type="text"
                                placeholder='********************'
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />

                            {msgError && msgError !== '' && <p className='msgError'>{msgError}</p>}
                            <button onClick={() => validationUpdate()}>{button_valid[lang]}</button>
                        </div>
                    )}

                    {/* Message de success */}
                    {msgSucess !== '' && (
                        <div className="content">
                            <p className='msgConf'>{msgSucess}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AddressMail;