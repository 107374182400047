import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { close, logout } from "../../redux";

import Address from "../../modals/Address";
import AddressMail from "../../modals/AddressMail";
import Coordinates from "../../modals/Coordinates";
import Password from "../../modals/Password";

import "./style.css"

const title = {
    fr: `Paramètres de compte`,
    en: `Account Settings`,
    sp: `Configuración de la cuenta`,
    de: `Kontoeinstellungen`
}

function Settings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [dataUser, setDataUser] = useState(null);

    // Logout
    const disconnection = () => {
        sessionStorage.removeItem('_id');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('password');
        sessionStorage.removeItem('lastname');
        sessionStorage.removeItem('firstname');

        localStorage.removeItem('_id');
        localStorage.removeItem('token');
        localStorage.removeItem('password');
        localStorage.removeItem('lastname');
        localStorage.removeItem('firstname');

        dispatch(logout());
        dispatch(close());
    }

    useEffect(() => {
        if (!user) {
            return
        }

        const fetchDataUser = async () => {
            const req = await fetch(`https://api.pro-vide.eu/user/get_account`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    id: sessionStorage.getItem('_id'),
                    password: sessionStorage.getItem('password')
                })
            });
            const res = await req.json();

            if (res && res._id) {
                setDataUser(res);
                return
            }
            disconnection();
            navigate('/login');
        }

        fetchDataUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return dataUser && (
        <div className='page_settings'>
            <h2>{title[lang]}</h2>
            <Coordinates data={dataUser} />
            <Address data={dataUser.delivery_address} />
            <AddressMail email={dataUser.email} />
            <Password />
        </div>
    )
}

export default Settings;