import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { close } from "../../redux"

import "./style.css"

const title = {
    fr: `Accessoires`,
    en: `Accessories`,
    sp: `Accesorios`,
    de: `Zubehör`
}
const msg = {
    fr: `Aucun accessoire`,
    en: `No accessories`,
    sp: `Sin accesorios`,
    de: `Kein Zubehör`
}

function Accessories({ data }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const lang = useSelector(state => state.menu.lang)
    const products = useSelector(state => state.data.products);

    const [prodList, setProdList] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (data) {
            setList(data);
        }
    }, [data])

    // get products associated
    useEffect(() => {
        if (!list) {
            return
        }

        if (list.length === 0) {
            setProdList(list);
            return
        }

        const fetchImgProd = async (imgId) => {
            const req = await fetch(`https://api.pro-vide.eu/public/image/${imgId}`);
            const res = await req.json();

            if (res && res.image) {
                return res.image
            }
            return null
        }

        const fetchData = async () => {
            let newProdList = [];

            for (let i = 0; i < list.length; i++) {
                if (prodList[i] && prodList[i].name) {
                    newProdList.push(prodList[i]);
                    continue
                }
                const prodData = products.find(d => d._id === list[i]);

                let image = '';

                if (prodData && prodData.images.length !== 0) {
                    const prodImg = await fetchImgProd(prodData.images[0]);
                    // console.log(prodImg);

                    if (prodImg !== null) {
                        image = prodImg;
                    }
                }

                newProdList.push({ ...prodData, images: image })
            }

            setProdList(newProdList);
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list])

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }

    return (
        <div className='modal modal_accessories'>
            <h4>{title[lang]}</h4>

            {prodList && prodList.length !== 0 ?
                <ul className='sect_showProdAssociated'>
                    {prodList.map((prod, idx) =>
                        <li onClick={() => goPage(`products/${prod._id}`)} key={idx}>
                            <div className="container-img">
                                <img src={prod.images} alt="Illustration" />
                            </div>

                            <p>{prod.name}</p>
                        </li>
                    )}
                </ul> :
                <p>{msg[lang]}</p>
            }
        </div>
    )
}

export default Accessories;