import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Slider from "../../components/Slider";
import Banner from "../../components/Banner";
import MainMenu from "../../components/MainMenu";

import "./style.css"

function Home() {
    const categories = useSelector(state => state.data.categories);

    const [page, setPage] = useState({
        children: [],
        childrenImg: [],
        childrenType: ''
    });

    // Récupération des données
    useEffect(() => {
        if (categories.length === 0) {
            return
        }

        const fetchDataPage = async () => {
            // Récup des catégories
            const childrenPage = categories.filter(e => !e.parentId && e.type === 'prod');

            // Récup des img des catégories
            const childrenImg = await Promise.all(childrenPage.map(async (cat) => {
                if (cat.image && cat.image !== '') {
                    const req = await fetch(`https://api.pro-vide.eu/public/image/${cat.image}`);
                    const res = await req.json();
                    return res ? res : '';
                }
                else {
                    return '';
                }
            }));

            const newPageData = {
                children: childrenPage,
                childrenImg: childrenImg,
                childrenType: 'categories'
            };
            setPage(newPageData)
        }
        fetchDataPage();

    }, [categories]);

    return page && (
        <div className='page_home'>
            <div className="container_slider-banner">
                <Slider data={page.children} images={page.childrenImg} type={page.childrenType} />
                <Banner />
            </div>

            <MainMenu data={page.children} images={page.childrenImg} type={page.childrenType} />
        </div>
    )
}

export default Home;