import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { close } from "../../redux";

import "./style.css"

const button_show = {
    fr: `Voir plus`,
    en: `Show more`,
    sp: `Ver más`,
    de: `Mehr sehen`
};

function Slider({ data, images, type }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const lang = useSelector(state => state.menu.lang);
    
    const [counter, setCounter] = useState(0);
    
    // Boucle du slider
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCounter(prev => prev === images.length - 1 ? 0 : prev + 1);
        }, 10000);
        
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);
    
    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }
    
    return (
        <div className='slider'>
            <div className="container">
                
                {/* DESCRIPTION */}
                
                <div className="description">
                    <h2>
                        <p>{data[counter]?.name}</p>
                        <button className="btnShowMore_mobile">{button_show[lang]}</button>
                    </h2>

                    <p className="text">{transformText(data[counter]?.text)}</p>
                    
                    <button 
                        className="btnShowMore_desktop"
                        onClick={() => goPage(`${type === 'subcategories' ? 'categories' : type}/${data[counter]._id}`)}
                    >{button_show[lang]}</button>
                </div>

                {/* IMAGES */}
                
                <div className="container-img">
                    {images.length !== 0 && <img src={images[counter].image} alt="Illustration" />}
                </div>
            </div>

            {/* DOTS */}
            
            <div className="container-dots">
                {images.length !== 0 && images.map((e, idx) => <i key={idx} className={idx === counter ? 'dot-actif' : 'dot'}></i>)}
            </div>
        </div>
    )
}

export default Slider;