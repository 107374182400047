import { useEffect, useState } from "react";
import "./style.css"
import { useSelector } from "react-redux";

const close = {
    fr: `Fermer`,
    en: `Close`,
    sp: `Cerrar`,
    de: `Schließen`
}

function Realisation({ data, setRealSelected }) {
    const [images, setImages] = useState([]);
    const [counter, setCounter] = useState(0);

    const lang = useSelector(state => state.menu.lang);

    // Recup des images de la page
    useEffect(() => {
        if (!data || !data.images || data.images.length === 0) {
            setImages([]);
            return
        }

        const fetchImages = async () => {
            try {
                const reqImgProd = await Promise.all(data.images.map(async (img) => {
                    const req = await fetch(`https://api.pro-vide.eu/public/image/${img}`);
                    const res = await req.json();
                    return res ? res : '';
                }));

                setImages(reqImgProd);
            }
            catch (error) {
                console.error("Error fetching images:", error);
            }
        };

        fetchImages();

    }, [data])

    const previousSlide = () => {
        setCounter(counter === 0 ? parseInt(images.length) - 1 : counter - 1);
    }

    const nextSlide = () => {
        setCounter(counter === parseInt(images.length) - 1 ? 0 : counter + 1);
    }

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };

    return (
        <div className='modal_realisation'>
            <div className="content">
                <div className="container-img">
                    {images && images.length > 1 && <i onClick={() => previousSlide()} className="arrow arrow_left">{'<'}</i>}
                    {images && images.length !== 0 && <img src={images[counter].image} alt="Illustration" />}
                    {images && images.length > 1 && <i onClick={() => nextSlide()} className="arrow arrow_right">{'>'}</i>}
                </div>
                <div className="descrip">
                    <h2>{data?.name}</h2>
                    <p>{transformText(data?.text)}</p>
                    <button onClick={() => setRealSelected(null)}>{close[lang]}</button>
                </div>
            </div>
        </div>
    )
}

export default Realisation;