import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux";

import "./style.css"

const title = {
    fr: `Vérification`,
    en: `Verification`,
    sp: `Verificación`,
    de: `Verifizierung`
}
const text_ = {
    fr: `Un code de vérification a été envoyé à l'adresse`,
    en: `A verification code has been sent to the address`,
    sp: `Se ha enviado un código de verificación a la dirección`,
    de: `Ein Bestätigungscode wurde an die Adresse gesendet`
}
const _text = {
    fr: `Nous vous invitons à le renseigner ci-dessous :`,
    en: `Please enter it below:`,
    sp: `Por favor, ingréselo a continuación:`,
    de: `Bitte geben Sie ihn unten ein:`
}
const pw_not_send = {
    fr: `Vous n’avez rien reçu?`,
    en: `Didn't receive anything?`,
    sp: `¿No ha recibido nada?`,
    de: `Nichts erhalten?`
}
const button_confirmation = {
    fr: `Confirmer`,
    en: `Confirm`,
    sp: `Confirmar`,
    de: `Bestätigen`
}
const msg_conf = {
    fr: `Nouveau code envoyé`,
    en: `New code sent`,
    sp: `Nuevo código enviado`,
    de: `Neuer Code gesendet`
}

function Inscription({ data, setData }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [msg, setMsg] = useState('');
    const lang = useSelector(state => state.menu.lang);

    // Validation de l'inscription
    const validationSignup = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/signup`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        const res = await req.json();

        if (res && res._id) {
            sessionStorage.setItem('lastname', res.lastname);
            sessionStorage.setItem('firstname', res.firstname);
            sessionStorage.setItem('password', data.password);
            sessionStorage.setItem('token', res.token);
            sessionStorage.setItem('_id', res._id);
            
            dispatch(login({ _id: res._id, token: res.token, password: data.password, firstname: res.firstname, lastname: res.lastname }))
        }
        else {
            navigate('/error');
        }
    }

    // Mot de passe non-reçu
    const sendNewCode = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/resendCode`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        const res = await req.json();

        if (res) {
            setMsg(msg_conf[lang]);
            return
        }
        else {
            navigate('/error');
        }
    }

    // Clear msg de renvoie
    useEffect(() => {
        if (msg && msg !== '') {
            const timer = setTimeout(() => {
                setMsg('');
            }, 5000);
    
            return () => clearTimeout(timer);
        }
    }, [msg])


    return (
        <div className='modal_inscription'>
            <div className="content">
                <span>{title[lang]}</span>
                <p>{text_[lang]} {data.email}<br />{_text[lang]}</p>

                <input
                    type="text"
                    value={data.code}
                    placeholder="________"
                    onChange={(e) => setData(prev => ({ ...prev, code: e.target.value }))}
                />

                <button onClick={() => validationSignup()} className="btn_conf">{button_confirmation[lang]}</button>
                {msg && msg !== '' && <p className="msg">{msg}</p>}
                <button onClick={() => sendNewCode()} className="btn_notSend">{pw_not_send[lang]}</button>
            </div>
        </div>
    )
}

export default Inscription;