import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Slider from "../../components/Slider";
import Banner from "../../components/Banner";
import MainMenu from "../../components/MainMenu";

import "./style.css"

const title_not_ready = {
    fr: `Page en construction`,
    en: `Page Under Construction`,
    sp: `Página en construcción`,
    de: `Seite im Aufbau`
};

const text_not_ready = {
    fr: `Cette page est en construction, vous pouvez revenir plus tard et retourner à la page précédente en cliquant sur le lien ci-dessous. Merci pour votre compréhension`,
    en: `This page is under construction. You can come back later and return to the previous page by clicking the link below. Thank you for your understanding.`,
    sp: `Esta página está en construcción. Puede volver más tarde y regresar a la página anterior haciendo clic en el enlace a continuación. Gracias por su comprensión.`,
    de: `Diese Seite befindet sich im Aufbau. Sie können später zurückkehren und zur vorherigen Seite zurückkehren, indem Sie auf den Link unten klicken. Vielen Dank für Ihr Verständnis.`
};

const button_not_ready = {
    fr: `page précédente`,
    en: `previous page`,
    sp: `página anterior`,
    de: `vorherige Seite`
};

function CategoriesServ() {
    const { id } = useParams();
    const navigate = useNavigate();

    const categories = useSelector(state => state.data.categories);
    const services = useSelector(state => state.data.services);
    const lang = useSelector(state => state.menu.lang);

    const [page, setPage] = useState({
        title: '',
        text: '',
        children: [], 
        childrenImg: [],
        childrenType: ''
    });
    const [childrenData, setChildrenData] = useState(true);

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };

    // Récupération des données
    useEffect(() => {
        if (!categories.length || !services.length) {
            return;
        }

        const fetchDataPage = async () => {
            // Fetch data page
            const categoryPage = categories.find(e => e._id === id);
            if (!categoryPage) {
                navigate('/error');
            }

            // Fetch subcategories
            let children = categories.filter(e => e.parentId === id && e.type === 'serv');

            let childrenType = 'categoriesServ';
            
            // Fetch images of subcategories
            let childrenImg = await Promise.all(children.map(async (cat) => {
                if (cat.image && cat.image !== '') {
                    const req = await fetch(`https://api.pro-vide.eu/public/image/${cat.image}`);
                    const res = await req.json();
                    return res ? res : '';
                }
                else {
                    return '';
                }
            }));

            // Si aucune subcategories
            if (children.length === 0) {

                // Fetch services
                children = services.filter(e => e.parentId === id);

                childrenType = 'services';

                // Fetch images of services
                childrenImg = await Promise.all(children.map(async (prod) => {
                    if (prod.images && prod.images.length !== 0) {
                        const req = await fetch(`https://api.pro-vide.eu/public/image/${prod.images[0]}`);
                        const res = await req.json();
                        return res ? res : '';
                    }
                    else {
                        return '';
                    }
                }));
            }

            const newPageData = {
                title: categoryPage.name,
                text: categoryPage.text,
                children: children,
                childrenImg: childrenImg,
                childrenType: childrenType
            };
            setPage(newPageData);
        }
        fetchDataPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, id, services])

    return page && childrenData ? (
        <div className='page_category'>
            <div className="container_slider-banner">
                <Slider data={page.children} images={page.childrenImg} type={page.childrenType} />
                <Banner />
            </div>

            <section>
                <h2>{page?.title}</h2>
                <p className="subTitle">{transformText(page?.text)}</p>

                <MainMenu data={page.children} images={page.childrenImg} type={page.childrenType} />
            </section>
        </div>
    ) : (
        <div className="page_not_ready">
            <h2>{title_not_ready[lang]}</h2>
            <p>{text_not_ready[lang]}</p>
            <button onClick={() => {
                setChildrenData(true);
                window.history.back();
            }}>{button_not_ready[lang]}</button>
        </div>
    )
}

export default CategoriesServ;