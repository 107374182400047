import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import getLocation from './../../location';

import { open, close, menu, addCategoriesToState, addProductsToState, addServicesToState, addRealisationsToState, login, logout, lang } from '../../redux';

import logo_provide from './../../assets/img/logo-pro-vide.png'
import chevron_icon from './../../assets/svg/chevron_icon-nav-desk.svg'
import engine_icon from './../../assets/svg/realisation_icon-nav-desk.svg'
import phone_icon from './../../assets/svg/contact_icon-nav-desk.svg'
import about_icon from './../../assets/svg/about_icon-nav-desk.svg'
import userBtn_svg from './../../assets/svg/user_btn-mobile.svg'
import navBtn_svg from './../../assets/svg/nav_btn-mobile.svg'

import flag_fr from './../../assets/svg/flag-fr.svg'
import flag_en from './../../assets/svg/flag-en.svg'
import flag_sp from './../../assets/svg/flag-es.svg'
import flag_de from './../../assets/svg/flag-de.svg'

import "./style.css"

const subTitle = {
    fr: `Créateur de solution`,
    en: `Solution Creator`,
    sp: `Creador de soluciones`,
    de: `Lösungsersteller`
};

const menu_prod = {
    fr: `Produits`,
    en: `Products`,
    sp: `Productos`,
    de: `Produkte`
};

const menu_serv = {
    fr: `Services`,
    en: `Services`,
    sp: `Servicios`,
    de: `Dienstleistungen`
};

const button_page_real = {
    fr: `Réalisations`,
    en: `Achievements`,
    sp: `Logros`,
    de: `Erfolge`
};

const button_page_contact = {
    fr: `Contact`,
    en: `Contact`,
    sp: `Contactar`,
    de: `Kontakt`
};

const button_page_about = {
    fr: `À propos`,
    en: `About`,
    sp: `A proposito`,
    de: `Um`
};

const button_login = {
    fr: `Se connecter`,
    en: `Login`,
    sp: `Conectarse`,
    de: `Einloggen`
};

function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLogin, setIsLogin] = useState(false);
    const [updLang, setUpdLang] = useState(false);

    const user = useSelector(state => state.user);
    const state_menu = useSelector(state => state.menu);

    // Check pre-login
    useEffect(() => {
        const locStrg_id = localStorage.getItem('_id');
        const locStrgToken = localStorage.getItem('token');
        const locStrgPassword = localStorage.getItem('password');
        const locStrgLastname = localStorage.getItem('lastname');
        const locStrgFirstname = localStorage.getItem('firstname');


        if (locStrg_id && locStrgToken && locStrgPassword && locStrgLastname && locStrgFirstname) {
            sessionStorage.setItem('_id', locStrg_id);
            sessionStorage.setItem('token', locStrgToken);
            sessionStorage.setItem('password', locStrgPassword);
            sessionStorage.setItem('lastname', locStrgLastname);
            sessionStorage.setItem('firstname', locStrgFirstname);

            dispatch(login({ _id: locStrg_id, token: locStrgToken, password: locStrgPassword, firstname: locStrgFirstname, lastname: locStrgLastname }));
        }
        else {
            const sessStrg_id = sessionStorage.getItem('_id');
            const sessStrgToken = sessionStorage.getItem('token');
            const sessStrgPassword = sessionStorage.getItem('password');
            const sessStrgLastname = sessionStorage.getItem('lastname');
            const sessStrgFirstname = sessionStorage.getItem('firstname');

            if (sessStrg_id && sessStrgToken && sessStrgPassword && sessStrgLastname && sessStrgFirstname) {
                dispatch(login({ _id: sessStrg_id, token: sessStrgToken, password: sessStrgPassword, firstname: sessStrgFirstname, lastname: sessStrgLastname }));
            }
            else {
                dispatch(logout());
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Get language
    useEffect(() => {
        const fetchLocation = async () => {
            const get_lang = await getLocation();
            dispatch(lang(get_lang));
        }

        fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Vérification de la connection
    useEffect(() => {
        if (user.token !== '') {
            setIsLogin(true);
            return
        }
        setIsLogin(false);
    }, [user]);

    // Vérification largeur d'écran
    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;

            // Si format mobile => desktopMenu désactivé
            if (windowWidth < 1050) {
                dispatch(open('mobile'));
            }
        };

        return () => window.addEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Récupération des catégories, produits et services
    useEffect(() => {
        const fetchCategories = async () => {
            const reqCategories = await fetch(`https://api.pro-vide.eu/public/categories/${state_menu.lang}`);
            const resCategories = await reqCategories.json();

            const catList = resCategories.sort((a, b) => a.order - b.order);
            dispatch(addCategoriesToState(catList));

            const reqProducts = await fetch(`https://api.pro-vide.eu/public/products/${state_menu.lang}`);
            const resProducts = await reqProducts.json();

            const prodList = resProducts.sort((a, b) => a.order - b.order);
            dispatch(addProductsToState(prodList));

            const reqServices = await fetch(`https://api.pro-vide.eu/public/services/${state_menu.lang}`);
            const resServices = await reqServices.json();

            const servList = resServices.sort((a, b) => a.order - b.order);
            dispatch(addServicesToState(servList));

            const reqRealisations = await fetch(`https://api.pro-vide.eu/public/realization/${state_menu.lang}`);
            const resRealisations = await reqRealisations.json();

            const realList = resRealisations.sort((a, b) => a.order - b.order);
            dispatch(addRealisationsToState(realList));
        }
        fetchCategories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_menu]);

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }


    return state_menu && (
        <div className="header">
            <header>
                {/* Button home */}
                <button
                    className='btn_home'
                    onClick={() => goPage('')}
                >
                    <img src={logo_provide} alt="Logo" />
                    <h1>Pro-vide<span>{subTitle[state_menu.lang]}</span></h1>
                </button>

                {/* Nav */}
                <div className="desktop-content nav-desktop">
                    <button onClick={() => dispatch(menu(1))} className={`btnNav_header-desktop ${state_menu.desktopMenu && state_menu.desktopTypeMenu === 1 && 'actif'}`}>{menu_prod[state_menu.lang]}<img src={chevron_icon} alt="icon" /></button>
                    <button onClick={() => dispatch(menu(2))} className={`btnNav_header-desktop ${state_menu.desktopMenu && state_menu.desktopTypeMenu === 2 && 'actif'}`}>{menu_serv[state_menu.lang]}<img src={chevron_icon} alt="icon" /></button>
                    <button onClick={() => goPage('realisations')} className='btnNav_header-desktop'>{button_page_real[state_menu.lang]}<img src={engine_icon} alt="icon" /></button>
                    <button onClick={() => goPage('contact')} className='btnNav_header-desktop'>{button_page_contact[state_menu.lang]}<img src={phone_icon} alt="icon" /></button>
                    <button onClick={() => goPage('about')} className='btnNav_header-desktop'>{button_page_about[state_menu.lang]}<img src={about_icon} alt="icon" /></button>
                </div>


                {/* Button login/menu PC */}
                <div className="desktop-content lang_user_container">
                    {!updLang ?
                        <button onClick={() => setUpdLang(true)} className="lang_selected">
                            {state_menu.lang === 'fr' && <img src={flag_fr} alt="Français" />}
                            {state_menu.lang === 'en' && <img src={flag_en} alt="English" />}
                            {state_menu.lang === 'sp' && <img src={flag_sp} alt="Español" />}
                            {state_menu.lang === 'de' && <img src={flag_de} alt="Deutsch" />}
                            <img className='chevron_lang' src={chevron_icon} alt="Icon" />
                        </button>
                        :
                        <div className='lang_selector'>
                            <button onClick={() => { dispatch(lang('fr')); setUpdLang(false) }}><img src={flag_fr} alt="Français" /></button>
                            <button onClick={() => { dispatch(lang('en')); setUpdLang(false) }}><img src={flag_en} alt="English" /></button>
                            <button onClick={() => { dispatch(lang('sp')); setUpdLang(false) }}><img src={flag_sp} alt="Español" /></button>
                            <button onClick={() => { dispatch(lang('de')); setUpdLang(false) }}><img src={flag_de} alt="Deutsch" /></button>
                        </div>
                    }

                    {!isLogin && state_menu ?
                        <button onClick={() => goPage('login')} className='btn-login'>{button_login[state_menu.lang]}</button> :
                        <button
                            onClick={() => dispatch(open('userMenu'))}
                            className={`btn-userMenu btnNav_header-desktop ${state_menu.userMenu ? 'actif' : ''}`}
                        >
                            {user.lastname + '.' + user.firstname[0]}
                            <img src={chevron_icon} alt="icon" />
                        </button>
                    }
                </div>

                {/* Button login/menu MOBILE */}
                <div className="mobile-content">
                    {!updLang ?
                        <button onClick={() => setUpdLang(true)} className="lang_selected">
                            {state_menu.lang === 'fr' && <img src={flag_fr} alt="Français" />}
                            {state_menu.lang === 'en' && <img src={flag_en} alt="English" />}
                            {state_menu.lang === 'sp' && <img src={flag_sp} alt="Español" />}
                            {state_menu.lang === 'de' && <img src={flag_de} alt="Deutsch" />}
                            <img className='chevron_lang' src={chevron_icon} alt="Icon" />
                        </button>
                        :
                        <div className='lang_selector'>
                            <button onClick={() => { dispatch(lang('fr')); setUpdLang(false) }}><img src={flag_fr} alt="Français" /></button>
                            <button onClick={() => { dispatch(lang('en')); setUpdLang(false) }}><img src={flag_en} alt="English" /></button>
                            <button onClick={() => { dispatch(lang('sp')); setUpdLang(false) }}><img src={flag_sp} alt="Español" /></button>
                            <button onClick={() => { dispatch(lang('de')); setUpdLang(false) }}><img src={flag_de} alt="Deutsch" /></button>
                        </div>
                    }

                    {!isLogin && state_menu && <button onClick={() => goPage('login')} className='btn-login'><img src={userBtn_svg} alt="Login" /></button>}
                    <button
                        onClick={() => dispatch(open('mobileMenu'))}
                        className={`btn-nav ${state_menu.mobileMenu && 'actif'}`}
                    >
                        <img src={navBtn_svg} alt="Menu" />
                    </button>
                </div>

            </header>
        </div>
    )
}

export default Header;