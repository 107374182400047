import "./style.css";

function Input({ label, placeholder, value, setValue, labForm }) {
    const handleChange = (e) => {
        const inputValue = e.target.value;
        const key = labForm.split('_isEdit')[0];
        setValue(prev => ({ ...prev, [key]: inputValue }));
    };

    const isPassword = labForm === 'password' || labForm === 'passw' || labForm === '_passw';

    return (
        <div className='input_model'>
            <label>{label}</label>
            <input
                type={isPassword ? 'password' : 'text'}
                value={value}
                className={`input_${labForm}`}
                placeholder={placeholder}
                onChange={handleChange}
            />
        </div>
    );
}

export default Input;