import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

//Layouts
import Header from './layouts/Header'
import Footer from './layouts/Footer'

//Menu
import Nav from './layouts/Nav'
import NavMenu from './layouts/NavMenu'
import Cookies from './layouts/Cookies'

//Général
import Home from './pages/Home'
import Error from './pages/Error'

//Connection
import Login from './pages/Login'

//Produits & Services
import Categories from './pages/Categories'
import Products from './pages/Products'

import CategoriesServ from './pages/CategoriesServ'
import Services from './pages/Services'

// Autres
import Realisations from './pages/Realisations'
import Contact from './pages/Contact'
import About from './pages/About'

import Settings from './pages/Settings'
import Basket from './pages/Basket'
import Historical from './pages/Historical'

// Condition d'utilisation et Politique de confidentialité
import CGU from './pages/CGU'
import PC from './pages/PC'

import './App.css'

function App() {
    const localId = localStorage.getItem('_id');
    const sessionId = sessionStorage.getItem('_id');


    return (
        <React.StrictMode>
            <Router>
                {(!localId && !sessionId) && <Cookies />}
                <Header />
                <Nav />
                <NavMenu />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Error />} />

                    <Route path="/login" element={<Login />} />

                    <Route path="/categories/:id" element={<Categories />} />
                    <Route path="/products/:id" element={<Products />} />
                    
                    <Route path="/categoriesServ/:id" element={<CategoriesServ />} />
                    <Route path="/services/:id" element={<Services />} />

                    <Route path="/realisations" element={<Realisations />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />

                    <Route path="/settings" element={<Settings />} />
                    <Route path="/basket" element={<Basket />} />
                    <Route path="/historical" element={<Historical />} />
                    
                    <Route path="/CGU" element={<CGU />} />
                    <Route path="/PC" element={<PC />} />
                </Routes>

                <Footer />
            </Router>
        </React.StrictMode>
    )
}

export default App;