import { useSelector } from "react-redux";
import "./style.css"

function PC() {
    const lang = useSelector(state => state.menu.lang)

    const title = {
        fr: `Politique de Confidentialité`,
        en: `Privacy Policy`,
        sp: `Política de Privacidad`,
        de: `Datenschutzrichtlinie`
    };    

    const data_list = [
        {
            title: {
                fr: `Collecte des Informations`,
                en: `Information Collection`,
                sp: `Recopilación de Información`,
                de: `Datensammlung`
            },
            text: {
                fr: `Nous recueillons des informations lorsque vous vous inscrivez sur notre site. Les informations recueillies incluent votre nom, prénom, nom de votre société, service, numéro de SIRET/SIREN, adresse e-mail, numéro de téléphone fixe et mobile, adresse postale, ville, pays et code postal.
    
    En outre, nous enregistrons les demandes et devis que vous effectuez sur notre site.`,
                en: `We collect information when you register on our site. The information collected includes your name, first name, company name, department, SIRET/SIREN number, email address, landline and mobile phone number, postal address, city, country and postal code.
    
    In addition, we record the requests and quotes that you make on our site.`,
                sp: `Recopilamos información cuando se registra en nuestro sitio. La información recopilada incluye su nombre, apellido, nombre de su empresa, servicio, número de SIRET/SIREN, dirección de correo electrónico, número de teléfono fijo y móvil, dirección postal, ciudad, país y código postal.
    
    Además, registramos las solicitudes y presupuestos que realiza en nuestro sitio.`,
                de: `Wir sammeln Informationen, wenn Sie sich auf unserer Website registrieren. Die gesammelten Informationen umfassen Ihren Namen, Vornamen, Firmennamen, Abteilung, SIRET/SIREN-Nummer, E-Mail-Adresse, Festnetz- und Mobiltelefonnummer, Postanschrift, Stadt, Land und Postleitzahl.
    
    Darüber hinaus zeichnen wir die Anfragen und Angebote auf, die Sie auf unserer Website tätigen.`
            }
        },
        {
            title: {
                fr: `Utilisation des Informations`,
                en: `Use of Information`,
                sp: `Uso de la Información`,
                de: `Verwendung von Informationen`
            },
            text: {
                fr: `Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :`,
                en: `All information we collect from you may be used for:`,
                sp: `Toda la información que recopilamos de usted puede utilizarse para:`,
                de: `Alle Informationen, die wir von Ihnen sammeln, können verwendet werden für:`
            },
            list: [
                {
                    fr: `Personnaliser votre expérience et répondre à vos besoins individuels`,
                    en: `Personalize your experience and respond to your individual needs`,
                    sp: `Personalizar su experiencia y responder a sus necesidades individuales`,
                    de: `Personalisieren Sie Ihre Erfahrung und reagieren Sie auf Ihre individuellen Bedürfnisse`
                },
                {
                    fr: `Améliorer notre site Web`,
                    en: `Improve our website`,
                    sp: `Mejorar nuestro sitio web`,
                    de: `Verbessern Sie unsere Website`
                },
                {
                    fr: `Améliorer le service client et vos besoins de prise en charge`,
                    en: `Improve customer service and your support needs`,
                    sp: `Mejorar el servicio al cliente y sus necesidades de soporte`,
                    de: `Verbessern Sie den Kundenservice und Ihre Support-Anforderungen`
                },
                {
                    fr: `Vous contacter par e-mail ou téléphone`,
                    en: `Contact you by email or phone`,
                    sp: `Comunicarse con usted por correo electrónico o teléfono`,
                    de: `Kontaktieren Sie uns per E-Mail oder Telefon`
                },
                {
                    fr: `Traiter vos demandes et devis`,
                    en: `Process your requests and quotes`,
                    sp: `Procesar sus solicitudes y presupuestos`,
                    de: `Bearbeiten Sie Ihre Anfragen und Angebote`
                }
            ]
        },
        {
            title: {
                fr: `Confidentialité des Informations`,
                en: `Information Confidentiality`,
                sp: `Confidencialidad de la Información`,
                de: `Vertraulichkeit von Informationen`
            },
            text: {
                fr: `Nous sommes les seuls propriétaires des informations recueillies sur ce site. Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande et / ou une transaction, comme par exemple pour traiter vos demandes et devis.`,
                en: `We are the sole owners of the information collected on this site. Your personal information will not be sold, exchanged, transferred, or given to any other company without your consent, except as necessary to respond to a request and/or transaction, such as processing your requests and quotes.`,
                sp: `Somos los únicos propietarios de la información recopilada en este sitio. Su información personal no se venderá, intercambiará, transferirá ni cederá a ninguna otra empresa sin su consentimiento, salvo lo necesario para responder a una solicitud y/o transacción, como por ejemplo, para procesar sus solicitudes y presupuestos.`,
                de: `Wir sind die alleinigen Eigentümer der auf dieser Website gesammelten Informationen. Ihre persönlichen Daten werden nicht verkauft, ausgetauscht, übertragen oder an ein anderes Unternehmen weitergegeben, es sei denn, dies ist erforderlich, um auf eine Anfrage und/oder eine Transaktion zu reagieren, z. B. um Ihre Anfragen und Angebote zu bearbeiten.`
            }
        },
        {
            title: {
                fr: `Protection des Informations`,
                en: `Information Protection`,
                sp: `Protección de la Información`,
                de: `Datenschutz`
            },
            text: {
                fr: `Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, le service à la clientèle) ont accès aux informations personnelles identifiables.`,
                en: `We implement a variety of security measures to maintain the safety of your personal information. Only employees who need to perform a specific job (for example, customer service) have access to personally identifiable information.`,
                sp: `Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información personal. Solo los empleados que necesitan realizar un trabajo específico (por ejemplo, el servicio al cliente) tienen acceso a la información personal identificable.`,
                de: `Wir setzen eine Vielzahl von Sicherheitsmaßnahmen ein, um die Sicherheit Ihrer persönlichen Daten zu gewährleisten. Nur Mitarbeiter, die eine bestimmte Aufgabe ausführen müssen (z. B. der Kundenservice), haben Zugriff auf personenbezogene Daten.`
            }
        },
        {
            title: {
                fr: `Utilisation des Cookies`,
                en: `Cookie Usage`,
                sp: `Uso de Cookies`,
                de: `Cookie-Nutzung`
            },
            text: {
                fr: `Nous utilisons des cookies pour améliorer votre expérience sur notre site. Les cookies sont de petits fichiers texte stockés sur votre appareil lorsque vous visitez notre site.
    
    Nous utilisons des cookies de session pour la fonctionnalité "Se souvenir de moi" lors de la connexion. Ces cookies permettent de mémoriser votre identifiant et votre mot de passe pour vous éviter de les saisir à chaque fois que vous visitez notre site. Ces cookies sont temporaires et sont supprimés dès que vous fermez votre navigateur.
    
    Vous pouvez contrôler et gérer les cookies à l'aide des paramètres de votre navigateur. Vous pouvez choisir d'accepter ou de refuser tous les cookies, ou de les accepter au cas par cas. Cependant, le blocage des cookies de session peut empêcher la fonctionnalité "Se souvenir de moi" de fonctionner correctement.`,
                en: `We use cookies to improve your experience on our site. Cookies are small text files stored on your device when you visit our site.
    
    We use session cookies for the "Remember me" functionality when logging in. These cookies allow us to remember your username and password so you don't have to enter them every time you visit our site. These cookies are temporary and are deleted as soon as you close your browser.
    
    You can control and manage cookies using your browser settings. You can choose to accept or reject all cookies, or to accept them on a case-by-case basis. However, blocking session cookies may prevent the "Remember me" functionality from working properly.`,
                sp: `Utilizamos cookies para mejorar su experiencia en nuestro sitio. Las cookies son pequeños archivos de texto almacenados en su dispositivo cuando visita nuestro sitio.
    
    Utilizamos cookies de sesión para la funcionalidad "Recuérdame" al iniciar sesión. Estas cookies nos permiten recordar su nombre de usuario y contraseña para que no tenga que ingresarlos cada vez que visite nuestro sitio. Estas cookies son temporales y se eliminan tan pronto como cierra su navegador.
    
    Puede controlar y administrar las cookies mediante la configuración de su navegador. Puede elegir aceptar o rechazar todas las cookies, o aceptarlas caso por caso. Sin embargo, bloquear las cookies de sesión puede impedir que la funcionalidad "Recuérdame" funcione correctamente.`,
                de: `Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern. Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden, wenn Sie unsere Website besuchen.
    
    Wir verwenden Sitzungscookies für die Funktion "Angemeldet bleiben" bei der Anmeldung. Diese Cookies ermöglichen es uns, Ihren Benutzernamen und Ihr Passwort zu speichern, damit Sie sie nicht jedes Mal eingeben müssen, wenn Sie unsere Website besuchen. Diese Cookies sind temporär und werden gelöscht, sobald Sie Ihren Browser schließen.
    
    Sie können Cookies über die Einstellungen Ihres Browsers steuern und verwalten. Sie können wählen, ob Sie alle Cookies akzeptieren oder ablehnen möchten oder ob Sie sie fallweise akzeptieren möchten. Wenn Sie jedoch Sitzungscookies blockieren, kann die Funktion "Angemeldet bleiben" möglicherweise nicht ordnungsgemäß funktionieren.`
            }
        },
        {
            title: {
                fr: `Accès à vos Informations`,
                en: `Access to Your Information`,
                sp: `Acceso a su Información`,
                de: `Zugriff auf Ihre Informationen`
            },
            text: {
                fr: `Vous pouvez accéder à vos informations personnelles et les modifier à tout moment en vous connectant à votre compte et en accédant à la page des paramètres de compte.`,
                en: `You can access and modify your personal information at any time by logging into your account and accessing the account settings page.`,
                sp: `Puede acceder y modificar su información personal en cualquier momento iniciando sesión en su cuenta y accediendo a la página de configuración de la cuenta.`,
                de: `Sie können jederzeit auf Ihre persönlichen Daten zugreifen und diese ändern, indem Sie sich in Ihr Konto einloggen und auf die Kontoeinstellungsseite zugreifen.`
            }
        },
        {
            title: {
                fr: `Conservation des Informations`,
                en: `Information Retention`,
                sp: `Conservación de la Información`,
                de: `Informationsspeicherung`
            },
            text: {
                fr: `Nous conservons vos informations personnelles aussi longtemps que votre compte est actif ou que cela est nécessaire pour vous fournir des services. Nous conservons également vos demandes et devis dans l'historique de votre compte.`,
                en: `We retain your personal information as long as your account is active or as needed to provide you services. We also retain your requests and quotes in your account history.`,
                sp: `Conservamos su información personal mientras su cuenta esté activa o sea necesaria para brindarle servicios. También conservamos sus solicitudes y presupuestos en el historial de su cuenta.`,
                de: `Wir speichern Ihre persönlichen Daten so lange, wie Ihr Konto aktiv ist oder wie es erforderlich ist, um Ihnen Dienstleistungen anzubieten. Wir speichern auch Ihre Anfragen und Angebote in Ihrem Kontoverlauf.`
            }
        },
        {
            title: {
                fr: `Consentement`,
                en: `Consent`,
                sp: `Consentimiento`,
                de: `Zustimmung`
            },
            text: {
                fr: `En utilisant notre site, vous consentez à notre politique de confidentialité.`,
                en: `By using our site, you consent to our privacy policy.`,
                sp: `Al usar nuestro sitio, acepta nuestra política de privacidad.`,
                de: `Durch die Nutzung unserer Website stimmen Sie unserer Datenschutzrichtlinie zu.`
            }
        },
    ];    


    return (
        <div className='page_PC'>
            <h2>{title[lang]}</h2>

            <ul>
                {data_list.map((data, idx) =>
                    <li key={idx}>
                        <h3>{idx + 1}. {data.title[lang]}</h3>
                        <p>{data.text[lang]}</p>

                        {data.list && data.list.map((li, id) =>
                            <span key={id}>- {li[lang]}</span>
                        )}
                    </li>
                )}
            </ul>
        </div>
    )
}

export default PC;