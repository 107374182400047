import { useSelector } from "react-redux";
import "./style.css"

function CGU() {
    const lang = useSelector(state => state.menu.lang)

    const title = {
        fr: `Conditions Générales d'Utilisation`,
        en: `Terms and Conditions of Use`,
        sp: `Términos y Condiciones de Uso`,
        de: `Allgemeine Nutzungsbedingungen`
    }

    const data_list = [
        {
            title: {
                fr: `Acceptation des Conditions Générales d'Utilisation`,
                en: `Acceptance of Terms and Conditions`,
                sp: `Aceptación de los Términos y Condiciones`,
                de: `Akzeptanz der Allgemeinen Geschäftsbedingungen`
            },
            text: {
                fr: `En accédant et en utilisant ce site web, vous acceptez d'être lié par les présentes Conditions Générales d'Utilisation.`,
                en: `By accessing and using this website, you agree to be bound by these Terms and Conditions.`,
                sp: `Al acceder y utilizar este sitio web, acepta estar sujeto a estos Términos y Condiciones.`,
                de: `Durch den Zugriff auf diese Website und deren Nutzung stimmen Sie diesen Allgemeinen Geschäftsbedingungen zu.`
            }
        },
        {
            title: {
                fr: `Inscription et Compte Utilisateur`,
                en: `Registration and User Account`,
                sp: `Registro y Cuenta de Usuario`,
                de: `Registrierung und Benutzerkonto`
            },
            text: {
                fr: `Pour accéder à certaines fonctionnalités de ce site, vous devez créer un compte. En créant un compte, vous acceptez de fournir des informations exactes, à jour et complètes. Vous êtes responsable de la confidentialité de votre mot de passe et de toute activité sur votre compte.`,
                en: `To access certain features of this site, you must create an account. By creating an account, you agree to provide accurate, up-to-date, and complete information. You are responsible for the confidentiality of your password and all activities on your account.`,
                sp: `Para acceder a ciertas funciones de este sitio, debe crear una cuenta. Al crear una cuenta, acepta proporcionar información precisa, actualizada y completa. Usted es responsable de la confidencialidad de su contraseña y de todas las actividades en su cuenta.`,
                de: `Um auf bestimmte Funktionen dieser Website zugreifen zu können, müssen Sie ein Konto erstellen. Durch die Erstellung eines Kontos stimmen Sie zu, genaue, aktuelle und vollständige Informationen bereitzustellen. Sie sind für die Vertraulichkeit Ihres Passworts und alle Aktivitäten auf Ihrem Konto verantwortlich.`
            }
        },
        {
            title: {
                fr: `Utilisation des Cookies`,
                en: `Use of Cookies`,
                sp: `Uso de Cookies`,
                de: `Verwendung von Cookies`
            },
            text: {
                fr: `Ce site utilise des cookies pour améliorer votre expérience utilisateur. En utilisant ce site, vous acceptez notre utilisation des cookies conformément à notre Politique de Confidentialité.`,
                en: `This site uses cookies to improve your user experience. By using this site, you agree to our use of cookies in accordance with our Privacy Policy.`,
                sp: `Este sitio utiliza cookies para mejorar su experiencia de usuario. Al utilizar este sitio, acepta nuestro uso de cookies de acuerdo con nuestra Política de Cookies.`,
                de: `Diese Website verwendet Cookies, um Ihre Benutzererfahrung zu verbessern. Durch die Nutzung dieser Website stimmen Sie unserer Verwendung von Cookies gemäß unserer Datenschutzrichtlinie zu.`
            }
        },
        {
            title: {
                fr: `Demandes de Produits et Services`,
                en: `Product and Service Requests`,
                sp: `Solicitudes de Productos y Servicios`,
                de: `Produkt- und Dienstleistungsanfragen`
            },
            text: {
                fr: `Les demandes de produits et services effectuées via ce site sont soumises à notre acceptation. Nous nous réservons le droit de refuser toute demande pour quelque raison que ce soit.`,
                en: `Requests for products and services made through this site are subject to our acceptance. We reserve the right to refuse any request for any reason.`,
                sp: `Las solicitudes de productos y servicios realizadas a través de este sitio están sujetas a nuestra aceptación. Nos reservamos el derecho de rechazar cualquier solicitud por cualquier motivo.`,
                de: `Anfragen für Produkte und Dienstleistungen, die über diese Website gestellt werden, unterliegen unserer Annahme. Wir behalten uns das Recht vor, jede Anfrage aus beliebigem Grund abzulehnen.`
            }
        },
        {
            title: {
                fr: `Panier et Devis`,
                en: `Cart and Quotes`,
                sp: `Carrito y Cotizaciones`,
                de: `Warenkorb und Angebote`
            },
            text: {
                fr: `Les produits ajoutés au panier ne sont pas réservés et peuvent devenir indisponibles. Les devis générés à partir du panier sont valables pour une durée limitée.`,
                en: `Products added to the cart are not reserved and may become unavailable. Quotes generated from the cart are valid for a limited time.`,
                sp: `Los productos añadidos al carrito no están reservados y pueden volverse indisponibles. Las cotizaciones generadas a partir del carrito son válidas por un tiempo limitado.`,
                de: `Produkte, die dem Warenkorb hinzugefügt wurden, sind nicht reserviert und können nicht verfügbar werden. Angebote, die aus dem Warenkorb erstellt werden, sind nur für eine begrenzte Zeit gültig.`
            }
        },
        {
            title: {
                fr: `Historique des Demandes et Devis`,
                en: `Request and Quote History`,
                sp: `Historial de Solicitudes y Cotizaciones`,
                de: `Anfragen- und Angebotshistorie`
            },
            text: {
                fr: `Nous conservons un historique de vos demandes et devis. Vous pouvez y accéder à tout moment dans la section « Historique ».`,
                en: `We keep a history of your requests and quotes. You can access it at any time in the "History" section.`,
                sp: `Mantenemos un historial de sus solicitudes y cotizaciones. Puede acceder a él en cualquier momento en la sección "Historial".`,
                de: `Wir führen eine Historie Ihrer Anfragen und Angebote. Sie können jederzeit im Abschnitt "Historie" darauf zugreifen.`
            }
        },
        {
            title: {
                fr: `Contact`,
                en: `Contact`,
                sp: `Contacto`,
                de: `Kontakt`
            },
            text: {
                fr: `Pour toute question ou préoccupation concernant ces Conditions Générales d'Utilisation, veuillez nous contacter via la page « Contact ».`,
                en: `For any questions or concerns regarding these Terms and Conditions, please contact us via the "Contact" page.`,
                sp: `Para cualquier pregunta o preocupación sobre estos Términos y Condiciones, por favor contáctenos a través de la página "Contacto".`,
                de: `Bei Fragen oder Bedenken zu diesen Allgemeinen Geschäftsbedingungen kontaktieren Sie uns bitte über die Seite "Kontakt".`
            }
        },
        {
            title: {
                fr: `Modification des Conditions Générales d'Utilisation`,
                en: `Modification of Terms and Conditions`,
                sp: `Modificación de los Términos y Condiciones`,
                de: `Änderung der Allgemeinen Geschäftsbedingungen`
            },
            text: {
                fr: `Nous nous réservons le droit de modifier ces Conditions Générales d'Utilisation à tout moment. Les modifications entreront en vigueur dès leur publication sur ce site.`,
                en: `We reserve the right to modify these Terms and Conditions at any time. Changes will take effect as soon as they are posted on this site.`,
                sp: `Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Los cambios entrarán en vigor tan pronto como se publiquen en este sitio.`,
                de: `Wir behalten uns das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern. Änderungen treten in Kraft, sobald sie auf dieser Website veröffentlicht werden.`
            }
        }
    ];


    return (
        <div className='page_CGU'>
            <h2>{title[lang]}</h2>

            <ul>
                {data_list.map((data, idx) =>
                    <li key={idx}>
                        <h3>{idx + 1}. {data.title[lang]}</h3>
                        <p>{data.text[lang]}</p>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default CGU;