import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { close, logout } from "../../redux";

import icon_download from './../../assets/svg/download-icon.svg';

import "./style.css"

const title = {
    fr: `Historique des devis`,
    en: `Quote History`,
    sp: `Historial de Cotizaciones`,
    de: `Angebotsverlauf`
}
const label_filter = {
    fr: `Année :`,
    en: `Year:`,
    sp: `Año:`,
    de: `Jahr:`
}
const option_all = {
    fr: `Tous`,
    en: `All`,
    sp: `Todos`,
    de: `Alle`
}
const option_req = {
    fr: `Demandes`,
    en: `Requests`,
    sp: `Solicitudes`,
    de: `Anfragen`
}
const option_ref = {
    fr: `Références`,
    en: `References`,
    sp: `Referencias`,
    de: `Referenzen`
}
const option_serv = {
    fr: `Services`,
    en: `Services`,
    sp: `Servicios`,
    de: `Dienstleistungen`
}
const label_date = {
    fr: `Date`,
    en: `Date`,
    sp: `Fecha`,
    de: `Datum`
}
const label_type = {
    fr: `Type`,
    en: `Type`,
    sp: `Tipo`,
    de: `Typ`
}
const label_content = {
    fr: `Produits/Services`,
    en: `Products/Services`,
    sp: `Productos/Servicios`,
    de: `Produkte/Dienstleistungen`
}
const label_devis = {
    fr: `Devis/Demande`,
    en: `Quote/Request`,
    sp: `Cotización/Solicitud`,
    de: `Angebot/Anfrage`
}
const buton_download = {
    fr: `Télécharger le devis`,
    en: `Download Quote`,
    sp: `Descargar Cotización`,
    de: `Angebot herunterladen`
}


function Historical() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [history, setHistory] = useState([]);

    const [years, setYears] = useState([]);
    const [filters, setFilters] = useState({
        years: '',
        type: 'all',
        category: 'all'
    });

    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    // Logout
    const disconnection = () => {
        sessionStorage.removeItem('_id');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('password');
        sessionStorage.removeItem('lastname');
        sessionStorage.removeItem('firstname');

        localStorage.removeItem('_id');
        localStorage.removeItem('token');
        localStorage.removeItem('password');
        localStorage.removeItem('lastname');
        localStorage.removeItem('firstname');

        dispatch(logout());
        dispatch(close());
    }

    // Recuperation de l'historique de l'utilisateur
    useEffect(() => {
        if (!user || !user.token) {
            return
        }

        const fetchUser = async () => {
            const req = await fetch(`https://api.pro-vide.eu/user/get_account`, {
                method: 'PUT',
                headers: { 'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: user._id, password: user.password })
            });
            const res = await req.json();

            if (res && res.history) {
                console.log(res.history);
                setHistory(res.history);
                setList(res.history);
                return
            }
            disconnection();
            navigate('/login');
        }
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Config years filter
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setFilters({ ...filters, years: currentYear.toString() });

        let array = [];

        for (let year = currentYear; year >= 2015; year--) {
            array.push(year);
        }
        setYears(array);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    }

    function formatYears(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    }

    useEffect(() => {
        if (!filters || !history || history.length === 0) {
            return
        }

        let lastData = [...history];

        const newData = lastData.filter(e =>
            formatYears(e.date) === filters.years &&
            (e.type === filters.type || filters.type === 'all')
        )

        setList(newData);

    }, [filters, history])

    return (
        <div className='page_historical'>
            <h2>{title[lang]}</h2>

            <div className="filters">
                <div className="filter">
                    <label>{label_filter[lang]}</label>

                    <select
                        value={filters.years}
                        onChange={(e) => setFilters({ ...filters, years: e.target.value })}
                    >
                        {years.map(year =>
                            <option key={year} value={year}>{year}</option>
                        )}
                    </select>
                </div>

                <div className="filter">
                    <label>Type :</label>
                    <select value={filters.type} onChange={(e) => setFilters({ ...filters, type: e.target.value })}>
                        <option value="all">{option_all[lang]}</option>
                        <option value="dem">{option_req[lang]}</option>
                        <option value="ref">{option_ref[lang]}</option>
                        <option value="serv">{option_serv[lang]}</option>
                    </select>
                </div>
            </div>

            <ul className="ul_history">
                <li className="first_li">
                    <p>{label_date[lang]}</p>
                    <p>{label_type[lang]}</p>
                    <p>{label_content[lang]}</p>
                    <p className="last">{label_devis[lang]}</p>
                </li>

                {list && list.length !== 0 && list.map((li, idx) =>
                    <li key={idx}>
                        <p className="date">{formatDate(li.date)}</p>

                        <p className="type">
                            {li.type === 'ref' && 'Références'}
                            {li.type === 'dem' && 'Demande'}
                            {li.type === 'serv' && 'Service'}
                        </p>

                        <p className="category">
                            {li?.cart.slice(0, 2).map(prod => prod.product_code).join(', ')}
                            {li?.cart.length > 2 && `, ${li.cart.length - 2} autres éléments`}
                        </p>

                        <a href={li.devis} target="blank"><img src={icon_download} alt="Icon" />{buton_download[lang]}</a>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Historical;