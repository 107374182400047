import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import { close, logout } from '../../redux';

import RequestQuote from '../../modals/RequestQuote';

import cart_icon from './../../assets/svg/cart-icon_page_cart.svg'
import phone_icon from './../../assets/svg/phone-icon_page_cart.svg'
import trash_icon from './../../assets/svg/btn-delProdToCart.svg'

import "./style.css"

const title = {
    fr: `Consultation du panier`,
    en: `Cart Review`,
    sp: `Revisión del carrito`,
    de: `Warenkorb Überprüfung`
}
const total_HT_txt = {
    fr: `Total HT :`,
    en: `Total (excl. VAT):`,
    sp: `Total (sin IVA):`,
    de: `Gesamt (ohne MwSt.):`
}
const tva_txt = {
    fr: `TVA 20% :`,
    en: `VAT 20%:`,
    sp: `IVA 20%:`,
    de: `MwSt. 20%:`
}
const total_TTC_txt = {
    fr: `Total TTC :`,
    en: `Total (incl. VAT):`,
    sp: `Total (con IVA):`,
    de: `Gesamt (inkl. MwSt.):`
}
const msg_void = {
    fr: `Aucun produits`,
    en: `No products`,
    sp: `Sin productos`,
    de: `Keine Produkte`
}
const button_request = {
    fr: `Valider le devis`,
    en: `Confirm the quote`,
    sp: `Confirmar el presupuesto`,
    de: `Angebot bestätigen`
}
const msg_held = {
    fr: `Si vous avez des questions relatives aux produits, n’hésitez pas à nous contacter au 02 35 14 96 58.`,
    en: `If you have any questions about the products, please contact us at 02 35 14 96 58.`,
    sp: `Si tiene alguna pregunta sobre los productos, contáctenos al 02 35 14 96 58.`,
    de: `Wenn Sie Fragen zu den Produkten haben, kontaktieren Sie uns bitte unter 02 35 14 96 58.`
}
const label_price = {
    fr: `Prix unité (HT) :`,
    en: `Unit price (excl. VAT):`,
    sp: `Precio unitario (sin IVA):`,
    de: `Einzelpreis (ohne MwSt.):`
}
const label_total = {
    fr: `Total :`,
    en: `Total:`,
    sp: `Total:`,
    de: `Gesamt:`
}
const label_qte = {
    fr: `Qte :`,
    en: `Qty:`,
    sp: `Cant:`,
    de: `Menge:`
}

function Basket() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [cart, setCart] = useState([]);
    const [userData, setUser] = useState(null);
    const [cartImg, setCartImg] = useState([]);

    const [requestQuote, setRequestQuote] = useState(false);

    // Logout
    const disconnection = () => {
        sessionStorage.removeItem('_id');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('password');
        sessionStorage.removeItem('lastname');
        sessionStorage.removeItem('firstname');

        localStorage.removeItem('_id');
        localStorage.removeItem('token');
        localStorage.removeItem('password');
        localStorage.removeItem('lastname');
        localStorage.removeItem('firstname');

        dispatch(logout());
        dispatch(close());
    }

    // Recup du panier de l'utilisateur
    useEffect(() => {
        if (!user || !user.token) {
            return
        }

        const fetchDataUser = async () => {
            const req = await fetch(`https://api.pro-vide.eu/user/get_account`, {
                method: 'PUT',
                headers: { 'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: user._id, password: user.password })
            });
            const res = await req.json();

            if (res && res.cart) {
                // Récup des img des catégories
                const productsImg = await Promise.all(res.cart.map(async (prod) => {
                    if (prod.img && prod.img !== '') {
                        const req = await fetch(`https://api.pro-vide.eu/public/image/${prod.img}`);
                        const res = await req.json();
                        return res ? res : '';
                    }
                    else {
                        return '';
                    }
                }));

                setCartImg(productsImg);
                setCart(res.cart);
                setUser(res);
                return
            }
            disconnection();
            navigate('/login');
        };

        fetchDataUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    function formatNumber(num) {
        num = parseFloat(num);

        if (Math.floor(num) === num) {
            return num.toString() + ".00";
        }
        else {
            let fixedNum = num.toFixed(2);
            return fixedNum.split(".")[1] === "00" ? fixedNum.split(".")[0] : fixedNum;
        }
    }

    const calcTotalHT = () => {
        let total = 0;

        for (let prod of cart) {
            total += parseInt(prod.qte) * parseFloat(formatNumber(prod.price));
        }

        return formatNumber(total)
    }

    const calcTVA = (totalHT) => {
        const TVA = parseFloat(totalHT) / 5;
        return formatNumber(TVA)
    }

    function isValidQuantity(qte) {
        let num = parseInt(qte, 10);

        if (isFinite(num) && num > 0) {
            return true;
        }
        return false;
    }

    const setQteToProd = async (prod, e) => {
        const value = e.target.value;

        // Vérifier si la valeur est un entier positif valide
        if (!isValidQuantity(value)) {
            console.log("La quantité est invalide");
            return;
        }

        const req = await fetch(`https://api.pro-vide.eu/user/editCart/${user._id}`, {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: prod.id,
                qte: parseInt(value)
            })
        });

        const res = await req.json();
        if (res && res.length !== 0) {
            setCart(res);
        }
    }

    const deleteProdToBasket = async (prod) => {
        const req = await fetch(`https://api.pro-vide.eu/user/editCart/${user._id}`, {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: prod.id,
                qte: parseInt(0)
            })
        });

        const res = await req.json();
        if (Array.isArray(res)) {
            setCart(res);
        }
    }

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }

    return userData && (
        <div className='page_basket'>
            <h2>{title[lang]}</h2>

            <div className="container-sect">
                <section className="total-contact">
                    <div className="total">
                        <img src={cart_icon} alt="Icon" />

                        <div className="container">
                            <div className="container_total">
                                <p>{total_HT_txt[lang]} {cart.length !== 0 ? <span>{calcTotalHT()} €</span> : <span>0 €</span>}</p>
                                <p>{tva_txt[lang]} {cart.length !== 0 ? <span>{calcTVA(calcTotalHT())} €</span> : <span>0 €</span>}</p>
                                <p className='large'>{total_TTC_txt[lang]} {cart.length !== 0 ? <span>{formatNumber(parseFloat(calcTotalHT()) + parseFloat(calcTVA(calcTotalHT())))} €</span> : <span>0 €</span>}</p>
                            </div>

                            <ul className="total_list">
                                {cart && cart.length !== 0 ? cart.map((prod, idx) => (
                                    <li key={idx}>
                                        <p>{prod.id}</p>
                                        <p className='price'>{formatNumber(prod.price)}€ <span>x{prod.qte}</span></p>
                                    </li>
                                )) : (
                                    <li className='no-products'>{msg_void[lang]}</li>
                                )}
                            </ul>
                        </div>

                        <button
                            className={!cart || cart.length === 0 ? 'inactif' : ''}
                            onClick={() => cart && cart.length !== 0 && setRequestQuote(true)}
                        >{button_request[lang]}</button>
                    </div>

                    <div className="contact">
                        <img src={phone_icon} alt="Icon" />
                        <p>{msg_held[lang]}</p>
                    </div>
                </section>

                <ul className="basket">
                    {cart && cart.length !== 0 && cart.map((prod, idx) => (
                        <li key={idx}>
                            <div className="container_left">
                                {cartImg && cartImg[idx] !== '' && <img className='illus' src={cartImg[idx].image} alt='Image_prod' />}
                                <h3 onClick={() => goPage(`products/${prod.prod_id}`)}><p>{prod.name ? prod.name : prod.id} </p> <span>{prod.name ? prod.id : ''}</span></h3>
                            </div>

                            <div className="container_right">
                                <div className="container">
                                    <label>{label_price[lang]}</label>
                                    <p>{formatNumber(prod.price)} €</p>
                                </div>

                                <div className="container">
                                    <label>{label_total[lang]}</label>
                                    <p>{formatNumber(prod.price * prod.qte)} €</p>
                                </div>

                                <div className="container">
                                    <label className='label_qte'>{label_qte[lang]}</label>
                                    <input
                                        type="number"
                                        value={prod.qte}
                                        onChange={(e) => setQteToProd(prod, e)}
                                    />
                                </div>

                                <button onClick={() => deleteProdToBasket(prod)} className='delToBasket'><img src={trash_icon} alt="Delete" /></button>
                            </div>
                        </li>
                    ))}
                </ul>

                {requestQuote && <RequestQuote data={userData} setCart={setCart} />}
            </div>
        </div>
    )
}

export default Basket;