import { configureStore, createSlice } from "@reduxjs/toolkit";

// ------------------------------------------------------------------------------------------------
// State utilisateur

const userSlice = createSlice({
    name: "user",
    initialState: {
        lastname: '',
        firstname: '',
        password: '',
        _id: '',
        token: ''
    },
    reducers: {
        login: (state, action) => {
            const { lastname, firstname, password, _id, token } = action.payload;
            state.lastname = lastname || '';
            state.firstname = firstname || '';
            state.password = password || '';
            state.token = token || '';
            state._id = _id || '';
        },
        logout: (state, action) => {
            state.lastname = '';
            state.firstname = '';
            state.password = '';
            state.token = '';
            state._id = '';
        }
    }
})

export const { login, logout } = userSlice.actions;

// ------------------------------------------------------------------------------------------------
// State menu

const menuSlice = createSlice({
    name: "menu",
    initialState: {
        desktopTypeMenu: 1,
        desktopMenu: false,
        mobileMenu: false,
        userMenu: false,
        lang: 'fr'
    },
    reducers: {
        open: (state, action) => {
            if (action.payload === 'mobile') {
                state.desktopMenu = false;
            }

            if (action.payload === 'desktopMenu') {
                state.desktopMenu = !state.desktopMenu;
                state.mobileMenu = false;
                state.userMenu = false;
            }
            
            if (action.payload === 'mobileMenu') {
                state.desktopMenu = false;
                state.mobileMenu = !state.mobileMenu;
                state.userMenu = false;
            }
            
            if (action.payload === 'userMenu') {
                state.desktopMenu = false;
                state.mobileMenu = false;
                state.userMenu = !state.userMenu;
            }
        },
        close: (state, action) => {
            state.desktopMenu = false;
            state.mobileMenu = false;
            state.userMenu = false;
        },
        menu: (state, action) => {      
            if (state.desktopTypeMenu === action.payload) {
                state.desktopMenu = !state.desktopMenu;
            }      
            else {
                state.desktopMenu = true;
            }

            state.desktopTypeMenu = action.payload;
            state.mobileMenu = false;
            state.userMenu = false;
        },
        lang: (state, action) => {            
            state.lang = action.payload;
        }
    }
})

export const { open, close, menu, lang } = menuSlice.actions;

// ------------------------------------------------------------------------------------------------
// State des données

const dataSlice = createSlice({
    name: "data",
    initialState: {
        categories: [],
        products: [],
        services: [],
        realisations: [],
    },
    reducers: {
        addCategoriesToState: (state, action) => {
            state.categories = action.payload;
        },
        addProductsToState: (state, action) => {
            state.products = action.payload;
        },
        addServicesToState: (state, action) => {
            state.services = action.payload;
        },
        addRealisationsToState: (state, action) => {
            state.realisations = action.payload;
        }
    }
})

export const { addCategoriesToState, addProductsToState, addServicesToState, addRealisationsToState } = dataSlice.actions;

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        menu: menuSlice.reducer,
        data: dataSlice.reducer,
    }
});