import { useState } from 'react';
import { useSelector } from 'react-redux';

import Input from '../../components/Input';

import logo from './../../assets/svg/contact_icon-contact.svg'
import file_icon from './../../assets/svg/files_icon-input.svg'

import "./style.css"

const title_contact = {
    fr: `Nous contacter`,
    en: `Contact us`,
    sp: `Contáctenos`,
    de: `Kontaktiere uns`
}

const label_name = {
    fr: `Nom complet`,
    en: `Full name`,
    sp: `Nombre completo`,
    de: `Vollständiger Name`
}

const label_compagny = {
    fr: `Société/Organisation`,
    en: `Compagny/Organization`,
    sp: `Unternehmen`,
    de: `Empresa/Organización`
}

const label_city = {
    fr: `Ville`,
    en: `City`,
    sp: `Ciudad`,
    de: `Stadt`
}

const label_country = {
    fr: `Pays`,
    en: `Country`,
    sp: `País`,
    de: `Land`
}

const label_phone = {
    fr: `Téléphone`,
    en: `Phonenumber`,
    sp: `Teléfono`,
    de: `Telefon`
}

const label_email = {
    fr: `Adresse mail`,
    en: `Mail address`,
    sp: `Correo electrónico`,
    de: `Mail adresse`
}

const label_request = {
    fr: `Demande`,
    en: `Request`,
    sp: `Pedido`,
    de: `Anfrage`
}

const placeholder_request = {
    fr: `Donnez-nous un maximum de détails pour nous permettre de répondre au mieux à votre demande.`,
    en: `Give us as much detail as possible to enable us to best respond to your request.`,
    sp: `Bríndenos tantos detalles como sea posible para que podamos responder mejor a su solicitud.`,
    de: `Geben Sie uns so viele Details wie möglich, damit wir optimal auf Ihre Anfrage reagieren können.`
}

const label_input_file = {
    fr: `Fichier`,
    en: `File`,
    sp: `Archivo`,
    de: `Datei`
}

const placeholder_input_file = {
    fr: `Ajouter un fichier`,
    en: `Add file`,
    sp: `Agregar un archivo`,
    de: `Fügen Sie eine Datei hinzu`
}

const button_send = {
    fr: `Envoyer`,
    en: `Send`,
    sp: `Enviar a`,
    de: `Schicken`
}

function FormContact() {
    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [formData, setFormData] = useState({
        name: '',
        society: '',
        city: '',
        country: '',
        phone: '',
        email: '',
        request: ''
    });
    
    const request = async (event) => {
        event.preventDefault();

        const req = await fetch(`https://api.pro-vide.eu/user/sendContact`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        });
        const res = await req.json();
        
        if (res === true) {
            alert('Ok');
            return
        }
        alert('Erreur');
    }

    return (
        <form className='form_contact' onSubmit={(event) => request(event)}>
            <h2><img src={logo} alt="Logo" />{title_contact[lang]}</h2>

            <div className="container">
                <Input label={label_name[lang]} placeholder='John Doe' value={formData.name} setValue={setFormData} labForm='name' />
                <Input label={label_compagny[lang]} placeholder='JohnDoe compagny' value={formData.society} setValue={setFormData} labForm='society' />
                <Input label={label_city[lang]} placeholder='Paris' value={formData.city} setValue={setFormData} labForm='city' />
                <Input label={label_country[lang]} placeholder='France' value={formData.country} setValue={setFormData} labForm='country' />
                <Input label={label_phone[lang]} placeholder='0614253647' value={formData.phone} setValue={setFormData} labForm='phone' />
                <Input label={label_email[lang]} placeholder='john-doe@gmail.com' value={formData.email} setValue={setFormData} labForm='email' />

                <div className='input_request'>
                    <label>{label_request[lang]}</label>
                    <textarea
                        type="text"
                        value={formData.request}
                        placeholder={placeholder_request[lang]}
                        onChange={e => setFormData(prev => ({ ...prev, request: e.target.value }))}
                    />
                </div>

                <div className="input_file">
                    <label>{label_input_file[lang]}</label>
                    <div className="inputFile">
                        <input type="file" />
                        <p><img src={file_icon} alt="Icon" />{placeholder_input_file[lang]}</p>
                    </div>
                </div>
            </div>
            
            <button type='submit'>{button_send[lang]}</button>
        </form>
    )
}

export default FormContact;