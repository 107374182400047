import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { close } from '../../redux';

import chevron_icon from './../../assets/svg/chevron_icon-nav.svg'

import "./style.css"

const our_products = {
    fr: `Nos produits`,
    en: `Our products`,
    sp: `Nuestros productos`,
    de: `Unsere Produkte`
};
const our_services = {
    fr: `Nos services`,
    en: `Our services`,
    sp: `Nuestros servicios`,
    de: `Unsere Dienstleistungen`
};

function NavMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [dataMenu, setDataMenu] = useState({
        categories: [],
        subcategories: [],
        products: []
    });
    const [route, setRoute] = useState({
        subcategories: '',
        products: ''
    });

    const [subcatSelected, setSubcatSelected] = useState('');
    const [catSelected, setCatSelected] = useState('');

    const menu = useSelector(state => state.menu);
    const data = useSelector(state => state.data);

    // -------------------------------------------

    // Récupération des catégorie
    useEffect(() => {
        const type = menu.desktopTypeMenu === 1 ? 'prod' : 'serv';
        setDataMenu({
            categories: data.categories.filter(e => !e.parentId && e.type === type),
            subcategories: [],
            products: []
        })
        setRoute({
            subcategories: '',
            products: ''
        });
        setSubcatSelected('');
        setCatSelected('');
    }, [data.categories, menu.desktopTypeMenu]);

    // Récupération des subcatégories
    useEffect(() => {
        if (catSelected === '') {
            setDataMenu({
                categories: dataMenu.categories,
                subcategories: [],
                products: []
            });
            setRoute({
                subcategories: '',
                products: ''
            });
            setSubcatSelected('');
            return
        }

        let subcategories = data.categories.filter(e => e.parentId === catSelected);
        setRoute({
            subcategories: menu.desktopTypeMenu === 1 ? 'categories' : 'categoriesServ',
            products: ''
        })

        if (subcategories.length === 0) {
            if (menu.desktopTypeMenu === 1) {
                subcategories = data.products.filter(e => e.parentId === catSelected);
                setRoute({
                    subcategories: 'products',
                    products: ''
                })
            }
            else {
                subcategories = data.services.filter(e => e.parentId === catSelected);
                setRoute({
                    subcategories: 'services',
                    products: ''
                })
            }
        }

        setDataMenu({
            categories: dataMenu.categories,
            subcategories: subcategories,
            products: []
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catSelected]);

    // Récupération des produits
    useEffect(() => {
        if (subcatSelected === '') {
            setDataMenu({
                categories: dataMenu.categories,
                subcategories: dataMenu.subcategories,
                products: []
            });
            setRoute({
                subcategories: route.subcategories,
                products: ''
            });
            return
        }

        let products = data.categories.filter(e => e.parentId === subcatSelected);
        setRoute({
            subcategories: route.subcategories,
            products: menu.desktopTypeMenu === 1 ? 'categories' : 'categoriesServ'
        })

        if (products.length === 0) {
            if (menu.desktopMenu === 1) {
                products = data.products.filter(e => e.parentId === subcatSelected);
                setRoute({
                    subcategories: route.subcategories,
                    products: 'products'
                })
            }
            else {
                products = data.services.filter(e => e.parentId === subcatSelected);
                setRoute({
                    subcategories: route.subcategories,
                    products: 'services'
                })
            }
        }

        setDataMenu({
            categories: dataMenu.categories,
            subcategories: dataMenu.subcategories,
            products: products
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcatSelected]);
    
    // Fermeture menu
    useEffect(() => {
        if (menu.desktopMenu === false) {
            setDataMenu({
                categories: dataMenu.categories,
                subcategories: [],
                products: []
            });
            setRoute({
                subcategories: '',
                products: ''
            });
            setSubcatSelected('');
            setCatSelected('');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu.desktopMenu]);

    // Changement de page
    function goPage(id, route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}/${id}`);
    }

    // ----------------------------------

    return menu.desktopMenu && (
        <div className='navMenu'>
            <div className="content">
                {/* 1er menu */}
                <div className="menu-categories menu">
                    <span>{menu.desktopTypeMenu === 1 ? our_products[menu.lang] : our_services[menu.lang]}</span>

                    <ul>
                        {dataMenu.categories.map(cat => (
                            <li key={cat._id}>
                                <button
                                    onClick={() => goPage(cat._id, menu.desktopTypeMenu === 1 ? 'categories' : 'categoriesServ')}
                                    className={cat._id === catSelected ? 'actifHover' : ''}
                                    onMouseEnter={() => setCatSelected(cat._id)}
                                >
                                    {cat.name}<img src={chevron_icon} alt='arrow' />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                <i></i>

                {dataMenu.subcategories.length !== 0 && (
                    <>
                        {/* 2eme menu */}
                        <div className="menu-subcategories menu">
                            <span className='hidden'>_</span>

                            <ul>
                                {dataMenu.subcategories.map((e, idx) => (
                                    <li key={idx}>
                                        <button
                                            onMouseEnter={() => setSubcatSelected(e._id)}
                                            onClick={() => goPage(e._id, route.subcategories)}
                                            className={e._id === subcatSelected ? 'actifHover' : ''}
                                        >{e.name}<img src={chevron_icon} alt='arrow' /></button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <i></i>

                        {dataMenu.products.length !== 0 && (
                            
                            // 3eme menu 
                            <div className="menu-products menu">
                                <span className='hidden'>_</span>

                                <ul>
                                    {dataMenu.products.map((e, idx) => (
                                        <li key={idx}>
                                            <button
                                                onClick={() => goPage(e._id, route.products)}
                                            >
                                                {e.name}<img src={chevron_icon} alt='arrow' />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default NavMenu;