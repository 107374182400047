import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Input from './../Input';

import "./style.css"

const button_request = {
    fr: `Demander un devis`,
    en: `Ask for a quote`,
    sp: `Solicite una cotización`,
    de: `Fordern Sie ein Angebot an`
};
const button_show = {
    fr: `Nos références`,
    en: `Our references`,
    sp: `Nuestras referencias`,
    de: `Unsere Referenzen`
};
const button_make_request = {
    fr: `Faire une demande`,
    en: `To make a request`,
    sp: `Para hacer una petición`,
    de: `Eine Anfrage stellen`
};
const title_modal = {
    fr: `Demande de produit`,
    en: `Product request`,
    sp: `Petición de producto`,
    de: `Produkt-Anfrage`
};
const label_name_modal = {
    fr: `Produit :`,
    en: `Product :`,
    sp: `Producto :`,
    de: `Produkt :`
};
const label_request_modal = {
    fr: `Besoins spécifiques :`,
    en: `Specific needs :`,
    sp: `Necesidades específicas :`,
    de: `Spezielle Bedürfnisse :`
};
const placeholder_request_modal = {
    fr: `Décrivez-nous au mieux vos besoins`,
    en: `Describe your needs to us as best as possible`,
    sp: `Descríbanos sus necesidades lo mejor posible`,
    de: `Beschreiben Sie uns Ihre Bedürfnisse so gut wie möglich`
};
const label_compagny = {
    fr: `Société`,
    en: `Compagny`,
    sp: `Unternehmen`,
    de: `Empresa`
}
const label_service = {
    fr: `Service`,
    en: `Service`,
    sp: `Servicio`,
    de: `Service`
};
const label_city = {
    fr: `Ville`,
    en: `City`,
    sp: `Ciudad`,
    de: `Stadt`
}
const label_country = {
    fr: `Pays`,
    en: `Country`,
    sp: `País`,
    de: `Land`
}
const label_phone = {
    fr: `Téléphone`,
    en: `Phonenumber`,
    sp: `Teléfono`,
    de: `Telefon`
}
const label_email = {
    fr: `Adresse mail`,
    en: `Mail address`,
    sp: `Correo electrónico`,
    de: `Mail adresse`
}
const label_address = {
    fr: `Adresse`,
    en: `Address`,
    sp: `Dirección`,
    de: `Adresse`
}
const label_zipcode = {
    fr: `Code postal`,
    en: `Zip code`,
    sp: `Código postal`,
    de: `Postleitzahl`
}

function SliderProd({ data, scrollToReferences }) {
    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [counter, setCounter] = useState(0);
    const [images, setImages] = useState([]);

    const [formData, setFormData] = useState({
        society: '',
        service: '',

        siret: '',
        email: '',

        phoneNumber: '',
        
        address_street: '',
        address_city: '',
        
        address_country: '',
        address_zip_code: ''
    });
    const [requestProd, setRequestProd] = useState(false);
    const [descripRequest, setDescripRequest] = useState('');
    const [msgSuccessOrError, setMsgSuccessOrError] = useState('');

    // Récupération des images du produit
    useEffect(() => {
        const fetchImg = async () => {
            if (data && data.images) {
                const reqImgProd = await Promise.all(data.images.map(async (imgId) => {
                    if (imgId.length !== 0) {
                        const req = await fetch(`https://api.pro-vide.eu/public/image/${imgId}`);
                        const res = await req.json();
                        return res ? res : '';
                    }
                    else {
                        return '';
                    }
                }));
                setImages(reqImgProd);
            }
        }
        fetchImg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };

    const request = async (event) => {
        event.preventDefault();

        let body = user._id ? { _id: user._id } : { ...formData };
        body.request = descripRequest;
        body.typeReq = 'dem';
        body.product_code = data.name;

        try {
            const req = await fetch(`https://api.pro-vide.eu/user/sendRequest`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            });
            const res = await req.json();

            if (res !== true) {
                setMsgSuccessOrError('Une erreur est survenue');
                setTimeout(() => {
                    window.location.reload();
                }, 3500)
                return
            }
            setMsgSuccessOrError('Demande envoyer avec succès');
            setTimeout(() => {
                setMsgSuccessOrError('');
                setDescripRequest('');
                setRequestProd(false);
                setFormData({
                    society: '',
                    service: '',
                    siret: '',
                    cellphoneNumber: '',
                    phoneNumber: '',
                    address_street: '',
                    address_city: '',
                    address_zip_code: ''
                });
            }, 4000)
        }
        catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };


    return data && lang && (
        <div className='slider-products'>
            <div className="descrip">
                <h2>{data.name}</h2>
                <p>{transformText(data.text)}</p>
                {data && data.table_ref.length !== 0 ?
                    <button onClick={scrollToReferences}>{button_show[lang]}</button> :
                    <button onClick={() => setRequestProd(true)} className="small">{button_request[lang]}</button>
                }
            </div>

            {images && images.length !== 0 && (
                <div className="slider-prod">
                    <div className="view">
                        <img src={images[counter].image} alt={`Illustration ${counter}`} />
                    </div>

                    <div className="list">
                        {images && images.length !== 0 && images.map((img, idx) => (
                            <div
                                key={idx}
                                onClick={() => setCounter(idx)}
                                className={`preview ${counter === idx && 'actif'}`}
                            >
                                <img src={img.image} alt="Illustration" />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {requestProd && (
                <section className="requestProd">
                    <div className="content">
                        {!msgSuccessOrError && msgSuccessOrError === '' ? (
                            <>
                                <i onClick={() => setRequestProd(false)} className="fas fa-xmark"></i>

                                <span className="title">{title_modal[lang]}</span>

                                <form onSubmit={(event) => request(event)}>
                                    <div className='input_model input_lock'>
                                        <label>{label_name_modal[lang]}</label>
                                        <input
                                            type="text"
                                            value={data.name}
                                            className={`input_`}
                                            readOnly
                                        />
                                    </div>

                                    {(!user || !user._id) &&
                                        <div className="container_fields">
                                            {formData && <Input label={label_compagny[lang]} placeholder='JohnDoe company' value={formData.society} setValue={setFormData} labForm='society' />}
                                            {formData && <Input label={label_service[lang]} placeholder='Général' value={formData.service} setValue={setFormData} labForm='service' />}
                                            
                                            {formData && <Input label='Siret/Siren' placeholder='Numéro siret/siren' value={formData.siret} setValue={setFormData} labForm='siret' />}
                                            {formData && <Input label={label_email[lang]} placeholder='john-doe@gmail.com' value={formData.email} setValue={setFormData} labForm='email' />}

                                            {formData && <Input label={label_phone[lang]} placeholder='Saisissez votre mobile' value={formData.phoneNumber} setValue={setFormData} labForm='phoneNumber' />}
                                            
                                            {formData && <Input label={label_address[lang]} placeholder='10 route de paris' value={formData.address_street} setValue={setFormData} labForm='address_street' />}
                                            {formData && <Input label={label_city[lang]} placeholder='Strasbourg' value={formData.address_city} setValue={setFormData} labForm='address_city' />}
                                            
                                            {formData && <Input label={label_country[lang]} placeholder='France' value={formData.address_country} setValue={setFormData} labForm='address_country' />}
                                            {formData && <Input label={label_zipcode[lang]} placeholder='67000' value={formData.address_zip_code} setValue={setFormData} labForm='address_zip_code' />}
                                        </div>
                                    }

                                    <div className='input_model input_lock'>
                                        <label>{label_request_modal[lang]}</label>
                                        <textarea
                                            value={descripRequest}
                                            className={`input_`}
                                            placeholder={placeholder_request_modal[lang]}
                                            onChange={(e) => setDescripRequest(e.target.value)}
                                        ></textarea>
                                    </div>

                                    <button type="submit">{button_make_request[lang]}</button>
                                </form>
                            </>
                        ) : (
                            <p>{msgSuccessOrError}</p>
                        )}
                    </div>
                </section>
            )}
        </div>
    )
}

export default SliderProd;