import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { close, logout } from '../../redux'

import chevron_icon from './../../assets/svg/chevron_icon-nav.svg'
import engine_icon from './../../assets/svg/realisation_icon-nav.svg'
import phone_icon from './../../assets/svg/contact_icon-nav.svg'
import about_icon from './../../assets/svg/about_icon-nav.svg'
import settings_icon from './../../assets/svg/settings_icon-nav.svg'
import cart_icon from './../../assets/svg/cart_icon-nav.svg'
import history_icon from './../../assets/svg/history_icon-nav.svg'
import logout_icon from './../../assets/svg/logout_icon-nav.svg'

import "./style.css"

const menu_prod = {
    fr: `Nos produits`,
    en: `Products`,
    sp: `Productos`,
    de: `Produkte`
};

const menu_serv = {
    fr: `Nos services`,
    en: `Services`,
    sp: `Servicios`,
    de: `Dienstleistungen`
};

const button_page_real = {
    fr: `Réalisations`,
    en: `Achievements`,
    sp: `Logros`,
    de: `Erfolge`
};

const button_page_contact = {
    fr: `Contact`,
    en: `Contact`,
    sp: `Contactar`,
    de: `Kontakt`
};

const button_page_about = {
    fr: `À propos`,
    en: `About`,
    sp: `A proposito`,
    de: `Um`
};

const button_page_settings = {
    fr: `Paramètre du compte`,
    en: `Account setting`,
    sp: `Configuración de cuenta`,
    de: `Konto-Einstellung`
};

const button_page_cart = {
    fr: `Consulter mon panier`,
    en: `View my cart`,
    sp: `Ver mi carrito`,
    de: `Einkaufswagen`
};

const button_page_historical = {
    fr: `Historique des demandes`,
    en: `Request history`,
    sp: `Historial de solicitudes`,
    de: `Anfrageverlauf`
};

const button_logout = {
    fr: `Se déconnecter`,
    en: `Sign out`,
    sp: `Desconectarse`,
    de: `Abmelden`
};

function Nav() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const menu = useSelector(state => state.menu);
    const data = useSelector(state => state.data);

    const [subMenu, setSubMenu] = useState(false);

    useEffect(() => {
        if (!menu.mobileMenu) {
            setSubMenu({ prod: false, serv: false });
        }
    }, [menu.mobileMenu])

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }

    // Logout
    const disconnection = () => {
        sessionStorage.removeItem('_id');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('password');
        sessionStorage.removeItem('lastname');
        sessionStorage.removeItem('firstname');

        localStorage.removeItem('_id');
        localStorage.removeItem('token');
        localStorage.removeItem('password');
        localStorage.removeItem('lastname');
        localStorage.removeItem('firstname');

        dispatch(logout());
        dispatch(close());
    }

    return menu && (menu.userMenu || menu.mobileMenu) && (
        <nav>
            {subMenu && data && (
                <div className="mobile-content">

                    {/* SubMenu-Mobile products */}
                    <button onClick={() => setSubMenu({ ...subMenu, prod: !subMenu.prod })}><img className={subMenu.prod ? 'bottom' : ''} src={chevron_icon} alt="icon" />{menu_prod[menu.lang]}</button>
                    {subMenu.prod && data.categories.length !== 0 && (
                        <div className='subMenu_mobile'>
                            {data.categories.map(cat => cat.type === 'prod' && !cat.parentId && (
                                <button key={cat._id} onClick={() => goPage(`categories/${cat._id}`)}>{cat.name}</button>
                            ))}
                        </div>
                    )}

                    {/* SubMenu-Mobile services */}
                    <button onClick={() => setSubMenu({ ...subMenu, serv: !subMenu.serv })}><img className={subMenu.serv ? 'bottom' : ''} src={chevron_icon} alt="icon" />{menu_serv[menu.lang]}</button>
                    {subMenu.serv && data.categories.length !== 0 && (
                        <div className='subMenu_mobile'>
                            {data.categories.map(cat => cat.type === 'serv' && !cat.parentId && (
                                <button key={cat._id} onClick={() => goPage(`categoriesServ/${cat._id}`)}>{cat.name}</button>
                            ))}
                        </div>
                    )}

                    <button onClick={() => goPage('realisations')}><img src={engine_icon} alt="icon" />{button_page_real[menu.lang]}</button>
                    <button onClick={() => goPage('contact')}><img src={phone_icon} alt="icon" />{button_page_contact[menu.lang]}</button>
                    <button onClick={() => goPage('about')}><img src={about_icon} alt="icon" />{button_page_about[menu.lang]}</button>
                </div>
            )}

            <button onClick={() => goPage('settings')}><img src={settings_icon} alt="icon" />{button_page_settings[menu.lang]}</button>
            <button onClick={() => goPage('basket')}><img src={cart_icon} alt="icon" />{button_page_cart[menu.lang]}</button>
            <button onClick={() => goPage('historical')}><img src={history_icon} alt="icon" />{button_page_historical[menu.lang]}</button>
            <button onClick={() => disconnection()}><img src={logout_icon} alt="icon" />{button_logout[menu.lang]}</button>
        </nav>
    )
}

export default Nav;