import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux';

import Input from './../../components/Input';

import "./style.css"

const subtitle_login = {
    fr: `Déjà client ?`,
    en: `Already a customer?`,
    sp: `¿Ya es cliente?`,
    de: `Schon Kunde?`
}
const label_email = {
    fr: `Adresse mail`,
    en: `Mail address`,
    sp: `Correo electrónico`,
    de: `Mail adresse`
}
const label_password = {
    fr: `Mot de passe`,
    en: `Password`,
    sp: `Contraseña`,
    de: `Passwort`
}
const button_login = {
    fr: `Connexion`,
    en: `Login`,
    sp: `Iniciar sesión`,
    de: `Einloggen`
}
const password_forget = {
    fr: `Mot de passe oublié`,
    en: `Forgot Password`,
    sp: `¿Olvidó su contraseña?`,
    de: `Passwort vergessen`
}
const save_me = {
    fr: `Se souvenir de moi`,
    en: `Remember me`,
    sp: `Recuérdame`,
    de: `Erinnere dich an mich`
}
const text_pw_forget = {
    fr: `Renseignez-nous votre adresse mail, pour pouvoir modifier votre mot de passe :`,
    en: `Provide your email address to be able to change your password:`,
    sp: `Proporcione su dirección de correo electrónico para poder cambiar su contraseña:`,
    de: `Geben Sie uns Ihre E-Mail-Adresse an, um Ihr Passwort ändern zu können:`
}
const button_validation = {
    fr: `Valider`,
    en: `Validate`,
    sp: `Validar`,
    de: `Bestätigen`
}
const button_confirmation = {
    fr: `Confirmer`,
    en: `Confirm`,
    sp: `Confirmar`,
    de: `Bestätigen`
}
const title_verif = {
    fr: `Vérification`,
    en: `Verification`,
    sp: `Verificación`,
    de: `Verifizierung`
}
const text_verif = {
    fr: `Un code de vérification vous a été envoyé par mail, merci de le renseigner afin de pouvoir mettre à jour votre mot de passe :`,
    en: `A verification code has been sent to you by email, please enter it to update your password:`,
    sp: `Se le ha enviado un código de verificación por correo electrónico, ingréselo para actualizar su contraseña:`,
    de: `Ein Bestätigungscode wurde Ihnen per E-Mail zugesandt, bitte geben Sie ihn ein, um Ihr Passwort zu aktualisieren:`
}
const title_new_pw = {
    fr: `Nouveau mot de passe`,
    en: `New Password`,
    sp: `Nueva contraseña`,
    de: `Neues Passwort`
}
const text_new_pw = {
    fr: `Merci de renseigner votre nouveau mot de passe :`,
    en: `Please enter your new password:`,
    sp: `Por favor ingrese su nueva contraseña:`,
    de: `Bitte geben Sie Ihr neues Passwort ein:`
}
const title_conf = {
    fr: `Mot de passe enregistré`,
    en: `Password Saved`,
    sp: `Contraseña guardada`,
    de: `Passwort gespeichert`
}
const text_conf = {
    fr: `Votre nouveau mot de passe a été modifié avec succès, vous allez être redirigé vers la page d'accueil.`,
    en: `Your new password has been successfully changed, you will be redirected to the homepage.`,
    sp: `Su nueva contraseña se ha cambiado con éxito, será redirigido a la página de inicio.`,
    de: `Ihr neues Passwort wurde erfolgreich geändert, Sie werden auf die Startseite weitergeleitet.`
}

function FormLogin() {
    const dispatch = useDispatch();
    const lang = useSelector(state => state.menu.lang);

    const [mdlForget, setMdlForget] = useState(false);

    const [email_forget, setEmail_forget] = useState('');
    const [code_forget, setCode_forget] = useState('');

    const [newPw, setNewPw] = useState('');
    const [userForget, setUserForget] = useState({
        _id: '',
        token: '',
        lastname: '',
        firstname: ''
    });
    const [conf_newPw, setConf_newPw] = useState('');

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        save: false
    });

    // Connection
    const testLogin = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: formData.email,
                password: formData.password
            })
        });
        const res = await req.json();

        if (res && res._id) {
            if (formData.save) {
                localStorage.setItem('lastname', res.lastname);
                localStorage.setItem('firstname', res.firstname);
                localStorage.setItem('password', formData.password);
                localStorage.setItem('token', res.token);
                localStorage.setItem('_id', res._id);
            }
            sessionStorage.setItem('lastname', res.lastname);
            sessionStorage.setItem('firstname', res.firstname);
            sessionStorage.setItem('password', formData.password);
            sessionStorage.setItem('token', res.token);
            sessionStorage.setItem('_id', res._id);

            dispatch(login({ _id: res._id, token: res.token, password: formData.password, firstname: res.firstname, lastname: res.lastname }));
        }
    }

    // Submit form
    const handleSubmit = (event) => {
        event.preventDefault();
        testLogin();
    };

    // Envoie un code de vérification par mail
    const forgetPw = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/findAccountForget`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email_forget })
        });
        const res = await req.json();

        if (res === true) {
            setMdlForget(1);
        }
    }

    // Vérification du code envoyé par mail
    const checkCodeForget = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/accountForget`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email_forget, code: code_forget })
        });
        const res = await req.json();
        
        if (res.userId) {
            setUserForget({
                _id: res.userId,
                token: res?.token,
                lastname: res?.lastname,
                firstname: res?.firstname
            })
            setMdlForget(2);
        }
    }

    // Change le mot de passe si authentification valide
    const changeForgetPw = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/changePwForget/${userForget._id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userForget.token}` },
            body: JSON.stringify({ password: newPw })
        });
        const res = await req.json();

        if (res === true) {
            sessionStorage.setItem('lastname', userForget.lastname);
            sessionStorage.setItem('firstname', userForget.firstname);
            sessionStorage.setItem('password', newPw);
            sessionStorage.setItem('token', userForget.token);
            sessionStorage.setItem('_id', userForget._id);

            dispatch(login({ _id: userForget._id, token: userForget.token, password: newPw, firstname: userForget.firstname, lastname: userForget.lastname }));

            setEmail_forget('');
            setCode_forget('');
            setNewPw('');
            setConf_newPw('');
            setUserForget({
                _id: '',
                token: '',
                lastname: '',
                firstname: ''
            });

            setMdlForget(3);

            setTimeout(() => {
                setMdlForget(false);
            }, 4000)
        }
    }

    return lang && (
        <>
            <form className='form_login' onSubmit={handleSubmit}>
                <h3>{subtitle_login[lang]}</h3>

                <Input label={label_email[lang] + ' *'} placeholder='john.doe@mail.com' value={formData.email} setValue={setFormData} labForm='email' />
                <Input label={label_password[lang] + ' *'} placeholder='****************' value={formData.password} setValue={setFormData} labForm='password' />

                <div className="container-save-forgetPw">
                    <p onClick={() => setFormData(prev => ({ ...prev, save: !prev.save }))}><i>{formData.save && '✓'}</i>{save_me[lang]}</p>
                    <button onClick={() => setMdlForget(0)} type='button'>{password_forget[lang]}?</button>
                </div>

                <button
                    className='btn-login'
                    type='submit'
                >{button_login[lang]}</button>
            </form>

            {mdlForget !== false && (
                <section className='sect_forgetPw'>

                    {mdlForget === 0 && (
                        <div className="content">
                            <h3>{password_forget[lang]} ?</h3>
                            <p>{text_pw_forget[lang]}</p>

                            <input
                                type="text"
                                placeholder='john.doe@society.eu'
                                value={email_forget}
                                onChange={(e) => setEmail_forget(e.target.value)}
                            />

                            <button onClick={() => forgetPw()}>{button_validation[lang]}</button>
                        </div>
                    )}

                    {mdlForget === 1 && (
                        <div className="content">
                            <h3>{title_verif[lang]}</h3>
                            <p>{text_verif[lang]}</p>

                            <input
                                type="text"
                                placeholder='_ _ _ _ _ _ _ _'
                                value={code_forget}
                                onChange={(e) => setCode_forget(e.target.value)}
                            />

                            <button onClick={() => checkCodeForget()}>{button_confirmation[lang]}</button>
                        </div>
                    )}

                    {mdlForget === 2 && (
                        <div className="content">
                            <h3>{title_new_pw[lang]}</h3>
                            <p>{text_new_pw[lang]}</p>

                            <input
                                type="password"
                                placeholder='***************'
                                value={newPw}
                                onChange={(e) => setNewPw(e.target.value)}
                            />

                            <input
                                type="password"
                                placeholder='***************'
                                value={conf_newPw}
                                onChange={(e) => setConf_newPw(e.target.value)}
                            />

                            <button onClick={() => changeForgetPw()}>{button_confirmation[lang]}</button>
                        </div>
                    )}

                    {mdlForget === 3 && (
                        <div className="content">
                            <h3>{title_conf[lang]}</h3>
                            <p>{text_conf[lang]}</p>
                        </div>
                    )}
                </section>
            )}
        </>
    )
}

export default FormLogin;