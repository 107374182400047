import "./style.css"

function MainReal({ data, images, showRealisation }) {   

    return (
        <ul className={`main_real`}>
            {data && data.length !== 0 && data.map((real, idx) => (
                <li 
                    key={idx}
                    onClick={() => showRealisation(real._id)}
                >
                    <div className="container-img">
                        {images && images.length !== 0 && <img src={images[idx].image} alt="Illustration" />}
                    </div>

                    <p>{real.name}</p>
                </li>
            ))}
        </ul>
    )
}

export default MainReal;