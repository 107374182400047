import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import FormContact from './../../forms/FormContact'

import location_icon from './../../assets/svg/location_icon-contact.svg';

import "./style.css"
import { useSelector } from "react-redux";

const title_location = {
    fr: `Où nous trouver?`,
    en: `Where to find us?`,
    sp: `¿Dónde encontrarnos?`,
    de: `Wo finden Sie uns?`
}

const label_phone = {
    fr: `Téléphone`,
    en: `Phonenumber`,
    sp: `Teléfono`,
    de: `Telefon`
}

function Contact() {
    const position = [47.4748593, 1.4139571];
    const lang = useSelector(state => state.menu.lang);

    return lang && (
        <div className='page_contact'>
            <FormContact />

            <div className="location">
                <h2><img src={location_icon} alt="Logo" />{title_location[lang]}</h2>

                <div className="container">
                    <p className="address">24 Bis Route de Fougères <br />
                        41120 CORMERAY <br />
                        France</p>
                    <p className="phone">{label_phone[lang]} : <span>+33 2 55 99 65 05</span></p>

                    <MapContainer className="map" center={position} zoom={10} style={{ height: "250px", width: "100%" }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={position}>
                            <Popup>
                                Pro-vide
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
        </div>
    )
}

export default Contact;