import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Input from "../../components/Input";

import "./style.css"

const title = {
    fr: `Demander un devis`,
    en: `Request a Quote`,
    sp: `Solicitar un presupuesto`,
    de: `Angebot anfordern`
}
const subtitle_coordinate = {
    fr: `Coordonnées`,
    en: `Contact Information`,
    sp: `Datos de contacto`,
    de: `Kontaktdaten`
}
const subtitle_delivery_address = {
    fr: `Adresse de livraison`,
    en: `Delivery Address`,
    sp: `Dirección de entrega`,
    de: `Lieferadresse`
}
const subtitle_specific_need = {
    fr: `Besoins spécifiques`,
    en: `Specific Needs`,
    sp: `Necesidades específicas`,
    de: `Spezifische Bedürfnisse`
}

const label_lastname = {
    fr: `Nom`,
    en: `Lastname`,
    sp: `Apellido`,
    de: `Nachname`
}
const label_firstname = {
    fr: `Prénom`,
    en: `Firstname`,
    sp: `Nombre de pila`,
    de: `Vorname`
}
const label_compagny = {
    fr: `Société`,
    en: `Compagny`,
    sp: `Unternehmen`,
    de: `Empresa`
}

const label_service = {
    fr: `Service`,
    en: `Service`,
    sp: `Servicio`,
    de: `Service`
};
const placeholder_service = {
    fr: `Saisissez le nom du service`,
    en: `Enter the name of the service`,
    sp: `Ingrese el nombre del servicio`,
    de: `Geben Sie den Namen des Dienstes ein`
};

const label_siret = {
    fr: `Siret/Siren`,
    en: `Siret/Siren`,
    sp: `Siret/Siren`,
    de: `Siret/Siren`
}
const placeholder_siret = {
    fr: `Saisissez votre numero siret/siren`,
    en: `Enter your Siret/Siren number`,
    sp: `Ingrese su número de Siret/Siren`,
    de: `Geben Sie Ihre Siret/Siren-Nummer ein`
}

const label_cellphone = {
    fr: `Téléphone fixe`,
    en: `Phone`,
    sp: `Teléfono fijo`,
    de: `Telefon`
}
const placeholder_cellphone = {
    fr: `Saisissez votre fixe`,
    en: `Enter your phone number`,
    sp: `Ingrese su teléfono fijo`,
    de: `Geben Sie Ihre Telefonnummer ein`
}

const label_phone = {
    fr: `Téléphone mobile`,
    en: `Mobile phone`,
    sp: `Teléfono móvil`,
    de: `Mobiltelefon`
}
const placeholder_phone = {
    fr: `Saisissez votre mobile`,
    en: `Enter your mobile number`,
    sp: `Ingrese su teléfono móvil`,
    de: `Geben Sie Ihre Handynummer ein`
}

const label_address = {
    fr: `Adresse`,
    en: `Address`,
    sp: `Dirección`,
    de: `Adresse`
}
const placeholder_address = {
    fr: `Saisissez votre adresse`,
    en: `Enter your address`,
    sp: `Ingrese su dirección`,
    de: `Geben Sie Ihre Adresse ein`
}

const label_city = {
    fr: `Ville`,
    en: `City`,
    sp: `Ciudad`,
    de: `Stadt`
}
const placeholder_city = {
    fr: `Saisissez votre ville`,
    en: `Enter your city`,
    sp: `Ingrese su ciudad`,
    de: `Geben Sie Ihre Stadt ein`
}

const label_zipcode = {
    fr: `Code postal`,
    en: `Zip code`,
    sp: `Código postal`,
    de: `Postleitzahl`
}

const label_country = {
    fr: `Pays`,
    en: `Country`,
    sp: `País`,
    de: `Land`
}
const placeholder_country = {
    fr: `Saisissez votre pays`,
    en: `Enter your country`,
    sp: `Ingrese su país`,
    de: `Geben Sie Ihr Land ein`
}

const label_selector = {
    fr: `Choisissez une adresse :`,
    en: `Choose an address:`,
    sp: `Elija una dirección:`,
    de: `Wählen Sie eine Adresse:`
}
const option_default = {
    fr: `Sélectionner une adresse`,
    en: `Select an address`,
    sp: `Seleccionar una dirección`,
    de: `Adresse auswählen`
}
const option_add = {
    fr: `+ Ajouter une adresse`,
    en: `+ Add an address`,
    sp: `+ Añadir una dirección`,
    de: `+ Adresse hinzufügen`
}

const label_specific_need = {
    fr: `Quels sont vos besoins :`,
    en: `What are your needs:`,
    sp: `Cuáles son sus necesidades:`,
    de: `Was sind Ihre Bedürfnisse:`
}
const placeholder_specific_need = {
    fr: `Décrivez-nous au mieux vos besoins`,
    en: `Describe your needs as best as possible`,
    sp: `Describa sus necesidades lo mejor posible`,
    de: `Beschreiben Sie Ihre Bedürfnisse so gut wie möglich`
}
const button_request = {
    fr: `Demander mon devis`,
    en: `Request my quote`,
    sp: `Solicitar mi presupuesto`,
    de: `Mein Angebot anfordern`
}

function RequestQuote({ data, setCart }) {
    const navigate = useNavigate();

    const user = useSelector(state => state.user);
    const lang = useSelector(state => state.menu.lang);

    const [specific, setSpecific] = useState('');
    const [userData, setUserData] = useState(null);

    const [address, setAddress] = useState(null);
    const [addressCursor, setAddressCursor] = useState('null');

    useEffect(() => {
        if (data) {
            setUserData({
                siret: data.siret || '',
                society: data.society || '',
                service: data.service || '',
                cellphoneNumber: data.cellphoneNumber || '',
                phoneNumber: data.phoneNumber || '',
                address_street: data.address_street || '',
                address_city: data.address_city || '',
                address_zip_code: data.address_zip_code || '',
                address_country: data.address_country || ''
            });

            setAddress({
                all: [...data.delivery_address],
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            })
        }
    }, [data])

    // Selecte addresse
    const handleSelectChange = (e) => {
        const selectedId = parseInt(e.target.value);
        setAddressCursor(e.target.value);

        if (e.target.value === 'null' || e.target.value === 'add') {
            // Réinitialiser les champs si "Sélectionner une adresse" ou "Ajouter une adresse" est sélectionné
            setAddress({
                ...address,
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            });
        }
        else {
            const selectedAddress = address.all.find(element => element.id === parseInt(selectedId));
            setAddress({
                ...address,
                name: selectedAddress?.name || '',
                address_street: selectedAddress?.address_street || '',
                address_city: selectedAddress?.address_city || '',
                address_country: selectedAddress?.address_country || '',
                address_zip_code: selectedAddress?.address_zip_code || ''
            });
        }
    };

    const sendRequest = async () => {
        if (addressCursor === 'null') {
            return
        }

        const formData = new FormData();

        if (addressCursor === 'add') {
            const req = await fetch(`https://api.pro-vide.eu/user/address/${user._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({ ...address, password: user.password })
            });
            const res = await req.json();

            if (res) {
                formData.append('addressId', res[res.length - 1].id);
            }
            else {
                navigate('/error');
            }
        }
        else {
            formData.append('addressId', addressCursor);
        }

        formData.append('siret', userData.siret);
        formData.append('service', userData.service);
        formData.append('cellphoneNumber', userData.cellphoneNumber);
        formData.append('phoneNumber', userData.phoneNumber);
        formData.append('address_street', userData.address_street);
        formData.append('address_city', userData.address_city);
        formData.append('address_zip_code', userData.address_zip_code);
        formData.append('comments', specific);

        const req = await fetch(`https://api.pro-vide.eu/user/askQuote/${user._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(formData)
        });
        const res = await req.json();

        if (res.public_path) {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = res.public_path;
            a.target = '_blank';

            a.click();
            
            document.body.removeChild(a);
            window.location.reload();
        }
    }

    return userData && (
        <div className='requestQuote'>
            <div className="content">
                <h3>{title[lang]}</h3>

                <section>
                    <h4>{subtitle_coordinate[lang]}</h4>

                    <div className="container_fields">

                        <div className='input_model input_lock'>
                            <label>{label_lastname[lang]}</label>
                            <input
                                type="text"
                                value={data.lastname}
                                className={`input_`}
                                readOnly
                            />
                        </div>
                        <div className='input_model input_lock'>
                            <label>{label_firstname[lang]}</label>
                            <input
                                type="text"
                                value={data.firstname}
                                className={`input_`}
                                readOnly
                            />
                        </div>

                        <div className='input_model input_lock'>
                            <label>{label_compagny[lang]}</label>
                            <input
                                type="text"
                                value={data.society}
                                className={`input_`}
                                readOnly
                            />
                        </div>
                        <Input label={label_service[lang]} placeholder={placeholder_service[lang]} value={userData.service} setValue={setUserData} labForm='service' />

                        <Input label={label_siret[lang]} placeholder={placeholder_siret[lang]} value={userData.siret} setValue={setUserData} labForm='siret' />
                        <Input label={label_cellphone[lang]} placeholder={placeholder_cellphone[lang]} value={userData.cellphoneNumber} setValue={setUserData} labForm='cellphoneNumber' />

                        <Input label={label_phone[lang]} placeholder={placeholder_phone[lang]} value={userData.phoneNumber} setValue={setUserData} labForm='phoneNumber' />
                        <Input label={label_address[lang]} placeholder={placeholder_address[lang]} value={userData.address_street} setValue={setUserData} labForm='address_street' />

                        <Input label={label_city[lang]} placeholder={placeholder_city[lang]} value={userData.address_city} setValue={setUserData} labForm='address_city' />
                        <Input label={label_zipcode[lang]} placeholder='73000' value={userData.address_zip_code} setValue={setUserData} labForm='address_zip_code' />

                        <div className='input_model input_lock'>
                            <label>{label_country[lang]}</label>
                            <input
                                type="text"
                                value={data.address_country}
                                className={`input_`}
                                readOnly
                            />
                        </div>
                    </div>
                </section>

                <section>
                    <h4>{subtitle_delivery_address[lang]}</h4>

                    <div className='input_model selector_address'>
                        <label>{label_selector[lang]}</label>

                        <select value={addressCursor} onChange={handleSelectChange}>
                            <option value="null">{option_default[lang]}</option>
                            <option value="add">{option_add[lang]}</option>

                            {address && address.all.length !== 0 && address.all.map((a, idx) => (
                                <option key={idx} value={a.id}>{a.name}, {a?.address_street}, {a?.address_city} {a?.address_country}</option>
                            ))}
                        </select>
                    </div>

                    {address && addressCursor && addressCursor === 'add' && (
                        <div className="container_fields">
                            <Input label='Nom' placeholder="Nom de l'addresse" value={address.name} setValue={setAddress} labForm='name' />
                            <i></i>
                            <Input label={label_address[lang]} placeholder={placeholder_address[lang]} value={address.address_street} setValue={setAddress} labForm='address_street' />
                            <Input label={label_city[lang]} placeholder={placeholder_city[lang]} value={address.address_city} setValue={setAddress} labForm='address_city' />
                            <Input label={label_zipcode[lang]} placeholder='73000' value={address.address_zip_code} setValue={setAddress} labForm='address_zip_code' />
                            <Input label={label_country[lang]} placeholder={placeholder_country[lang]} value={address.address_country} setValue={setAddress} labForm='address_country' />
                        </div>
                    )}
                </section>

                <section>
                    <h4>{subtitle_specific_need[lang]}</h4>

                    <div className='input_model specific'>
                        <label>{label_specific_need[lang]}</label>
                        <textarea
                            value={specific}
                            placeholder={placeholder_specific_need[lang]}
                            onChange={(e) => setSpecific(e.target.value)}
                        />
                    </div>
                </section>

                <button
                    className="button_requestQuote"
                    onClick={() => sendRequest()}
                >{button_request[lang]}</button>
            </div>
        </div>
    )
}

export default RequestQuote;