import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { close } from "../../redux";

import Banner from "../../components/Banner";
import SliderProd from "../../components/SliderProd";

import References from "../../modals/References";
import Description from "../../modals/Description";
import Download from "../../modals/Download";
import Accessories from "../../modals/Accessories";

import icon_contact from './../../assets/svg/contact_icon-product.svg'
import icon_phone from './../../assets/svg/phone_icon-product.svg'
import icon_message from './../../assets/svg/message_icon-product.svg'

import "./style.css"

const title_list_features = {
    fr: `Caracteristiques`,
    en: `Features`,
    sp: `Características`,
    de: `Merkmale`
};
const title_list_benefits = {
    fr: `Avantages`,
    en: `Benefits`,
    sp: `Ventajas`,
    de: `Vorteile`
};

const button_references = {
    fr: `Nos références`,
    en: `Our references`,
    sp: `Nuestras referencias`,
    de: `Unsere Referenzen`
};
const button_description = {
    fr: `Description`,
    en: `Description`,
    sp: `Descripción`,
    de: `Beschreibung`
};
const button_downloads = {
    fr: `Téléchargements`,
    en: `Downloads`,
    sp: `Descargas`,
    de: `Downloads`
};
const button_accessories = {
    fr: `Produits Associés`,
    en: `Related Products`,
    sp: `Productos Relacionados`,
    de: `Verwandte Produkte`
};
const msg_contact = {
    fr: `Si vous désirez une offre, ou de plus amples informations sur ce produit contactez-nous :`,
    en: `If you would like an offer, or more information on this product, contact us :`,
    sp: `Si desea una oferta o más información sobre este producto, contáctenos :`,
    de: `Wenn Sie ein Angebot oder weitere Informationen zu diesem Produkt wünschen, kontaktieren Sie uns :`
};
const button_contact = {
    fr: `Nous écrire`,
    en: `Write us`,
    sp: `Escribenos`,
    de: `Schreiben Sie uns`
};

function Product() {
    const { id } = useParams();
    const lang = useSelector(state => state.menu.lang);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const referenceRef = useRef(null);

    // ------------------------------------------

    const [dataPage, setDataPage] = useState(null);
    const [modal, setModal] = useState('references');
    const [plans, setPlans] = useState([]);

    // ------------------------------------------

    // Récuprération des données
    useEffect(() => {
        if (!id) {
            navigate('/error');
        }

        const fetchData = async () => {
            const req = await fetch(`https://api.pro-vide.eu/public/product/${lang}/${id}`);
            const res = await req.json();

            if (res._id) {
                setDataPage(res);
                return
            }
            navigate('/error');
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, lang]);

    // Vérification tableau ref
    useEffect(() => {
        if (dataPage && dataPage.table_ref.length === 0) {
            setModal('descrip');
        }
        else {
            setModal('references');
        }
    }, [dataPage]);

    // ------------------------------------------

    // Récup des plans/pdf
    useEffect(() => {
        if (!dataPage || !dataPage.files || dataPage.files.length === 0) {
            setPlans([]);
            return
        }

        const fetchPlans = async () => {
            let newArray = [];

            for (let fileId of dataPage.files) {
                const req = await fetch(`https://api.pro-vide.eu/pdf/${fileId}`);
                const res = await req.json();

                if (res && res.pdf) {
                    newArray.push({ url: res.pdf, filename: getFileName(res.pdf) });
                }
                else {
                    const req2 = await fetch(`https://api.pro-vide.eu/public/plans/${fileId}`);
                    const res2 = await req2.json();

                    newArray.push(res2 && res2.plan ? { url: res2.plan, filename: getFileName(res2.plan) } : '');
                }
            }

            setPlans(newArray);
        }
        fetchPlans();
    }, [dataPage]);

    // Découpage filename
    const getFileName = (url) => {
        if (!url) return '';

        let filename = url.substring(url.lastIndexOf('/') + 1); // Supprime les caractères avant le dernier '/'
        filename = filename.replace(/(-[^-]+)(?=\.)/g, ''); // Supprime les caractères après le premier tiret avant l'extension
        return filename;
    };

    // ------------------------------------------

    // Ancre references
    const scrollToReferences = () => {
        referenceRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());
        navigate(`/${route}`);
    }

    // ------------------------------------------

    return (
        <div className='page_product'>
            <div className="container-slider-banner">
                <SliderProd data={dataPage} scrollToReferences={scrollToReferences} />
                <Banner />
            </div>


            {dataPage && (dataPage.benefits.length !== 0 || dataPage.features.length !== 0) && (
                <div className="container-schema-descriptions">
                    {(dataPage.benefits.length !== 0 || dataPage.features.length !== 0) && (
                        <div className={`container-descriptions no-schema}`}>
                            {dataPage.features.length !== 0 && (
                                <ul className="features">
                                    <h3>{title_list_features[lang]}</h3>
                                    {dataPage.features.map((feature, idx) => (
                                        <li key={idx}>{feature}</li>
                                    ))}
                                </ul>
                            )}

                            {dataPage.benefits.length !== 0 && (
                                <ul className="benefits">
                                    <h3>{title_list_benefits[lang]}</h3>
                                    {dataPage.benefits.map((benefit, idx) => (
                                        <li key={idx}>{benefit}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>
            )}


            {dataPage &&
                <div ref={referenceRef} className="modal-product">
                    <ul className="menu">
                        {dataPage.table_ref.length !== 0 && <li><button className={`${modal === 'references' && 'actif'}`} onClick={() => setModal('references')}>{button_references[lang]}</button></li>}
                        <li><button className={`${modal === 'descrip' && 'actif'}`} onClick={() => setModal('descrip')}>{button_description[lang]}</button></li>
                        <li><button className={`${modal === 'download' && 'actif'}`} onClick={() => setModal('download')}>{button_downloads[lang]}</button></li>
                        <li><button className={`${modal === 'accessories' && 'actif'}`} onClick={() => setModal('accessories')}>{button_accessories[lang]}</button></li>
                    </ul>

                    {modal && modal === 'references' && <References data={dataPage} />}
                    {modal && modal === 'descrip' && <Description data={dataPage?.text} />}
                    {modal && modal === 'download' && <Download data={plans} />}
                    {modal && modal === 'accessories' && <Accessories data={dataPage?.associated} />}
                </div>
            }

            <div className="content-mobile">
                {dataPage && dataPage.table_ref && dataPage.table_ref.length !== 0 && <References data={dataPage} />}
                {plans && plans.length !== 0 && <Download data={plans} />}
                {dataPage && dataPage.associated && <Accessories data={dataPage.associated} />}
            </div>


            <div className="contact">
                <p><img src={icon_contact} alt="Icon" />{msg_contact[lang]}</p>

                <div className="contact-btn">
                    <a href="tel:+330255996505" className="btn-phone"><img src={icon_phone} alt="Icon" />+33 2 55 99 65 05</a>
                    <button onClick={() => goPage('contact')} className="btn-email"><img src={icon_message} alt="Icon" />{button_contact[lang]}</button>
                </div>
            </div>
        </div>
    )
}

export default Product;